import React, { useContext, useState } from 'react';

import InputSuggestion from "./InputSuggestion";
import TextareaGenerator from "./TextareaGenerator";
import { GenerateFormContext } from './GeneratorContextComponent';
import SelectBox from '../../components/base/SelectBox';
import NumberInput from '../../components/base/NumberInput';
import logo from '../../assets/img/logo.png';
import listChecksImg from '../../assets/img/icons/list-checks.svg';
import GoalInputSuggestion from './GoalInputSuggestion';
import { useSelector } from 'react-redux';
import { getFormOptions } from '../../redux/slices/applicationSlice';


const GoalConfigStage1 = () => {
    const [generateForm, setGenerateForm] = useContext(GenerateFormContext);
    const { 
        goalCategory, goalMilestonesNumber, goalTitle, goalDescription, goalChallenges
    } = generateForm;
    const setFormValue = (key, value) => setGenerateForm(prev => ({...prev, [key]: value}));
    const [showSugg, setShowSugg] = useState(false);
    const formOptions = useSelector(getFormOptions);
    const categoryOptions = formOptions.filter(({key}) => key === 'goal_category');
    return <>
        <div className="get4-content-header mb-[36px]">
            <div className='flex items-center justify-center mb-[20px]'>
                <div className='blog-kit-icon-container mr-[12px]'>
                    <img src={listChecksImg} alt='list checks'/>
                </div>
                <img src={logo} className='h-[18px] mr-[6px]' alt='list checks'/>
                <h2 className="font-[Gilroy] text-[24px] font-semibold leading-none">Goal Realization Kit</h2>
            </div>
            <p className="get4-content-subtitle">Describe your goal here to help us achieve it</p>
        </div>
        <div className='flex flex-col mb-[16px] gap-[16px]'>
            <div className='generator-settings-part'>
                <div className={`input-custom min-w-[364px] mr-[16px]`}>
                    <label className="input-custom__label mb-[10px]">Goal Category</label>
                    <div className="input-custom__wrap-input gen4-settings-select">
                        <SelectBox
                            className='default-select flex-1'
                            propsOptions={[
                                { label: 'Select Category', value: '', placeholder: true },
                                ...categoryOptions.map(item => ({ label: item.title, value: item.title }))
                            ]}
                            value={goalCategory || 'Select category'}
                            change={(val) => setFormValue('goalCategory', val)}
                        />
                    </div>
                </div>
                <div className={`input-custom`}>
                    <label className="input-custom__label mb-[10px]">Milestones Number</label>
                    <div className="input-custom__wrap-input gen4-settings-select">
                        <NumberInput 
                            value={goalMilestonesNumber || 8}
                            onChange={(val) => setFormValue('goalMilestonesNumber', val)} 
                        />
                    </div>
                </div>
            </div>
            <GoalInputSuggestion
                destination='form'
                value={goalTitle}
                setValue={(value) => setFormValue('goalTitle', value)}
                showTopicSugg={showSugg}
                setShowTopicSugg={setShowSugg}
                to='goal'
            />
            <label htmlFor='blog_description'>
                <p
                    className='text-[12px] leading-[14px] text-[#A7A7A7]'
                >
                    Goal Description
                </p>
                <textarea
                    value={goalDescription}
                    onChange={(e) => setFormValue('goalDescription', e.target.value)}
                    className='bg-[#1B1B1B] w-full resize-none pt-[16px] pb-[8px] pl-[14px] pr-[8px] rounded-[8px] mt-[10px]
                    border border-solid border-[#333] h-[120px] text-[14px] leading-[22px] text-[#ffffffe6]
                    overflow-y-auto'
                    id="blog_description"
                    placeholder='Ex. I want to write a 50,000 words novel by the end of this year...'
                    onFocus={(e) => {
                        setShowSugg(false);
                    }}
                />
            </label>
            <label htmlFor='goalChallenges'>
                <p
                    className='text-[12px] leading-[14px] text-[#A7A7A7]'
                >
                    Challenges to Overcome
                </p>
                <textarea
                    value={goalChallenges}
                    onChange={(e) => setFormValue('goalChallenges', e.target.value)}
                    className='bg-[#1B1B1B] w-full resize-none pt-[16px] pb-[8px] pl-[14px] pr-[8px] rounded-[8px] mt-[10px]
                    border border-solid border-[#333] h-[120px] text-[14px] leading-[22px] text-[#ffffffe6]
                    overflow-y-auto'
                    id="goalChallenges"
                    placeholder="Ex. Finding dedicated writing time, Overcoming writer's block..."
                />
            </label>
        </div>
    </>;
};

export default GoalConfigStage1;