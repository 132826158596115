import React from 'react';
import { useDropzone } from 'react-dropzone';

const FileDropzone = ({options}) => {
    const {
        getRootProps,
        getInputProps,
        isDragActive,
    } = useDropzone(options);
    return <div 
        className='flex flex-col items-center gap-[16px] py-[24px] pl-[14px] pr-[8px]
        border border-dashed border-[#FF8114] rounded-[8px] cursor-pointer' 
        id="file-dropzone"
        {...getRootProps()}
    >
        <input {...getInputProps()} />
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path d="M17.5 17.5L14 14" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14 14L10.5 17.5" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.9974 23.3333V14" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.33594 22.1666H7.0026C4.42528 22.1666 2.33594 20.0773 2.33594 17.5C2.33594 14.9226 4.42528 12.8333 7.0026 12.8333L6.99094 12.7512C6.70056 10.7186 7.30653 8.65997 8.65183 7.10882C9.99713 5.55767 11.9493 4.66664 14.0026 4.66663C16.0559 4.66664 18.0081 5.55767 19.3534 7.10882C20.6987 8.65997 21.3046 10.7186 21.0143 12.7512L21.0026 12.8333C23.5799 12.8333 25.6693 14.9226 25.6693 17.5C25.6693 20.0773 23.5799 22.1666 21.0026 22.1666H18.6693" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div className='flex flex-col items-center gap-[12px]'>
            <p className='text-[14px] text-[#FF8114] leading-[10px]'>
                Upload files from computer
            </p>
            <p className='text-[12px] leading-[9px] opacity-60 font-light'>
                {
                    isDragActive ?
                    'drop the files here' :
                    'or drag and drop here'
                }
            </p>
            <p className='text-[12px] leading-[9px] opacity-30 font-light'>
                (up to 10MB in size)
            </p>
        </div>
    </div>
};

export default FileDropzone;