import React, { useEffect, useState, } from 'react';
import Tippy from '@tippyjs/react';
import { useDebounce } from "../../../helpers/useDebounce";

const Goal = ({ generateSuggestedList }) => {
    const [updatedTimes, setUpdatedTimes] = useState(0);
    const isUpdated = useDebounce(updatedTimes, 1500);

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        if(inputValue?.length){
            generateSuggestedList(inputValue);
        }
    }, [isUpdated])

    return <>
        <label className='text-[#A7A7A7] text-[12px] leading-[14px] block mb-[10px] d-flex items-center min-h-[16px]' htmlFor="goal-page-input">
            What's Your Goal? <div className="ml-[4px]"><ButtonTooltip tooltip={<div className='max-w-[291px]' dangerouslySetInnerHTML={{ __html: `What overarching achievement do you have in mind? By identifying a broad objective, we will suggest diverse topics that help you reach that goal. <br /> <br /> For instance, if you're aiming to 'Elevate Digital Marketing Skills', you might receive topic suggestions from 'SEO Best Practices' to 'Visual Storytelling in Social Media'., 'Social Media Marketing 101', 'Using Influencer Marketing To Grow Any Business'.` }} />} /></div>
        </label>
        <div className='flex gap-[10px] mb-[16px]'>
            <input
                id='goal-page-input'
                className='h-[40px] p-[8px] px-[14px] flex-1 bg-[#1B1B1B] rounded-[8px] border border-solid border-[#333] text-[14px]'
                placeholder='Ex. Boost team productivity, Understand modern art forms...'
                value={inputValue}
                onChange={(e) => {
                    setInputValue(e.target.value);
                    setUpdatedTimes((prevState) => prevState + 1);
                }}
            />
        </div>
    </>;
};

const ButtonTooltip = ({ tooltip }) => {
    return <Tippy
        offset={[0,4]}
        arrow={false}
        className='generator-button-tooltip'
        placement='bottom'
        content={tooltip}
    >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.9991 8.47351L8.88414 7.98181C9.32583 7.73646 9.59977 7.27093 9.59977 6.76567C9.54745 5.92546 8.82688 5.28495 7.98633 5.33149C7.23528 5.30031 6.56834 5.80801 6.39844 6.54026" stroke="#A7A7A7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            <circle cx="7.99859" cy="7.99957" r="6.0025" stroke="#A7A7A7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.06814 10.6011C8.06809 10.6379 8.03822 10.6678 8.00141 10.6677C7.9646 10.6677 7.93476 10.6379 7.93475 10.6011C7.93473 10.5643 7.96454 10.5344 8.00135 10.5344C8.01907 10.5343 8.03607 10.5414 8.0486 10.5539C8.06113 10.5664 8.06816 10.5834 8.06814 10.6011" stroke="#A7A7A7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </Tippy>
};

export default Goal;