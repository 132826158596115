import React, { useContext, useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import logo from '../../../assets/img/big-logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from '../../../api';
import { ReactComponent as IconStars } from '../../../assets/img/icons/icon-stars.svg';
import { GenerateFormContext, initialState } from '../GeneratorContextComponent';
import { getSocket, getToken } from '../../../redux/slices/applicationSlice';
import Label from '../../../components/base/Label';
import SelectBox from '../../../components/base/SelectBox';
import Addons from '../Addons';
import KeywordsGenerator from './KeywordsGenerator';
import { languageOptions, } from '../../../configs/selectOptions';

import { setGenerateLoading, resetBlogGenerator, setBlogs, setBlogVersionAddon, } from '../../../redux/slices/blogGeneratorSlice';
import { getFormOptions } from '../../../redux/slices/applicationSlice';
import { toast } from 'react-toastify';

const GeneralConfigComponent = ({ setLoading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const socket = useSelector(getSocket);
    const token = useSelector(getToken);
    const bitId = searchParams.get('bitId');

    const [generateForm, setGenerateForm] = useContext(GenerateFormContext);
    const {  productType, description, add_ons, keywords, language, target_audience, } = generateForm;

    const submitDisabled = !productType?.length || !description?.length;

    const formOptions = useSelector(getFormOptions);
    const audienceOptions = formOptions.filter(({key}) => key === 'target_audience');
    const languageOptions = formOptions.filter(({key}) => key === 'language');

    const [activeTab, setActiveTab] = useState('Add-ons');

    const [descriptionListHeight, setDescriptionListHeight] = useState(0);
    const descriptionListRef = useRef();

    const handleSubmit = async (e) => {
        try {
            setLoading(true);
            dispatch(resetBlogGenerator());
            dispatch(setGenerateLoading(true));
            
            const formatedAddons = add_ons.map(addon => ({ title: addon, key: addon.replace(/ /g, '_').toLowerCase() }));
            
            let settings = {
                productType,
                description,
                addons: formatedAddons,
                target_audience: target_audience || '',
                language: language || 'English',
                keywords,
            };

            const response = await api.assets.createAsset({
                data: {
                    name: productType,
                    content: JSON.stringify([
                        {
                            add_ons: formatedAddons,
                            settings,
                            versions: [],
                            activeVersionIndex: 0,
                            generatedDate: new Date().toUTCString(),
                            selectedVersionId: null,
                            addonsValues: []
                        }
                    ]),
                    productId: 8,
                },
                token
            });

            if(!response.success) return;

            socket.send({
                event: 'general-generator-create',
                payload: settings,
                handler: (data) => {
                    if(data?.isError){
                        toast.error("AI bot is not available at the moment");

                        setLoading(false);
                        dispatch(setGenerateLoading(false));
        
                        return;
                    }

                    if(window.location.pathname === `/generated-page/general/${response.data.id}`) {
                        dispatch(setBlogs([
                            {
                                add_ons: settings.addons,
                                settings,
                                versions: [{
                                    "title": data.payload.title,
                                    "content": data.payload.content,
                                    "isDone": data.isDone,
                                    "id": `${response.data.id}-general-${0}`,
                                    "language": language,
                                    "tone": "",
                                    "activeContent": data.payload.content,
                                    "activeButton": ""
                                }],
                                activeVersionIndex: 0,
                                generatedDate: new Date().toUTCString(),
                                selectedVersionId: null,
                                addonsValues: []
                            }
                        ]));
                    }
                
                    if(data.isDone) {                        
                        if(add_ons?.length) {
                            socket.send({
                                event: 'general-generator-addons',
                                payload: {
                                    content: data.payload.content,
                                    title: data.payload.title,
                                    addons: formatedAddons,
                                },
                                handler: (addonsResponse) => {
                                    if(addonsResponse?.isError){
                                        toast.error("AI bot is not available at the moment");
                
                                        setLoading(false);
                                        dispatch(setGenerateLoading(false));
                        
                                        return;
                                    }

                                    dispatch(setBlogVersionAddon({
                                        index: 0,
                                        data: {
                                            ...addonsResponse.payload,
                                            isDone: addonsResponse.isDone
                                        }
                                    }));
                                }
                            })
                        }
                    }
                }
            });

            navigate(`/generated-page/general/${response.data.id}`);
        } catch(e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const test = false;
        if(!bitId) {
            setGenerateForm(test ? {
                ...initialState,
                add_ons: [],
                productType: 'Macramé Wall Hanging',
                description: `Elevate your living space with our handcrafted Macramé Wall Hanging. This boho-inspired piece adds a touch of warmth and texture to any room. Meticulously crafted with love and attention to detail, this wall hanging is a unique statement piece. The intricate knots and patterns create a visually stunning display that complements various decor styles. Whether you're enhancing your home or searching for a thoughtful gift, our Macramé Wall Hanging brings a bohemian vibe to any space. Embrace the artistry of handmade decor and make a statement with this beautiful macramé piece.`,
            } : {
                ...initialState,
                add_ons: [],
                productType: '',
                description: '',
            });
        }

        if (!descriptionListRef.current) return;
        const resizeObserver = new ResizeObserver((e) => {
            setDescriptionListHeight(
                descriptionListRef.current.getBoundingClientRect().height+8
            );
        });
        resizeObserver.observe(descriptionListRef.current);
        return () => resizeObserver.disconnect(); // clean up 
    }, [])

    useEffect(() => {
        if(!bitId || !token) return;
        api.assets.getAssetById({
            id: bitId,
            token
        }).then(resp => {
            if(!resp.success || resp.data.productId !== 10) return;
            const data = JSON.parse(resp.data.content);
            setGenerateForm(prev => ({
                ...prev,
                add_ons: data.addons.map(item => item.title),
                productType: data.productType,
                description: data.description,
                keywords: data.keywords,
                language: data.language,
                target_audience: data.target_audience,
            }))
        }).catch(e => console.log(e))
    }, [bitId, token])

    return <>
        <div className='gen4-content'>
            <div className='w-full max-w-[500px] m-auto mt-0'>
                <div className="get4-content-header mb-[36px]">
                    <div className='flex items-center justify-center gap-[12px]'>
                        <div className='blog-kit-icon-container'>
                            <svg width="22" height="22" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="jam:write-f" filter="url(#filter0_d_2309_87412)">
                                    <path d="M17.4375 24.2554C17.9553 24.2554 18.375 23.8356 18.375 23.3179C18.375 22.8001 17.9553 22.3804 17.4375 22.3804C16.9197 22.3804 16.5 22.8001 16.5 23.3179C16.5 23.8356 16.9197 24.2554 17.4375 24.2554Z" fill="white" />
                                    <path d="M24.9707 21.4766C25.4885 21.4766 25.9082 21.0568 25.9082 20.5391C25.9082 20.0213 25.4885 19.6016 24.9707 19.6016C24.4529 19.6016 24.0332 20.0213 24.0332 20.5391C24.0332 21.0568 24.4529 21.4766 24.9707 21.4766Z" fill="white" />
                                    <path d="M26.3809 13.4751C26.8986 13.4751 27.3184 13.0554 27.3184 12.5376C27.3184 12.0198 26.8986 11.6001 26.3809 11.6001C25.8631 11.6001 25.4434 12.0198 25.4434 12.5376C25.4434 13.0554 25.8631 13.4751 26.3809 13.4751Z" fill="white" />
                                    <path d="M13.4887 9.2842C12.9034 10.1201 12.8136 11.1843 13.2554 12.1121L15.5189 13.6971C16.5419 13.7948 17.5111 13.3465 18.0964 12.5106C19.6343 10.3143 18.6642 7.4722 17.9491 5.97351C17.7865 5.63288 17.4514 5.42807 17.0744 5.43888C16.6987 5.44976 16.377 5.67263 16.235 6.02063C15.9858 6.63157 15.7328 7.10282 15.4832 7.42132C15.171 7.81957 14.9195 7.98532 14.6532 8.16082C14.3244 8.37745 13.9517 8.62307 13.4887 9.2842ZM14.3932 15.1978C13.9797 16.3844 12.9909 18.1676 11.1019 20.8653C7.34023 26.2376 1.45216 33.1831 0.603913 32.5891C-0.244337 31.9951 4.26841 24.0867 8.0301 18.7144C9.9191 16.0166 11.2564 14.4776 12.2302 13.6831L14.3932 15.1978Z" fill="white" />
                                    <path d="M10.1521 25.3247C10.2622 25.7091 10.4077 26.0824 10.5869 26.4399C11.4359 28.1328 12.9929 29.3227 14.8587 29.7045C14.9903 29.7315 15.1251 29.7571 15.2589 29.7807C16.1112 29.9314 16.975 30.0073 17.8406 30.0077C20.8267 30.0077 23.7366 29.0962 26.2337 27.3476C29.4474 25.0974 31.5924 21.7303 32.2737 17.8668C32.9549 14.0032 32.0909 10.1056 29.8406 6.8919C27.5904 3.67822 24.2234 1.53322 20.3598 0.851904C16.4965 0.170841 12.5986 1.03472 9.38493 3.28503C6.17124 5.53522 4.02624 8.90228 3.34493 12.7658L3.31656 12.9282C3.16549 13.7916 3.45637 14.6598 4.09468 15.2503C4.76181 15.8675 5.69681 16.0983 6.59593 15.868C6.99999 15.7645 7.42243 15.7376 7.84062 15.7865C9.09424 14.132 10.1508 12.9606 11.0455 12.2306L11.2535 12.0609C10.9194 10.75 11.1631 9.33772 11.9535 8.20903C12.6217 7.25459 13.2239 6.85784 13.6223 6.59534C13.8343 6.45559 13.8847 6.4224 14.0081 6.26497C14.0727 6.18253 14.2499 5.92472 14.4997 5.3124C14.92 4.28278 15.9097 3.59684 17.0214 3.5649C17.0488 3.56409 17.0763 3.56365 17.1035 3.56365C18.1976 3.56365 19.1702 4.17765 19.6421 5.16634C21.1804 8.39072 21.1772 11.381 19.633 13.5863C19.2452 14.1399 18.7423 14.6032 18.1588 14.9443C17.5753 15.2855 16.9249 15.4965 16.2523 15.5629L16.1645 15.815C15.6557 17.2753 14.5023 19.2792 12.6384 21.9411C11.8753 23.031 11.0284 24.182 10.1521 25.3247ZM26.3818 15.3499C24.831 15.3499 23.5693 14.0882 23.5693 12.5374C23.5693 10.9866 24.831 9.7249 26.3818 9.7249C27.9326 9.7249 29.1943 10.9866 29.1943 12.5374C29.1943 14.0882 27.9326 15.3499 26.3818 15.3499ZM22.1584 20.539C22.1584 18.9882 23.4201 17.7265 24.9709 17.7265C26.5217 17.7265 27.7834 18.9882 27.7834 20.539C27.7834 22.0898 26.5217 23.3515 24.9709 23.3515C23.4201 23.3515 22.1584 22.0898 22.1584 20.539ZM14.6257 23.3179C14.6257 21.7671 15.8874 20.5054 17.4382 20.5054C18.989 20.5054 20.2507 21.7671 20.2507 23.3179C20.2507 24.8687 18.989 26.1304 17.4382 26.1304C15.8874 26.1304 14.6257 24.8687 14.6257 23.3179Z" fill="white" />
                                </g>
                                <defs>
                                    <filter id="filter0_d_2309_87412" x="-0.205653" y="0.117877" width="34" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix>
                                        <feOffset dy="0.823529"></feOffset><feGaussianBlur stdDeviation="0.823529"></feGaussianBlur>
                                        <feComposite in2="hardAlpha" operator="out"></feComposite>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"></feColorMatrix>
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2309_87412"></feBlend>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2309_87412" result="shape"></feBlend>
                                    </filter>
                                    <linearGradient id="paint0_linear_2309_87412" x1="10.6199" y1="2.70568" x2="10.6199" y2="18.2806" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="white"></stop><stop offset="1" stopColor="#E0E0E0"></stop>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <h1 className='text-[24px] font-semibold text-[#ffffffe6] leading-none d-flex items-center'>
                            <img src={logo} alt="Logo" className="h-[18px] mr-[6px]" /> General Kit
                        </h1>
                    </div>
                    <p className='text-[14px] leading-[22px] text-[#a7a7a780] mt-[20px]'>
                        Create impactful, SEO-optimized product with our AI-powered General Kit Generator
                    </p>
                </div>
                <div className='w-100 mb-[16px]' >
                    <div className="mb-[36px]">
                        <label className='text-[#A7A7A7] text-[12px] leading-[14px] block mb-[10px]' htmlFor="page-input">
                            Product Type
                        </label>
                        <div className='flex gap-[10px] mb-[16px]'>
                            <input
                                id='page-input'
                                className='h-[40px] p-[8px] px-[14px] flex-1 bg-[#1B1B1B] rounded-[8px]
                                border border-solid border-[#333] text-[14px]'
                                placeholder='Input Product Type'
                                value={productType}
                                onChange={(e) => {
                                    setGenerateForm((prevState)=>({ ...prevState, productType: e.target.value }))
                                }}
                            />
                        </div>
                    </div>
                    <div className="mb-[36px]">
                        <label className='text-[#A7A7A7] text-[12px] leading-[14px] block mb-[10px]'>
                            Description
                        </label>
                        <div className='d-flex relative'>
                            <textarea
                                value={description}
                                onChange={(e) => {
                                    setGenerateForm((prevState)=>({ ...prevState, description: e.target.value }))
                                }}
                                className='bg-[#1B1B1B] w-full resize-none pt-[16px] pb-[8px] pl-[14px] pr-[8px] rounded-[8px]
                                border border-solid border-[#333] text-[14px] leading-[22px] text-[#ffffffe6]
                                overflow-y-auto min-h-[220px]'
                                placeholder="Input description..."
                                style={{
                                    paddingBottom: `${8+descriptionListHeight}px`
                                }}
                            />

                            <ul
                                ref={descriptionListRef}
                                className='flex gap-[8px] flex-wrap absolute bottom-[8px] left-[14px]'
                                style={{
                                    width: 'calc(100% - 27px)',
                                    background: '#1b1b1b'
                                }}
                            >
                                { [
                                    ...(keywords?.length ? keywords?.map(itemKeyword => ({ type: 'keywords', text: itemKeyword })) : [] ),
                                ].map((item, index) => <li
                                    className={classNames(`flex h-[26px] pl-[10px] pr-[6px] items-center gap-[6px] text-[12px]
                                    border border-solid rounded-[24px] cursor-pointer`,
                                    'bg-[#202020] border-[#383838] text-[#ffffff80]')}
                                    key={item +'-'+ index}
                                    onClick={() => {
                                        setGenerateForm(prev => ({...prev, keywords: prev.keywords.filter(itemF => itemF !== item.text) }))
                                    }}
                                >
                                    { item?.text }
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.5">
                                            <path d="M11 5L5 11" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M5 5L11 11" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </g>
                                    </svg>
                                </li>)}
                            </ul>
                        </div>
                    </div>

                    {
                        productType?.length && description?.length ?
                        <div>
                            <div className="mt-[24px] mb-[16px] d-flex align-items-center justify-between">
                                <ul className="tabs-controls blog-config-tabs">
                                    {
                                        [
                                            'Add-ons',
                                            'Keywords',
                                            'Settings'
                                        ].map((item, index) => {
                                            return <li key={index}>
                                                <a href="#" className={activeTab === item ? `active` : ''} onClick={(e) => {
                                                    e.preventDefault();
                                                    setActiveTab(item);
                                                }}>
                                                    {item}
                                                </a>
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>

                            { activeTab === 'Add-ons' && <div>
                                    <Addons data={add_ons || []} setData={(add_ons => setGenerateForm(prev => ({...prev, add_ons})))} />
                                </div>
                            }
                            { activeTab === 'Keywords' && <div>
                                    {/* keywords */}
                                    <KeywordsGenerator onChange={(val) => setGenerateForm(prev => ({...prev, 'keywords': val}))} data={keywords || null} />
                                </div>
                            }
                            { activeTab === 'Settings' && <div>
                                    <div className="d-flex gap-[16px]">
                                        <div className='flex flex-col gap-[10px] flex-1 mb-[16px]'>
                                            <Label>Language</Label>
                                            <SelectBox
                                                className='default-select flex-1'
                                                propsOptions={[
                                                    { label: 'Select Language', value: '', placeholder: true },
                                                    ...languageOptions.map(item => ({ label: item.title, value: item.title }))
                                                ]}
                                                value={language}
                                                change={(languageValue) =>
                                                    setGenerateForm((prevState)=>({
                                                        ...prevState,
                                                        language: languageValue
                                                    }))
                                                }
                                            />
                                        </div>
                                        <div className='flex flex-col gap-[10px] flex-1 mb-[16px]'>
                                            <Label>Target Audience</Label>
                                            <SelectBox
                                                className='default-select flex-1'
                                                propsOptions={[
                                                    { label: 'Select target audience', value: '', placeholder: true },
                                                    ...audienceOptions.map(item => ({ label: item.title, value: item.title }))
                                                ]}
                                                value={target_audience || ''}
                                                change={(val) => setGenerateForm(prev => ({...prev, target_audience: val}))}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div> : null
                    }
                </div>
            </div>
        </div>
        <div className="gen4-footer">
            <button
                className="gen4-btn"
                disabled={submitDisabled}
                onClick={handleSubmit}
            >
                <span>Generate</span>
                <IconStars />
            </button>
        </div>
    </>;
};

export default GeneralConfigComponent;