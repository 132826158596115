import React, { useEffect, useRef, useState } from 'react';
import EditorToolbar from '../Blog/EditorToolbar';
import { useQuill } from 'react-quilljs';
import classNames from 'classnames';
import DefaultToolbar from '../Blog/DefaultToolbar';
import EditorControl from '../Blog/EditorControl';
import { useDispatch } from 'react-redux';
import BlogVariantKeywords from '../Blog/BlogVariantKeywords';
import { useSelector } from 'react-redux';
import {
    getActiveBlogIndex, getBlogGeneratingContent, getGeneratedBlogs,
    setBlogActiveButton, setBlogActiveContent, setBlogActiveVersionIndex, setBlogContent, setBlogGeneratingContent, setGenerateLoading
} from '../../../redux/slices/blogGeneratorSlice';
import api from '../../../api';
import { useParams } from 'react-router-dom';
import { getSocket, getToken } from '../../../redux/slices/applicationSlice';
import ImageUploader from "quill-image-uploader";
import Quill from "quill";
import 'quill-image-uploader/dist/quill.imageUploader.min.css';
// Quill.register("modules/imageUploader", ImageUploader);
import { toast } from 'react-toastify';

const GeneralCardContent = ({index, isFullScreen, setCardFullScreen, setUpdateBlogs, markup, isNotDone = false}) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const blogs = useSelector(getGeneratedBlogs);
    const socket = useSelector(getSocket);
    const activeBlogIndex = useSelector(getActiveBlogIndex);
    const generatingContent = useSelector(getBlogGeneratingContent);
    const { activeVersionIndex, versions, selectedVersionId } = blogs[activeBlogIndex] || {};
    const isSelected = selectedVersionId === versions[activeVersionIndex]?.id;
    const { id } = useParams();
    const { quill, quillRef } = useQuill({
        theme: 'snow',
        modules: {
          toolbar: '#toolbar' + index,
          history: {
            delay: 2000,
            userOnly: true
          },
          imageUploader: {
            upload: (file) => {
                return new Promise(async (resolve, reject) => {
                    try {
                        const { data: { attachments }, success } = await api.assets.changeAsset({
                            id,
                            data: {
                                attachments: file
                            },
                            token
                        })
                        if(success) {
                            resolve(process.env.REACT_APP_API_URL + attachments[attachments.length - 1].path);
                        }
                        reject("Upload failed");
                    } catch (e) {
                        reject("Upload failed");
                    }
                });
              },
          }
        },
        formats: [
            'bold', 'italic', 'underline', 'align', 'link', 'image', 'video',
            'color', 'background', 'header', 'font', 'size',
            'clean', 'imageBlot'
        ],
    });

    const [isEdit, setIsEdit] = useState(false);

    const [editorActiveButton, setEditorActiveButton] = useState(null);
    const [imagePopupOpened, setImagePopupOpened] = useState(false);
    const [imageLinkOpened, setImageLinkOpened] = useState(false);
    const [footerActions, setFooterActions] = useState({
        keyword: false,
        customPrompt: false
    });
    const [customPrompt, setCustomPrompt] = useState('');
    const [editBlogLoading, setEditBlogLoading] = useState(false);

    const editBlog = ({operation, isActive, customPayload = {}}) => {
        dispatch(setGenerateLoading(false));
        setEditBlogLoading(true);

        socket.send({
            event: 'general-generator-edit',
            payload: {
                content: versions[activeVersionIndex]?.content,
                operation,
                ...customPayload
            },
            handler: (response) => {
                if(response?.isError){
                    toast.error("AI bot is not available at the moment");
                    return;
                }

                if(quillRef?.current?.firstChild){
                    const { firstChild } = quillRef.current;
                    firstChild.scroll({ top: firstChild.scrollHeight, behavior: 'smooth' });
                }

                const setEditContent = () => {
                    isActive ? dispatch(setBlogActiveContent(response.payload)) : dispatch(setBlogContent(response.payload));
                } 

                if(response.isDone) {
                    dispatch(setBlogGeneratingContent(null));
                    setEditContent();
                    // setUpdateBlogs(true);
                    setEditBlogLoading(false);
                    return;
                } else {
                    setEditContent();
                }

                dispatch(setBlogGeneratingContent(response.payload));
            }
        })
    }
    const wordsCount = markup?.split(' ').filter(word => word.length > 0).length;
    const imageButtonRef = useRef();

    useEffect(() => {
        if (!quill || !markup) return;
        quill.disable();
        quill.clipboard.dangerouslyPasteHTML(generatingContent || markup, 'api');
    }, [quill, markup, generatingContent]);

    useEffect(() => {
        if (!quill) return;
        if(isEdit) {
            quill.enable();
        } else {
            quill.disable();
        }
    }, [quill, isEdit]);


    return <>
        <div 
            id={'toolbar'+ index}
            className={classNames("flex justify-between py-[6px] px-[8px] rounded-full bg-[#1B1B1B] mx-[16px] min-h-[40px]", isNotDone ? 'block-disabled' : '')}
            style={{
                display: isEdit ? 'flex' : 'none'
            }}
        >
            <EditorToolbar
                isEdit={isEdit}
                quill={quill}
                activeButton={editorActiveButton}
                setActiveButton={setEditorActiveButton}
                setImagePopupOpened={setImagePopupOpened}
                setImageLinkOpened={setImageLinkOpened}
                isFullScreen={isFullScreen}
                setCardFullScreen={setCardFullScreen}
                editBlog={editBlog}
                imageButtonRef={imageButtonRef}
            />
        </div>
        <div
            className={classNames("flex justify-between py-[6px] px-[8px] rounded-full bg-[#1B1B1B] mx-[16px]",
            isEdit ? 'hidden' : 'flex')}
        >
            <DefaultToolbar
                disabled={isNotDone || editBlogLoading}
                footerActions={footerActions}
                setBlogIsEdit={setIsEdit}
                setFooterActions={setFooterActions}
                isFullScreen={isFullScreen}
                setCardFullScreen={setCardFullScreen}
                editBlog={editBlog}
                hiddenButtons={["Emojis", "List", "Copy"]}
            />
        </div>

        <pre
            ref={quillRef}
            // style={{display: isEdit ? 'block': 'none'}}
        />

        <div
            // className={classNames('blog-generator-card-footer', isOpen || isFullScreen ? 'block' : 'hidden')}
            className='blog-generator-card-footer'
        >
            {isEdit ? <EditorControl
                activeButton={editorActiveButton}
                closeEditor={() => setIsEdit(false)}
                imageLinkOpened={imageLinkOpened}
                quill={quill}
                setActiveButton={setEditorActiveButton}
                setImageLinkOpened={setImageLinkOpened}
                // setUpdateBlogs={setUpdateBlogs}
                // onlyInnerTextEmit={true}
            /> : footerActions.keyword ? <BlogVariantKeywords
                data={versions[activeVersionIndex]}
                setUpdateBlogs={setUpdateBlogs}
                editBlog={editBlog}
                setFooterActions={setFooterActions}
            /> : footerActions.customPrompt ? <div>
                <div className='flex gap-[10px]'>
                    <input
                        className='bg-inherit h-[32px] text-[13px] flex-1 pl-[12px] pr-[8px]
                        border border-solid border-[#333] rounded-[8px]'
                        value={customPrompt}
                        onChange={(e) => setCustomPrompt(e.target.value)}
                        placeholder="Ex. Rewrite the introductory paragraph to create a stronger hook and engage the reader's interest from the start..."
                    />
                    <button 
                        className='goal-generator-action btn--primary-gradient btn'
                        style={{
                            height: 32,
                            padding: '0 12px',
                            fontSize: 13
                        }}
                        onClick={() => {
                            dispatch(setBlogActiveContent(null));
                            dispatch(setBlogActiveButton(null));
                            editBlog({
                                operation: 'custom',
                                isActive: false,
                                customPayload: {
                                    data: customPrompt
                                }
                            });
                            setFooterActions({
                                keyword: false,
                                customPrompt: false
                            });
                        }}
                        disabled={!customPrompt}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M13.3337 4.33301L6.00033 11.6663L2.66699 8.33301" stroke="#331A04" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        Apply
                    </button>
                </div>
            </div> : versions?.length > 1 ? <div className='flex justify-between items-center'>
                <ul className='flex gap-[8px]'>
                    {versions.map((value, index) => <li
                        key={index}
                        className={classNames(`rounded-[8px] border border-solid border-[#333333] bg-[#1B1B1B]
                        p-[10px] h-[32px] flex items-center text-[13px] opacity-90 text-[#A7A7A7] cursor-pointer hover:text-white`, {
                            'border-[#FF8114] text-white': index === activeVersionIndex
                        })}
                        onClick={() => dispatch(setBlogActiveVersionIndex(index))}
                    >
                        <span className="capitalize">{ value?.pageName || value?.title }</span>
                    </li>)}
                </ul> 
                <div className='flex gap-[16px] items-center'>
                    <span className='text-[13px] opacity-30'>
                        {wordsCount} words
                    </span>
                </div>
            </div> : null }
        </div>
    </>
};

export default GeneralCardContent;