import React, { useState, useEffect } from 'react';


import api from '../../api';
import { toast } from 'react-toastify';
 

import { useSelector, useDispatch } from 'react-redux';
import { getToken, getKeepItPositiveData } from '../../redux/slices/applicationSlice';
import { fetchKeepPositiveThunk } from '../../redux/thunks/applicationThunks';

import Label from '../../components/base/Label';
import Input from '../../components/base/Input';
import PrimaryButton from '../../components/base/PrimaryButton';
import { Link, } from 'react-router-dom';

const KeepItPositive = ({ onlyInput = false, placeholder = 'Ex. Amplify Encouragement, Cultivate Hopefulness...', created }) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const keepPositiveData = useSelector(getKeepItPositiveData);

    const [addCustomActive, setAddCustomActive] = useState(false);
    const [payload, setPayload] = useState('');

    const getKeepPositive = async() => {
        dispatch(fetchKeepPositiveThunk({ token }))
    }

    const createKeepPositive = async() => {
        if(!payload.length){
            toast.error('Enter custom option please!');
            // pending
            // success
        }

        await api.settings.createKeepPositive({
            token,
            data: payload
        }).then((response) => {
            if(!response.success) return;
            setPayload('');
            getKeepPositive();

            if(created) {
                created();
            }
        });
    }

    const keepPositiveToggleAction = async(id) => {
        await api.settings.keepPositiveToggleFavorite({ token, id });
        await getKeepPositive();
    }

    const keepPositiveDelete = async(id) => {
        await api.settings.keepPositiveDelete({ token, id });
        await getKeepPositive();
    };

    useEffect(()=>{
        if(!keepPositiveData?.length) {
            getKeepPositive();
        }    
    }, [])

    useEffect(()=>{
        if(onlyInput){
            setAddCustomActive(true);
        }
    }, [onlyInput])

    console.log(keepPositiveData);

    return <>
        <div className={!onlyInput ? 'settings-component-wrapper justify-between' : null}>
            {
                !onlyInput ?
                <div className="settings-component-side flex-col">
                    <span className='settings-component-title d-flex items-center'>
                        Keep it positive
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-[7px]">
                            <path d="M5.41536 15.1163C5.00425 15.1163 4.6487 14.9729 4.3487 14.6863C4.0487 14.3996 3.8987 14.0374 3.8987 13.5996V6.84959C3.8987 6.657 3.93261 6.46961 4.00043 6.28744C4.06825 6.10526 4.18434 5.93709 4.3487 5.78292L7.03203 2.91625C7.24314 2.69403 7.50148 2.54681 7.80703 2.47459C8.11259 2.40236 8.41536 2.43292 8.71536 2.56625C8.98203 2.7107 9.17925 2.92736 9.30703 3.21625C9.43481 3.50514 9.46537 3.8107 9.3987 4.13292L8.96537 6.23292H14.0487C14.4465 6.23292 14.8015 6.3907 15.1137 6.70625C15.4259 7.02181 15.582 7.36959 15.582 7.74959V8.37175C15.582 8.51165 15.5626 8.67266 15.5237 8.85477C15.4848 9.03687 15.4431 9.18514 15.3987 9.29959L13.3987 13.9496C13.2765 14.2607 13.0489 14.5329 12.7161 14.7663C12.3833 14.9996 12.0305 15.1163 11.6578 15.1163H5.41536ZM1.84663 15.1163C1.57023 15.1163 1.33203 15.0179 1.13203 14.8212C0.932031 14.6244 0.832031 14.3839 0.832031 14.0996V7.26625C0.832031 6.98848 0.92532 6.74681 1.1119 6.54125C1.29848 6.3357 1.53459 6.23292 1.82023 6.23292H1.87023C2.14476 6.23292 2.38481 6.3357 2.59036 6.54125C2.79592 6.74681 2.8987 6.98848 2.8987 7.26625V14.0996C2.8987 14.3839 2.79572 14.6244 2.58976 14.8212C2.3838 15.0179 2.14164 15.1163 1.8633 15.1163H1.84663Z" fill="#FF8114"/>
                        </svg>
                    </span>
                    <span className='settings-component-subtitle'>Empower your words: crafting positivity with ease</span>
                </div> : false
            }
            
            <div className={`${!onlyInput ? 'settings-component-side' : ''} flex-col max-w-[540px] w-100`}>
                { !onlyInput ?
                    <div className="d-flex flex-wrap gap-[8px] mb-[30px]">
                        {
                            keepPositiveData.map((item) => {
                                return <a
                                    href="#" className={`keep-it-positive-badge-item ${item.isFavorite ? 'active' : ''}`} key={item.id}
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        return keepPositiveToggleAction(item.id);
                                    }}
                                >
                                    <span className="text-[14px]">{ item.text }</span>
                                    {
                                        item.isFavorite ? <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-[7px] keep-it-positive-check-icon">
                                            <path d="M8.73047 11.3955L7.08464 9.72884C6.90686 9.56217 6.68803 9.47884 6.42816 9.47884C6.16828 9.47884 5.94266 9.56565 5.7513 9.73926C5.57075 9.92676 5.48047 10.1525 5.48047 10.4163C5.48047 10.6802 5.57769 10.8886 5.77214 11.0413L8.0638 13.3538C8.23426 13.5483 8.45396 13.6455 8.72289 13.6455C8.99183 13.6455 9.22353 13.5483 9.41797 13.3538L14.1471 8.62467C14.3416 8.4469 14.4388 8.23154 14.4388 7.97861C14.4388 7.72568 14.3427 7.49659 14.1505 7.29134C13.9583 7.12467 13.7283 7.04481 13.4607 7.05176C13.193 7.0587 12.9704 7.14551 12.793 7.31217L8.73047 11.3955ZM10.0042 18.7705C8.79893 18.7705 7.65931 18.5441 6.58532 18.0913C5.51132 17.6384 4.57676 17.0145 3.78164 16.2193C2.98651 15.4242 2.36254 14.4906 1.90972 13.4185C1.45689 12.3465 1.23047 11.2078 1.23047 10.0026C1.23047 8.79374 1.45729 7.65425 1.91093 6.58407C2.36458 5.51389 2.9897 4.5799 3.78628 3.78211C4.58288 2.98431 5.51589 2.35583 6.58532 1.8967C7.65477 1.43757 8.79189 1.20801 9.9967 1.20801C11.2065 1.20801 12.3476 1.43692 13.4199 1.89474C14.4923 2.35256 15.4267 2.97934 16.2233 3.77509C17.0198 4.57084 17.6472 5.50452 18.1055 6.57613C18.5638 7.64776 18.793 8.78952 18.793 10.0014C18.793 11.2124 18.5634 12.3513 18.1043 13.4181C17.6451 14.4849 17.0167 15.4169 16.2189 16.2139C15.4211 17.011 14.488 17.6364 13.4198 18.09C12.3515 18.5437 11.213 18.7705 10.0042 18.7705Z" fill="#FF8114"/>
                                        </svg> :
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-[7px] keep-it-positive-plus-icon">
                                            <path d="M9.99766 16.1462C9.74141 16.1462 9.5217 16.0585 9.33855 15.8831C9.15539 15.7078 9.0638 15.476 9.0638 15.1878V10.9587H4.79297C4.53776 10.9587 4.3177 10.8645 4.1328 10.6762C3.94791 10.4878 3.85547 10.2635 3.85547 10.0032C3.85547 9.72352 3.94791 9.49685 4.1328 9.32324C4.3177 9.14963 4.53776 9.06283 4.79297 9.06283H9.0638V4.79199C9.0638 4.51734 9.15657 4.29242 9.34209 4.11724C9.52762 3.94208 9.74846 3.85449 10.0046 3.85449C10.2607 3.85449 10.4839 3.94208 10.6742 4.11724C10.8645 4.29242 10.9596 4.51734 10.9596 4.79199V9.06283H15.2096C15.4707 9.06283 15.6957 9.15085 15.8846 9.32689C16.0735 9.50294 16.168 9.72736 16.168 10.0001C16.168 10.2729 16.0735 10.5009 15.8846 10.684C15.6957 10.8671 15.4707 10.9587 15.2096 10.9587H10.9596V15.1878C10.9596 15.476 10.8648 15.7078 10.6753 15.8831C10.4857 16.0585 10.2598 16.1462 9.99766 16.1462Z" fill="white" fillOpacity="0.5"/>
                                        </svg>
                                    }
                                    <a href="#" className="ml-[7px] items-center keep-it-positive-remove-btn" onClick={(e)=>{
                                        e.preventDefault();
                                        e.stopPropagation();
                                        keepPositiveDelete(item.id);
                                    }}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(45deg)' }}>
                                            <path d="M9.99766 16.1462C9.74141 16.1462 9.5217 16.0585 9.33855 15.8831C9.15539 15.7078 9.0638 15.476 9.0638 15.1878V10.9587H4.79297C4.53776 10.9587 4.3177 10.8645 4.1328 10.6762C3.94791 10.4878 3.85547 10.2635 3.85547 10.0032C3.85547 9.72352 3.94791 9.49685 4.1328 9.32324C4.3177 9.14963 4.53776 9.06283 4.79297 9.06283H9.0638V4.79199C9.0638 4.51734 9.15657 4.29242 9.34209 4.11724C9.52762 3.94208 9.74846 3.85449 10.0046 3.85449C10.2607 3.85449 10.4839 3.94208 10.6742 4.11724C10.8645 4.29242 10.9596 4.51734 10.9596 4.79199V9.06283H15.2096C15.4707 9.06283 15.6957 9.15085 15.8846 9.32689C16.0735 9.50294 16.168 9.72736 16.168 10.0001C16.168 10.2729 16.0735 10.5009 15.8846 10.684C15.6957 10.8671 15.4707 10.9587 15.2096 10.9587H10.9596V15.1878C10.9596 15.476 10.8648 15.7078 10.6753 15.8831C10.4857 16.0585 10.2598 16.1462 9.99766 16.1462Z" fill="white" fillOpacity="0.5"/>
                                        </svg>
                                    </a>
                                </a>
                            })
                        }
                        { !addCustomActive ? 
                            <a
                                href="#" className={`keep-it-positive-badge-item add-btn ${addCustomActive ? 'active' : ''}`}
                                onClick={(e)=>{
                                    e.preventDefault();
                                    setAddCustomActive((prevState) => !prevState);
                                }}
                            >
                                <span className="text-[14px]">Add Custom</span>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-[7px]">
                                    <path d="M9.99766 16.1462C9.74141 16.1462 9.5217 16.0585 9.33855 15.8831C9.15539 15.7078 9.0638 15.476 9.0638 15.1878V10.9587H4.79297C4.53776 10.9587 4.3177 10.8645 4.1328 10.6762C3.94791 10.4878 3.85547 10.2635 3.85547 10.0032C3.85547 9.72352 3.94791 9.49685 4.1328 9.32324C4.3177 9.14963 4.53776 9.06283 4.79297 9.06283H9.0638V4.79199C9.0638 4.51734 9.15657 4.29242 9.34209 4.11724C9.52762 3.94208 9.74846 3.85449 10.0046 3.85449C10.2607 3.85449 10.4839 3.94208 10.6742 4.11724C10.8645 4.29242 10.9596 4.51734 10.9596 4.79199V9.06283H15.2096C15.4707 9.06283 15.6957 9.15085 15.8846 9.32689C16.0735 9.50294 16.168 9.72736 16.168 10.0001C16.168 10.2729 16.0735 10.5009 15.8846 10.684C15.6957 10.8671 15.4707 10.9587 15.2096 10.9587H10.9596V15.1878C10.9596 15.476 10.8648 15.7078 10.6753 15.8831C10.4857 16.0585 10.2598 16.1462 9.99766 16.1462Z" fill="white" fillOpacity="0.5"/>
                                </svg>
                            </a>
                        : null }
                    </div> 
                : null }
                { addCustomActive ?
                <div className='flex flex-col gap-[8px]'>
                    { !onlyInput ?
                    <Label htmlFor='add-custom-option'>
                        Add Custom Option
                    </Label> : null }
                    <div className='flex gap-[12px]'>
                        <Input
                            id='add-custom-option'
                            placeholder={placeholder}
                            type='text'
                            value={payload}
                            onChange={(e) => setPayload(e.target.value)}
                        />
                        <PrimaryButton
                            onClick={() => {
                                createKeepPositive();
                            }}
                        >
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.99766 16.1462C9.74141 16.1462 9.5217 16.0585 9.33855 15.8831C9.15539 15.7078 9.0638 15.476 9.0638 15.1878V10.9587H4.79297C4.53776 10.9587 4.3177 10.8645 4.1328 10.6762C3.94791 10.4878 3.85547 10.2635 3.85547 10.0032C3.85547 9.72352 3.94791 9.49685 4.1328 9.32324C4.3177 9.14963 4.53776 9.06283 4.79297 9.06283H9.0638V4.79199C9.0638 4.51734 9.15657 4.29242 9.34209 4.11724C9.52762 3.94208 9.74846 3.85449 10.0046 3.85449C10.2607 3.85449 10.4839 3.94208 10.6742 4.11724C10.8645 4.29242 10.9596 4.51734 10.9596 4.79199V9.06283H15.2096C15.4707 9.06283 15.6957 9.15085 15.8846 9.32689C16.0735 9.50294 16.168 9.72736 16.168 10.0001C16.168 10.2729 16.0735 10.5009 15.8846 10.684C15.6957 10.8671 15.4707 10.9587 15.2096 10.9587H10.9596V15.1878C10.9596 15.476 10.8648 15.7078 10.6753 15.8831C10.4857 16.0585 10.2598 16.1462 9.99766 16.1462Z" fill="#331A04"/>
                            </svg>
                            <span>Add</span>
                        </PrimaryButton>
                        { !onlyInput ?
                        <button
                            className="btn btn-outline-black btn-lg-2 rounded-full" style={{ padding: "8px" }}
                            onClick={(e)=>{
                                e.preventDefault();
                                setAddCustomActive((prevState) => !prevState);
                                setPayload('');
                            }}
                        >
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.5">
                                    <path d="M7.33203 7.33301L14.6654 14.6663" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M14.6654 7.33301L7.33203 14.6663" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </g>
                            </svg>
                        </button> : null }
                    </div>
                </div> : null }
            </div>
        </div>
    </>
};

export default KeepItPositive;