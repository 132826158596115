import { useSelector } from "react-redux";
import { getToken } from "../../../redux/slices/applicationSlice";
import { getActiveBlogIndex, getGeneratedBlogs, setActiveBlogIndex } from "../../../redux/slices/blogGeneratorSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ReactComponent as ArrowIcon } from '../Blog/icons/arrow.svg';
import api from "../../../api";
import classNames from "classnames";
import { format } from "date-fns";
import EtsyNewProductCardContent from "./EtsyNewProductCardContent";

const EtsyNewProductCard = ({data, index, setCardFullScreen, isFullScreen, isNotDone = false, researchCard = false, researchCardFullScreen, setResearchCardFullScreen }) => {

    const token = useSelector(getToken);
    const activeBlogIndex = useSelector(getActiveBlogIndex);
    const blogs = useSelector(getGeneratedBlogs);
    const {activeVersionIndex } = blogs[index] || {};
    const { generatedDate, selectedVersionId } = blogs[index];
    const dispatch = useDispatch();
    const isOpen = index === activeBlogIndex;

    const isSelected = selectedVersionId === data[activeVersionIndex]?.id;

    const { id } = useParams();
    const [updateBlogs, setUpdateBlogs] = useState(false);
    const [cardOpen, setCardOpen] = useState(false);
    const [researchCardOpen, setResearchCardOpen] = useState(false);
    
    useEffect(() => {
        if(updateBlogs) {
            api.assets.changeAsset({
                id,
                data: {
                    content: JSON.stringify(blogs)
                },
                token
            }).catch(e => console.log(e))
            setUpdateBlogs(false);
        }
    }, [updateBlogs])

    useEffect(() => {
        if(researchCard && !researchCardFullScreen) {
            setResearchCardOpen(false);
        }
    }, [isFullScreen])

    useEffect(() => {
        if(researchCardFullScreen){
            setCardOpen(false);
        }
    }, [researchCardFullScreen])

    useEffect(() => {
        setCardOpen(isOpen);
    }, [isOpen])
    

    const markup = data[activeVersionIndex]?.activeContent || data[activeVersionIndex]?.content;

    return <><li 
        className={classNames(
            'blog-post-card', 
            (researchCard ? researchCardFullScreen : isFullScreen) ? 'h-full' : (researchCard ? isOpen && researchCardOpen : cardOpen) ? 'h-[442px]' : 'h-[42px]',
            ((researchCard ? researchCardFullScreen : isFullScreen) && (researchCard ? !isOpen && !researchCardOpen : !cardOpen)) ? 'hidden': 'flex'
        )}
        style={{
            borderColor: isSelected ? '#FF8114': '#1F1F1F'
        }}
    >
        <div 
            className='flex items-center justify-between py-[11px] px-[16px]'
        >
            <div className={`gen-con-header-title gap-[8px] cursor-pointer ${isNotDone ? 'disabled' : ''}`} onClick={() => {
                dispatch(setActiveBlogIndex(index));
                if(researchCard){
                    setResearchCardOpen(!researchCardOpen);
                }
            }}>
                <div
                    className={`gen-header-arrow pointer-events-none ${(researchCard ? isOpen && researchCardOpen : cardOpen) && 'rotate-180'}`}
                >
                    <ArrowIcon />
                </div>
                <span className="leading-[18px]">{researchCard ? 'Research' : `Content ${index + 1}`}</span>
            </div>
            <div className="gen-con-header-date leading-[18px]">
                Generated  {format(generatedDate ? new Date(generatedDate) : new Date(), 'dd.MM.yyyy, H:mm')}
            </div>
        </div>
        
        {(researchCard ? isOpen && researchCardOpen : cardOpen) && <>
            <EtsyNewProductCardContent
                key={activeVersionIndex}
                index={index}
                isFullScreen={researchCard ? researchCardFullScreen : isFullScreen}
                setCardFullScreen={researchCard ? (value)=>{ setResearchCardFullScreen(value); } : setCardFullScreen}
                setUpdateBlogs={setUpdateBlogs}
                markup={markup}
                isNotDone={isNotDone}
                data={data[activeVersionIndex]}
                researchCard={researchCard}
            />
        </> }
    </li>
    {/* <div dangerouslySetInnerHTML={{ __html: markup }}></div> */}
    </>;
};

export default EtsyNewProductCard;