import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { store } from './redux/store';
import { Provider } from 'react-redux';

import './assets/css/reset.css'
import './assets/css/main-alpha.css'
import './assets/css/main-bravo.css'
import './assets/css/main-charlie.css'
import './assets/css/media-alpha.css'
import './assets/css/media-bravo.css'
import './assets/fonts/gilroy/style.css'

// StrictMode ломает drag and drop
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<App />
	</Provider>
);