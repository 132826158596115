import axios from "axios";
import { apiConfig, axiosConfig } from "./config";

import { openai } from "./parts/openai";
import { auth } from "./parts/auth";
import { users } from "./parts/users";
import { assets } from "./parts/assets";
import { settings } from "./parts/settings";
import { payments } from "./parts/payments";
import { paymentCards } from "./parts/paymentCards";
import { refferals } from "./parts/refferals";
import { pricingPlans } from "./parts/pricingPlans";
import { assetsHistories } from "./parts/assetsHistories";
import { assetsReminder } from "./parts/assetsReminder";
import { newWords } from "./parts/newWords";
import { reports } from "./parts/reports";
import { feedbacks } from "./parts/feedbacks";
import { generationForms } from "./parts/generationForms";
import { botKits } from "./parts/botKits";
import { botChatMessages } from "./parts/botChatMessages";
import { courses } from "./parts/courses";
import { collections } from "./parts/collections";
import { share } from "./parts/share";

const instance = axios.create(axiosConfig);
const apiInstance = axios.create(apiConfig);
// apiInstance.interceptors.request.use((config) => {
//     const token = localStorage.getItem('authToken');
//     if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
// });

const api = {
    openai: openai(instance),
    auth: auth(apiInstance),
    users: users(apiInstance),
    assets: assets(apiInstance),
    settings: settings(apiInstance),
    payments: payments(apiInstance),
    paymentCards: paymentCards(apiInstance),
    refferals: refferals(apiInstance),
    pricingPlans: pricingPlans(apiInstance),
    assetsHistories: assetsHistories(apiInstance),
    assetsReminder: assetsReminder(apiInstance),
    newWords: newWords(apiInstance),
    reports: reports(apiInstance),
    feedbacks: feedbacks(apiInstance),
    generationForms: generationForms(apiInstance),
    botKits: botKits(apiInstance),
    botChatMessages: botChatMessages(apiInstance),
    courses: courses(apiInstance),
    collections: collections(apiInstance),
    share: share(apiInstance)
}

export default api;

