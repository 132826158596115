import React, { Fragment, useContext, useEffect, useState } from 'react'
import classNames from 'classnames';
import logo from '../../../assets/img/gen4logo.svg';
import { navLinks } from './consts';
import { GenerateFormContext } from '../../GeneratorV4/GeneratorContextComponent';
import BottomPanel from '../LandingContent/BottomPanel';
import { ReactComponent as ArrowIcon } from '../Blog/icons/arrow.svg';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getSocket, getToken } from '../../../redux/slices/applicationSlice';
import { 
    getActiveBlogIndex, getGeneratedBlogs, getGenerateLoading,
    setBlogs, setBlogDone, setBlogVersions, setGenerateLoading, setActiveBlogIndex, setBlogVersionAddon, resetBlogGenerator,
} from '../../../redux/slices/blogGeneratorSlice';
import Modals from '../LandingContent/Modals';
import AddonCard from '../Blog/AddonCard';
import LandingPageCard from '../LandingContent/LandingPageCard';
import ProductPageCard from '../ProductDescription/ProductPageCard';
import GeneratedContentModal from '../GeneratedContentModal';
import HelpModal from '../../../components/base/HelpModal';
import { toast } from 'react-toastify';
import api from '../../../api';
import { SidePanel } from './SidePanel';
import ReleasePageCard from './ReleasePageCard';
import PressAddonCard from './PressAddonCard';

const PressReleaseGeneretedContent = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const token = useSelector(getToken);
    const socket = useSelector(getSocket);
    const generateLoading = useSelector(getGenerateLoading);
    const activeBlogIndex = useSelector(getActiveBlogIndex);
    const blogs = useSelector(getGeneratedBlogs);
    console.log('blogs:', blogs);

    const [openedModal, setOpenedModal] = useState();
    const [configForm, setConfigForm] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [toastId, setToastId] = useState(null);
    const [cardFullScreen, setCardFullScreen] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);

    
    
    const activeBlog = blogs[activeBlogIndex];

    const isNotDone = generateLoading ? blogs.filter(item => !item?.done).length : false;
    console.log(isNotDone, 'done');
    // const isNotDone = false

    const wordsCount = blogs.reduce((accumulator, blog) => {
        const blogWordCount = blog.versions.reduce((accumulator, version) => {
            const displayText = version?.activeContent || version?.content || '';
            return accumulator + displayText.split(' ').filter(word => word.length > 0).length;
        }, 0);
        return accumulator + blogWordCount
    }, 0)

    const closeModal = () => setShowHelpModal(false);
    
    // copy
    const duplicate = async() => {
        if(!navigate || !id || !token) return;
        
        const response = await api.assets.cloneAsset({
            id,
            token
        });

        if(response?.success){
            setOpenedModal({
                name: 'duplicate',
                title: `Blog “${blogs?.[0]?.blog_topic}” successfully duplicated as: ”${response.data?.name}”`
            });

            return;
        }

        setOpenedModal(null);
    }

    const deleteAction = async() => {
        const response = await api.assets.deleteAsset({
            id,
            token
        });

        if(response.success){
            setOpenedModal({
                name: 'save',
                title: response.message,
                text: ''
            });

            setTimeout(() => {
                navigate('/my-assets');
            }, 1500);

            return;
        }

        setOpenedModal(null);
    }

    const save = async() => {
        let res = await api.assets.changeAsset({
            id,
            data: {
                content: JSON.stringify(blogs)
            },
            token
        });

        if(res?.success){
            setOpenedModal({
                name: 'save',
                title: 'Saved',
                text: `Your blog has been successfully saved`
            })
        } else {
            console.error(res);
        }
    }

    const handleExport = async (id, ext) => {
        await api.assets.exportAsset({
            token,
            id,
            type: 'blog',
            ext,
            filename: `${blogs[activeBlogIndex].blog_topic}.${ext}`,
        });

        setOpenedModal(null);
    }
    // end copy
    const handleGenerate = async () => {

        try {
            console.log('handleGenerate configForm:', configForm);

            setIsLoading(true);

            const formatedAddons = configForm.add_ons

            let settings = {
                name: configForm.name,
                subject: configForm.subject,
                key_message: configForm.key_message,
                quotes: configForm.quotes,
                contact: configForm.contact,
                distribution_date: configForm.distribution_date,
                target_audience: configForm.target_audience,
                addons: formatedAddons,

                language: 'English',
                tone: 'Professional',
            }

            const response = await api.assets.changeAsset({
                id,
                data: {
                    content: JSON.stringify(
                        [...blogs,
                            {
                            add_ons: formatedAddons,
                            settings,
                            versions: [],
                            activeVersionIndex: 0,
                            generatedDate: new Date().toUTCString(),
                            selectedVersionId: null,
                            addonsValues: []
                        }
                    ])
                },
                token
            });

            setIsLoading(false)
            if(!response.success) return;
            // navigate(`/generated-page/product-description/${response.data.id}`);
            const dataRes = JSON.parse(response.data.content);
            const lastDataRes = dataRes[dataRes.length - 1];
            dispatch(setBlogs(dataRes));
            dispatch(setActiveBlogIndex(dataRes.length - 1));
            dispatch(setGenerateLoading(true));

            socket.send({
                event: 'press-release-create',
                payload: {
                    name: configForm.name,
                    subject: configForm.subject,
                    key_message: configForm.key_message,
                    quotes: configForm.quotes,
                    contact: configForm.contact,
                    distribution_date: configForm.distribution_date,
                    target_audience: configForm.target_audience,

                    add_ons: formatedAddons,
                },
                handler: (resData) => {
                    if(resData?.isError){
                        setIsLoading(false)
                        dispatch(setGenerateLoading(false));
                        toast.error("AI bot is not available at the moment");
                        return;
                    }

                    dispatch(setBlogVersions([
                        {
                            "isDone": resData.isDone,
                            'title': resData.data.title,
                            'content': resData.data.content,
                            'id': response.data.id,
                            'name': configForm.name,
                            'subject': configForm.subject,
                            key_message: configForm.key_message,
                            quotes: configForm.quotes,
                            contact: configForm.contact,
                            distribution_date: configForm.distribution_date,
                            target_audience: configForm.target_audience,
                            'add_ons': formatedAddons,
                        }
                    ]))

                    if(resData.isDone) {
                        if(configForm.add_ons?.length) {

                                socket.send({
                                    event: 'press-release-addons',
                                    payload: {
                                        content: resData.payload.content,
                                        title: resData.payload.title,
                                        addons: formatedAddons,
                                    },
                                    handler: (addonsResponse) => {
                                        if(addonsResponse?.isError){
                                            setIsLoading(false)
                                            dispatch(setGenerateLoading(false));
                                            toast.error("AI bot is not available at the moment");
                                            return;
                                        }

                                        dispatch(setBlogVersionAddon({
                                            index: 0,
                                            data: {
                                                ...addonsResponse.payload,
                                                isDone: addonsResponse.isDone
                                            }
                                        }));

                                        if(addonsResponse.isDone){
                                            console.log('press-release-addons done');
                                        }
                                    }
                                })
                            
                        }

                    }
                    
                }
            })
            
        } catch (error) {
            console.log(error, 'error');
        } finally {
            
            console.log('done 33333');
        }

    }


    useEffect(() => {
        if(!generateLoading) return;
        if(!toastId) {
            setToastId(toast.loading('Generating your release, please wait...'));
        }

        if(activeBlog?.versions?.length && activeBlog?.versions.every(version => version.isDone)) {
            if(activeBlog?.add_ons?.length && (activeBlog.versions?.length !== activeBlog.addonsValues?.length || activeBlog?.addonsValues.some(addon => !addon.isDone))) return;

            api.assets.changeAsset({
                id,
                data: {
                    name: blogs?.length && blogs[0]?.versions?.length ? ( blogs[0]?.versions?.length === 1 ? blogs[0].versions[0].title : blogs[0].versions.map(item => item.title).join(', ') ) : 'Product description',
                    content: JSON.stringify(blogs)
                },
                token
            }).then(resp => {console.log(resp)});

            toast.update(toastId, {
                render: 'Generated successfully!',
                type: 'success', 
                isLoading: false,
                autoClose: 5000
            });

            dispatch(setGenerateLoading(false));
            setToastId(null);

            dispatch(setBlogDone(true));
        }
    }, [activeBlog, generateLoading, blogs, id, token, dispatch, toastId])

    useEffect(() => {
        if(!navigate || !id || !dispatch || !token) return;
        const fetchBlogData = async () => {
            try {
                const response = await api.assets.getAssetById({
                    id,
                    token
                });
                if(!response.success) return;
                dispatch(setBlogs(JSON.parse(response.data.content)));
            } catch (e) {
                if(e.response?.status === 404) {
                    navigate('/my-assets')
                }
            }
        }
        fetchBlogData();
    }, [id, navigate, dispatch, token])


    useEffect(() => {
        if(!blogs.length) return;
        setConfigForm({
            ...blogs[activeBlogIndex]?.settings,
            add_ons: blogs[activeBlogIndex]?.settings?.addons || [],
        });
    }, [blogs, activeBlogIndex])


    return (
        <main className='w-screen h-screen overflow-hidden flex'>
            
            <SidePanel
                cardFullScreen={cardFullScreen}
                configForm={configForm}
                setConfigForm={setConfigForm}
                setIsLoading={setIsLoading}
                handleGenerate={handleGenerate}
                isNotDone={isNotDone}
            />

        {isLoading ? <div className="flex flex-col justify-center items-center h-full flex-1">
            <div className="lds-ring"><div /><div /><div /><div /></div>
            <div className="mt-[36px] text-[18px] font-medium leading-[13px] opacity-90">Loading...</div>
            <div className="mt-[24px] text-[14px] leading-[10px] opacity-50 text-[#A7A7A7]">Please wait for your results...</div>
        </div> :
        <div className='flex-1 flex flex-col'>
            <div className={classNames('py-[13px] px-[16px] flex items-center justify-between', {
                'px-[30px]': cardFullScreen
            })}>
                <div>
                    <h1 className='text-[14px] font-medium leading-normal'>
                        Generated Content
                    </h1>
                    <div className='text-[12px] leading-[14px] opacity-40 flex items-center gap-[5px]'>
                        Text
                        <div className='w-[3px] h-[3px] rounded-full bg-white'/>
                        Product
                        {/* <div className='w-[3px] h-[3px] rounded-full bg-white'/>
                        No Generated Content */}
                    </div>
                </div>
                <button 
                    onClick={() => setShowHelpModal(true)}
                    className='w-[32px] h-[32px] flex items-center justify-center opacity-50'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M9.68457 9.68482C9.92159 8.66326 10.852 7.95496 11.8998 7.99844C13.0725 7.93349 14.0778 8.82709 14.1508 9.99928C14.1508 11.5038 11.9999 12.0001 11.9999 13.0005" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12.1253 15.7523C12.1252 15.8213 12.0692 15.8772 12.0002 15.8772C11.9311 15.8772 11.8752 15.8212 11.8752 15.7522C11.8751 15.6832 11.931 15.6272 12 15.6271C12.0333 15.6271 12.0651 15.6402 12.0886 15.6637C12.1121 15.6872 12.1253 15.7191 12.1253 15.7523" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M4.62861 17.1605C1.94551 13.3308 2.63502 8.08546 6.21672 5.07926C9.79842 2.07305 15.0839 2.30347 18.3903 5.60997C21.6968 8.91646 21.9273 14.2019 18.9211 17.7836C15.9149 21.3653 10.6695 22.0548 6.8398 19.3717L3.99688 20.0034L4.62861 17.1605Z" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
            <div className='py-[4px] px-[16px] pb-[12px] flex-1 gap-[12px] overflow-y-scroll no-scroll'>
                <ul className={classNames('flex flex-col gap-[12px]', {
                    'h-full': cardFullScreen
                })}>
                    {blogs.map((data, index) => {
                        return <Fragment key={index}>
                            <ReleasePageCard
                                isNotDone={isNotDone}
                                data={data.versions} 
                                index={index}
                                setCardFullScreen={setCardFullScreen}
                                isFullScreen={cardFullScreen}
                            />
                            { Boolean(data?.addonsValues?.length) && data?.add_ons?.map((addonItem, addonIndex) => 
                            <PressAddonCard
                                key={addonItem?.title}
                                title={addonItem?.title}
                                data={data}
                                index={index}
                                addonIndex={addonIndex}
                                forceActiveVersionIndex={data?.activeVersionIndex}
                                isFullScreen={cardFullScreen}
                                selectFromVersion={true}
                            />)}
                        </Fragment>
                    })}
                </ul>
            </div>

            <BottomPanel
                blogs={blogs}
                wordsCount={wordsCount}
                isNotDone={isNotDone}
                setOpenedModal={setOpenedModal}
                deleteAction={deleteAction}
                duplicate={duplicate}
                save={save}
            />
        </div>}

        <Modals
            openedModal={ openedModal }
            setOpenedModal={ setOpenedModal }
            closeModal={ closeModal }
            showHelpModal={ showHelpModal }
            GeneratedContentModal={ GeneratedContentModal }
            handleExport={ handleExport }
            id={ id }
            HelpModal={ HelpModal }
        />

        </main>
    )
}


export default PressReleaseGeneretedContent