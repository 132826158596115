import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react'
import Label from '../../../components/base/Label'
import SelectBox from '../../../components/base/SelectBox'
import { categoryOptions, languageOptions, partSpeechOptions } from '../../../configs/selectOptions';
import Input from '../../../components/base/Input';
import TextArea from '../../../components/base/TextArea';

const EditorContent = forwardRef(({ formValues, setFormValues, returnCurrentIdx }, ref) => {

    const [form, setForm] = useState({
        ...formValues
    })


    const [init, setInit] = useState(false)
    const [noUpldate, setNoUpdate] = useState(false)

    const [formHistory, setFormHistory] = useState([])

    const [currentIndex, setCurrentIndex] = useState(-1)

    const updateFormState = index => {
        const state = formHistory[index];
        setNoUpdate(true)
        setForm(prev => ({...prev, ...state }));
        setFormValues(prev => ({...prev, ...state}))
    };

    const undo = () => {
        if (currentIndex > 0) {
            setCurrentIndex(prevIndex => prevIndex - 1);
            updateFormState(currentIndex - 1);
        }
        returnCurrentIdx(currentIndex - 1, formHistory.length -1)
      };
    
      const redo = () => {
        if (currentIndex < formHistory.length - 1) {
            setCurrentIndex(prevIndex => prevIndex + 1);
            updateFormState(currentIndex + 1);
        }
        returnCurrentIdx(currentIndex + 1, formHistory.length -1)
      };

    useEffect(() => {
        if (noUpldate) {
            setNoUpdate(false)
            return
        }
        let timer
        clearTimeout(timer)
        timer = setTimeout(() => {
            setFormHistory(prev => [...prev, form])
            const length = formHistory.length
            setCurrentIndex(length)
            returnCurrentIdx(length, length)
            setFormValues(prev => ({...prev, ...form}))
        }, 800)


        return () => clearTimeout(timer)
    }, [form])

    useImperativeHandle(ref, () => ({
        undo,
        redo,
      }));


    return <>
        <div className='flex gap-[14px]'>
            <div className='flex flex-col gap-[10px] flex-1'>
                <Label>Part of Speech</Label>
                <SelectBox
                    className='default-select flex-1'
                    propsOptions={partSpeechOptions}
                    value={form.part_speech.toLowerCase()}

                    change={(part_speech) => setForm(prev => ({...prev, part_speech}))}
                />
            </div>
            <div className='flex flex-col gap-[10px] flex-1'>
                <Label>Word Category</Label>
                <SelectBox
                    className='default-select flex-1'
                    propsOptions={categoryOptions}
                    value={form.word_category.toLowerCase()}
                    change={(word_category) => setForm(prev => ({...prev, word_category}))}
                />
            </div>
            <div className='flex flex-col gap-[10px] flex-1'>
                <Label>Pronunciation</Label>
                <Input
                    value={form.pronunciation}
                    className='w-full'

                    onChange={(e) => setForm(prev => ({...prev, pronunciation: e.target.value}))}
                />
            </div>
            <div className='flex flex-col gap-[10px] flex-1'>
                <Label>Syllable Division</Label>
                <Input
                    value={form.syllable_division}
                    className='w-full'

                    onChange={(e) => setForm(prev => ({...prev, syllable_division: e.target.value}))}
                />
            </div>     
        </div>
        <div className='flex flex-col gap-[10px]'>
            <Label>Definition</Label>
            <TextArea
                value={form.definition}
                className='h-[120px]'

                change={(definition) => setForm(prev => ({...prev, definition}))}
            />
        </div>
        <div className='flex flex-col gap-[10px]'>
            <Label>Example</Label>
            <TextArea
                value={form.example}
                className='h-[120px]'
                change={(example) => setForm(prev => ({...prev, example}))}
            />
        </div>
    </>
})

export default EditorContent