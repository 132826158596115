import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from '../../assets/img/icons/icons-plus.svg';
import { ReactComponent as CheckIcon } from '../../assets/img/icons/icon-check-circle.svg';
import { ReactComponent as CloseIcon } from '../../assets/img/icons/icon-close.svg';
import { useSelector } from 'react-redux';
import { getFormOptions } from '../../redux/slices/applicationSlice';

// const addOns = {
//     "SEO": ["Subject Headline", "Meta Description", "Page Title", "Image Title"],
//     "Meta Tags": ["Meta Tags 1", "Meta Tags 2", "Meta Tags 3", "Meta Tags 4"], 
//     "Social Media": ["Social Media 1", "Social Media 2", "Social Media 3", "Social Media 4"],
//     "Ads": ["Ads 1", "Ads 2", "Ads 3", "Ads 4"],
//     "Misc": ["Misc 1", "Misc 2", "Misc 3", "Misc 4"]
// }

const Addons = ({ data, setData, label = 'Add Custom Add-on' }) => {
    const [selectedAddon, setSelectedAddon] = useState('SEO');
    const [inputValue, setInputValue] = useState('');
    const addOns = useSelector(getFormOptions).filter(({key}) => key === 'addons');
    return <div className='flex flex-col gap-[16px]'>
        { data?.length ? <ul className='flex gap-[8px] flex-wrap'>
            {data.map((option, index) => <li
                key={ index }
                className={classNames(`text-[#ffffff80] flex h-[26px] pl-[10px] pr-[6px] items-center gap-[6px] text-[12px]
                border border-solid bg-[#202020] rounded-[24px] border-[#383838]`)}
            >
                {option}
                <CloseIcon className='cursor-pointer' onClick={() => setData(data.filter(item => item !== option))}/>
            </li>)}
        </ul> : null }
        <ul className='flex gap-[5px]'>
            {addOns.map((addon, index) => <li
                key={ index }
                className={classNames(`h-[40px] px-[8px] flex-1 flex items-center justify-center 
                rounded-[8px] bg-[#202020] cursor-pointer`, 
                addon.title === selectedAddon ? 'border border-solid border-orange-primary text-orange-primary' : 'text-[#ffffff80]')}
                onClick={() => setSelectedAddon(addon.title)}
            >
                <span className='text-[13px] font-medium text-center'>{addon.title}</span>
            </li>)}
        </ul>
        <ul className='flex gap-[8px] flex-wrap'>
            {addOns.find(({title}) => title === selectedAddon)?.items?.map((option, index) => <li
                key={ index }
                className={classNames(`flex h-[26px] pl-[10px] pr-[6px] items-center gap-[6px] text-[12px]
                border border-solid bg-[#202020] rounded-[24px] cursor-pointer`,
                data.includes(option) ? 'border-orange-primary text-orange-primary' : 'border-[#383838] text-[#ffffff80]')}
                onClick={() => {
                    if(data.includes(option)) {
                        // setData(data.filter(item => item !== option));
                    } else {
                        setData([...data, option]);
                    }
                }}
            >
                {option}
                {data.includes(option) ? <CheckIcon /> : <PlusIcon />}
            </li>)}
        </ul>
        
        <div className='flex items-center gap-[16px]'>
            <div className='h-[1px] flex-1 bg-[#1F1F1F]'/>
            <span className='text-[12px] text-[#A7A7A7]'>or</span>
            <div className='h-[1px] flex-1 bg-[#1F1F1F]'/>
        </div>
        <div>
            <label 
                className='text-[#A7A7A7] text-[12px] leading-[14px] block mb-[10px]'
                htmlFor='add-on'
            >
                { label }
            </label>
            <div className='flex gap-[10px]'>
                <input
                    id='add-on'
                    className='h-[40px] p-[8px] px-[14px] flex-1 bg-[#1B1B1B] rounded-[8px]
                    border border-solid border-[#333] text-[14px]'
                    placeholder='Ex. Page Title, Meta Description...'
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                />
                <button 
                    className={`h-[40px] flex items-center justify-center gap-[8px] px-[12px] radial-bg rounded-full text-[#331A04] text-[14px] font-medium ${!inputValue ? 'block-disabled' : ''}`}
                    disabled={!inputValue}
                    onClick={() => {
                        setData([...data, inputValue]);
                        setInputValue('');
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10 4.16699V15.8337" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4.16602 10H15.8327" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    Add
                </button>
            </div>
        </div>
    </div>
};

export default Addons;