import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    EmailShareButton,
    WhatsappShareButton,
    RedditShareButton,
    RedditIcon,
    
} from "react-share";
import { toast } from 'react-toastify';
import api from '../../../api';
import { useSelector } from 'react-redux';
import { getToken } from '../../../redux/slices/applicationSlice';

const sharePopupActions = [
    [
        TwitterShareButton,
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.9">
                <path d="M22.4591 6C21.6891 6.35 20.8591 6.58 19.9991 6.69C20.8791 6.16 21.5591 5.32 21.8791 4.31C21.0491 4.81 20.1291 5.16 19.1591 5.36C18.3691 4.5 17.2591 4 15.9991 4C13.6491 4 11.7291 5.92 11.7291 8.29C11.7291 8.63 11.7691 8.96 11.8391 9.27C8.27906 9.09 5.10906 7.38 2.99906 4.79C2.62906 5.42 2.41906 6.16 2.41906 6.94C2.41906 8.43 3.16906 9.75 4.32906 10.5C3.61906 10.5 2.95906 10.3 2.37906 10V10.03C2.37906 12.11 3.85906 13.85 5.81906 14.24C5.18979 14.4122 4.52916 14.4362 3.88906 14.31C4.16067 15.1625 4.6926 15.9084 5.41008 16.4429C6.12756 16.9775 6.99451 17.2737 7.88906 17.29C6.37269 18.4904 4.49306 19.1393 2.55906 19.13C2.21906 19.13 1.87906 19.11 1.53906 19.07C3.43906 20.29 5.69906 21 8.11906 21C15.9991 21 20.3291 14.46 20.3291 8.79C20.3291 8.6 20.3291 8.42 20.3191 8.23C21.1591 7.63 21.8791 6.87 22.4591 6Z" fill="white"/>
            </g>
        </svg>,
        'Twitter',
        () => {}
    ], [   
        FacebookShareButton,
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.9">
                <path d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 16.84 5.44 20.87 10 21.8V15H8V12H10V9.5C10 7.57 11.57 6 13.5 6H16V9H14C13.45 9 13 9.45 13 10V12H16V15H13V21.95C18.05 21.45 22 17.19 22 12Z" fill="white"/>
            </g>
        </svg>,
        'Facebook',
        () => {}
    ], 
    [   
        null,
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.9">
                <path d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 16.84 5.44 20.87 10 21.8V15H8V12H10V9.5C10 7.57 11.57 6 13.5 6H16V9H14C13.45 9 13 9.45 13 10V12H16V15H13V21.95C18.05 21.45 22 17.19 22 12Z" fill="white"/>
            </g>
        </svg>,
        'WhatsApp',
        () => {}
    ], 
    [
        WhatsappShareButton,
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g opacity="0.9">
                <path d="M15.8737 4.09204C15.1097 3.32031 14.1997 2.70843 13.1967 2.29208C12.1938 1.87574 11.118 1.66326 10.032 1.66704C5.48203 1.66704 1.7737 5.37537 1.7737 9.92537C1.7737 11.3837 2.15703 12.8004 2.8737 14.0504L1.70703 18.3337L6.08203 17.1837C7.29036 17.842 8.6487 18.192 10.032 18.192C14.582 18.192 18.2904 14.4837 18.2904 9.93371C18.2904 7.72537 17.432 5.65037 15.8737 4.09204ZM10.032 16.792C8.7987 16.792 7.59036 16.4587 6.53203 15.8337L6.28203 15.6837L3.68203 16.367L4.3737 13.8337L4.20703 13.5754C3.52182 12.4812 3.15798 11.2164 3.15703 9.92537C3.15703 6.14204 6.24036 3.05871 10.0237 3.05871C11.857 3.05871 13.582 3.77538 14.8737 5.07538C15.5133 5.71201 16.0201 6.46926 16.3649 7.30323C16.7096 8.1372 16.8854 9.0313 16.882 9.93371C16.8987 13.717 13.8154 16.792 10.032 16.792ZM13.7987 11.6587C13.5904 11.5587 12.5737 11.0587 12.3904 10.9837C12.1987 10.917 12.0654 10.8837 11.9237 11.0837C11.782 11.292 11.3904 11.7587 11.2737 11.892C11.157 12.0337 11.032 12.0504 10.8237 11.942C10.6154 11.842 9.9487 11.617 9.16536 10.917C8.5487 10.367 8.14036 9.69204 8.01536 9.48371C7.8987 9.27537 7.9987 9.16704 8.10703 9.05871C8.1987 8.96704 8.31536 8.81704 8.41536 8.70037C8.51536 8.58371 8.55703 8.49204 8.6237 8.35871C8.69036 8.21704 8.65703 8.10038 8.60703 8.00038C8.55703 7.90038 8.14036 6.88371 7.9737 6.46704C7.80703 6.06704 7.63203 6.11704 7.50703 6.10871H7.10703C6.96536 6.10871 6.7487 6.15871 6.55703 6.36704C6.3737 6.57538 5.84036 7.07538 5.84036 8.09204C5.84036 9.10871 6.58203 10.092 6.68203 10.2254C6.78203 10.367 8.14036 12.4504 10.207 13.342C10.6987 13.5587 11.082 13.6837 11.382 13.7754C11.8737 13.9337 12.3237 13.9087 12.682 13.8587C13.082 13.8004 13.907 13.3587 14.0737 12.8754C14.2487 12.392 14.2487 11.9837 14.1904 11.892C14.132 11.8004 14.007 11.7587 13.7987 11.6587Z" fill="white"/>
            </g>
        </svg>,
        'WhatsApp',
        () => {}
    ], [
        LinkedinShareButton,
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.9">
                <path d="M19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19ZM18.5 18.5V13.2C18.5 12.3354 18.1565 11.5062 17.5452 10.8948C16.9338 10.2835 16.1046 9.94 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17C14.6813 12.17 15.0374 12.3175 15.2999 12.5801C15.5625 12.8426 15.71 13.1987 15.71 13.57V18.5H18.5ZM6.88 8.56C7.32556 8.56 7.75288 8.383 8.06794 8.06794C8.383 7.75288 8.56 7.32556 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19C6.43178 5.19 6.00193 5.36805 5.68499 5.68499C5.36805 6.00193 5.19 6.43178 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56ZM8.27 18.5V10.13H5.5V18.5H8.27Z" fill="white"/>
            </g>
        </svg>,
        'LinkedIn',
        () => {}
    ], [
        EmailShareButton,
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3 6.8V6.8C3 7.4 3.3 7.9 3.8 8.3L9.8 12.4C11.2 13.3 12.9 13.3 14.3 12.4L20.3 8.4C20.7 7.9 21 7.4 21 6.8V6.8C21 5.8 20.2 5 19.2 5H4.8C3.8 5 3 5.8 3 6.8Z" stroke="#E9E9E9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 7V17C3 18.1 3.9 19 5 19H19C20.1 19 21 18.1 21 17V7" stroke="#E9E9E9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.58594 18.4124L9.67994 12.3184" stroke="#E9E9E9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.3594 12.3604L20.4114 18.4124" stroke="#E9E9E9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
        'Email',
        () => {}
    ],
    [   
        RedditShareButton,
        <RedditIcon width={24} height={24}/>,
        'Reddit',
        () => {}
    ], 
];

const Share = ({setOpenedModal}) => {
    const { id } = useParams();
    const location = useLocation();
    console.log(location);
    const token = useSelector(getToken);
    const [linkCopied, setLinkCopied] = useState(false);
    const [sharedHash, setSharedHash] = useState('');
    const linkToShare = `https://aikit.webstaginghub.com/shared-page/new-word/${sharedHash}`
   
    const copyCustomLink = () => {
        navigator.clipboard.writeText(linkToShare).then(() => {
            setLinkCopied(true);

            toast.success('Copied successfully!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            setTimeout(() => {
                setLinkCopied(false);
            }, 1500);
        })
    }

    useEffect(() => {
        if(!id || !token) return;
        api.share.shareKit({
            data: {
                wordId: id
            },
            token
        }).then(response => {
            if(!response.success) return
            setSharedHash(response.data.sharedHash)
        })
    }, [id, token])
    return <div className="modal__body w-[600px]">
        <button 
            className="modal-close-btn" 
            onClick={() => setOpenedModal(null)}
        >
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 8.5L16 16.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M16 8.5L8 16.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </button>
        <h3 className="text-[18px] font-[500] text-[#E8E8E8]">Share Generated Content </h3>
        <p className="mb-[24px] text-[14px] font-[400] text-[#6F6F6F]">Select on of options or copy link to share content</p>
        <div className="share-platforms-items">
            {
                sharePopupActions.map(([Button, icon, title]) => {
                    return Button ? <Button className="share-platforms-item" key={title} url={linkToShare}>
                        <span className="d-flex align-items-center mb-[14px]">
                            { icon }
                        </span>
                        <span>{ title }</span>
                    </Button> : null
                })
            }
        </div>
        <div className="input-custom">
            <label className="input-custom__label mb-[10px] text-[12px] font-[400]">Custom Link</label>
            <div className="input-custom__wrap-input icon-right">
                <input className="input-custom__input h-[40px]" type="text" placeholder="Custom Link" value={linkToShare} />
                <button className="icon-input" style={{ pointerEvents: 'initial' }} onClick={(e)=>{
                    copyCustomLink();
                }}>
                    {
                        linkCopied ? 
                        <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M6.99 26.9305L9.38 30.0585L9.896 33.9525C10.182 36.1125 11.88 37.8125 14.04 38.1005L17.944 38.6225L21.07 41.0105C22.8 42.3325 25.198 42.3325 26.928 41.0105L30.056 38.6205H30.052L33.948 38.1045C36.108 37.8185 37.808 36.1205 38.096 33.9605L38.616 30.0565C38.616 30.0585 39.824 28.4765 41.006 26.9305C42.328 25.2005 42.326 22.8025 41.006 21.0725L38.62 17.9425L38.104 14.0485C37.818 11.8885 36.12 10.1885 33.96 9.90048L30.054 9.38048L26.928 6.99248C25.198 5.67048 22.8 5.67048 21.07 6.99248L17.942 9.38048H17.946L14.05 9.89848C11.89 10.1845 10.19 11.8825 9.902 14.0425L9.38 17.9465C9.38 17.9445 8.172 19.5265 6.99 21.0725C5.67 22.8005 5.67 25.2005 6.99 26.9305V26.9305Z" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M29.6064 21.2041L22.6044 28.2061L18.3984 24.0041" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg> : <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="7.5" y="7.5" width="10" height="10" rx="2" stroke="#8F8F8F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M5 12.5H4.16667C3.24619 12.5 2.5 11.7538 2.5 10.8333V4.16667C2.5 3.24619 3.24619 2.5 4.16667 2.5H10.8333C11.7538 2.5 12.5 3.24619 12.5 4.16667V5" stroke="#8F8F8F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    }
                </button>
            </div>
        </div>
    </div>
}

export default Share;