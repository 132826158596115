import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import api from '../../api';
import { getToken } from '../../redux/slices/applicationSlice';
import { useSelector } from 'react-redux';

const NewsletterCard = ({ data, fetchCourses, setOpenedModal }) => {
    const navigate = useNavigate();
    const [openMenu, setOpenMenu] = useState(false);
    const token = useSelector(getToken);
    
    const deleteItem = async(id) => {
        setOpenedModal({
            name: 'confirm-delete',
            action: async() => {
                const response = await api.courses.deleteCourse({
                    id,
                    token
                });
                await fetchCourses();
                setOpenedModal(null);
            },
            title: `Do you really want to delete this lesson?`
        });

    }

    return <li 
        className='p-[8px] rounded-[16px] border border-solid border-[#333333] bg-[#1B1B1B] flex flex-col gap-[8px] cursor-pointer'
        onClick={(e)=>{
            e.preventDefault();
            navigate(`/generated-page/courses/${data.id}`)
        }}
    >
        <div className='flex justify-between items-center'>
            <div className='flex items-center gap-[8px]'>
                {/* <img 
                    src={imageSrc} 
                    alt={name}
                    className='w-[20px] h-[20px] rounded-full border border-solid border-white border-opacity-[0.08]'
                />
                <span className='text-[12px] opacity-80'>{name}</span> */}
            </div>
            <div className="relative">
                <a 
                    href="#" className={`w-[22px] h-[22px] d-flex items-center rounded-full ${openMenu ? 'bg-[#111111B2]' : ''}`}
                    onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        setOpenMenu(!openMenu);
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                        <g opacity="0.7">
                            <path d="M15.9196 9.99997C15.9196 10.2302 15.733 10.4168 15.5028 10.4168C15.2726 10.4168 15.0859 10.2302 15.0859 9.99997C15.0859 9.76976 15.2726 9.58313 15.5028 9.58313C15.733 9.58313 15.9196 9.76976 15.9196 9.99997" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.9157 9.99997C10.9157 10.2302 10.7291 10.4168 10.4989 10.4168C10.2687 10.4168 10.082 10.2302 10.082 9.99997C10.082 9.76976 10.2687 9.58313 10.4989 9.58313C10.7291 9.58313 10.9157 9.76976 10.9157 9.99997" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5.91571 9.99997C5.91571 10.2302 5.72909 10.4168 5.49887 10.4168C5.26866 10.4168 5.08203 10.2302 5.08203 9.99997C5.08203 9.76976 5.26866 9.58313 5.49887 9.58313C5.72909 9.58313 5.91571 9.76976 5.91571 9.99997" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                    </svg>
                </a>
                { openMenu ?
                    <ul className='action-item-dropdown'>
                        <li
                            className="action-item-dropdown-item cursor-pointer"
                            onClick={(e)=>{
                                e.preventDefault();
                                e.stopPropagation();
                                deleteItem(data.id);
                                setOpenMenu(false);
                            }}
                        >
                            Delete
                        </li>
                    </ul> : null
                }
            </div>
        </div>
        <img
            src={`https://aikit.webstaginghub.com/${data?.cover}`}
            alt={data?.title}
            loading='lazy'
            className='h-[120px] rounded-[12px] border border-solid border-white border-opacity-[0.12]'
        />
        <div className='p-[8px] flex flex-col gap-[8px]'>
            <h3 className='generated-email-title'>{data?.title}</h3>
            <p className='generated-email-paragraph'>{data?.description}</p>
            <div className='flex items-center gap-[8px]'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                    <path d="M7.75 5.69114H16.0034" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16.0034 9.86363H7.75" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.75 14.0361H16.0034" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.37125 5.31599C4.16406 5.31599 3.99609 5.48395 3.99609 5.69114C3.99609 5.89834 4.16406 6.0663 4.37125 6.0663C4.57844 6.0663 4.74641 5.89834 4.74641 5.69114C4.74641 5.48395 4.57844 5.31599 4.37125 5.31599" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.37125 9.48848C4.16406 9.48848 3.99609 9.65644 3.99609 9.86363C3.99609 10.0708 4.16406 10.2388 4.37125 10.2388C4.57844 10.2388 4.74641 10.0708 4.74641 9.86363C4.74641 9.65644 4.57844 9.48848 4.37125 9.48848" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.37125 13.661C4.16406 13.661 3.99609 13.8289 3.99609 14.0361C3.99609 14.2433 4.16406 14.4113 4.37125 14.4113C4.57844 14.4113 4.74641 14.2433 4.74641 14.0361C4.74641 13.8289 4.57844 13.661 4.37125 13.661" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span className='text-[13px] opacity-90'>{data?.lessons?.length} lessons</span>
            </div>
        </div>
    </li>
};

export default NewsletterCard;