import React from "react";
import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";

import { Pages } from "./index"
import GeneratorLayout from "../layouts/GeneratorLayout";
import AuthLayout from "../layouts/AuthLayout";
import SidebarLayout from "../layouts/SidebarLayout";
import GeneratePageLayout from "../layouts/GeneratePageLayout";
import EmailEducatorGeneratedContent from "./GeneratedContent/EmailEducator/EmailEducatorGeneratedContent";
import GoalGeneratedContent from "./GeneratedContent/GoalGeneratedContent/GoalGeneratedContent";
import PrivateRouteLayout from "../layouts/PrivateRouteLayout";

export const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/" element={<Pages.ProductDescription/>}/>
            <Route path="/pricing" element={<Pages.Pricing />} />
            <Route path="/shared-page/asset/:id" element={<Pages.AssetSharedPage/>} />
            <Route path="/shared-page/new-word/:id" element={<Pages.NewWordSharedPage/>} />

            <Route path="/generator-start" element={<Pages.GeneratorStart/>}/>

            <Route path="/generated-content-v-1" element={<Pages.GeneratedContentV1/>}/>
            
            <Route element={<AuthLayout />}>
                <Route path="/sign-in" element={<Pages.SignIn/>} />
                <Route path="/sign-in/manually" element={<Pages.SignInManually />} />
                <Route path="/sign-up" element={<Pages.SignUp/>} />
                <Route path="/forgot-password" element={<Pages.ForgotPassword/>} />
                <Route path="/reset-password" element={<Pages.ResetPassword/>} />
            </Route>

            <Route element={<GeneratePageLayout />}>
                <Route path="/generated-page/blog-old" element={<Pages.GeneratedContent />} />
            </Route>

            <Route element={<PrivateRouteLayout />}>
                <Route element={<GeneratorLayout />}>
                    <Route path="/generated-content" element={<Pages.GeneratorContainer />}/>
                </Route>
                <Route path="/generated-page/blog/:id" element={<Pages.BlogGeneratedContent/>} />

                <Route path="/generated-page/courses" element={<EmailEducatorGeneratedContent />} />
                <Route path="/generated-page/courses/:id" element={<EmailEducatorGeneratedContent />} />

                <Route path="/generated-page/goal/:id" element={<GoalGeneratedContent />} />
                <Route path="/generated-page/new-word" element={<Pages.NewWordGeneratedContent />} />
                <Route path="/generated-page/new-word/:id" element={<Pages.NewWordGeneratedContent />} />
                <Route path="/generated-page/landing/:id" element={<Pages.LandingContentGeneratedContent type="landing" />} />
                <Route path="/generated-page/entire-website/:id" element={<Pages.LandingContentGeneratedContent type="entire" />} />
                <Route path="/generated-page/general/:id" element={<Pages.GeneralGeneratedContent />} />
                <Route path="/generated-page/etsy/:id" element={<Pages.EtsyNewGeneratedContent />} />
                <Route path="/generated-page/prompt/:id" element={<Pages.PromptGeneratedContent />} />
                <Route path="/generated-page/meta-tags/:id" element={<Pages.MetaTagsGeneratedContent />} />
                <Route path="/generated-page/social-media-campaign/:id" element={<Pages.SocialGeneratedContent />} />

                <Route path="/chat-bot/:botId" element={<Pages.ChatBot />} />
                <Route path="/generated-page/product-description/:id" element={<Pages.ProductDescriptionGeneratedContent />} />
                <Route path="/generated-page/press-release/:id" element={<Pages.PressReleaseGeneretedContent />} />
                <Route path="/chat-bot/:botId/c/:chatId" element={<Pages.ChatBot />} />
                <Route path="/generated-page/essay/:id" element={<Pages.EssayGeneratedContent/>} />
                <Route element={<SidebarLayout />}>
                    <Route path="/my-assets" element={<Pages.MyAssets/>}/>
                    <Route path="/new-words" element={<Pages.NewWords/>}/>
                    <Route path="/my-lessons" element={<Pages.MyEmailNewsLetters />}/>
                    <Route path="/settings/*" element={<Pages.SettingsNew/>}/>
                    <Route path="/my-team-users" element={<Pages.MyTeamUsers/>} />
                    <Route path="/add-user" element={<Pages.AddEditUser/>} />
                    <Route path="/edit-user/:id" element={<Pages.AddEditUser edit={true} />} />
                    
                    <Route path="/my-projects" element={<Pages.MyProjects />} />
                    <Route path="/create-new-project" element={<Pages.CreateNewProject />} />


                    <Route path="/want-to-create" element={<Pages.WantToCreate />} />
                </Route>
            </Route>
       </>
    ), { basename: "/"}
)