import React, { useState } from 'react';
import api from '../../api';
import { useSelector } from 'react-redux';
import { getSettings, getToken, getUser } from '../../redux/slices/applicationSlice';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ReactComponent as VisaIcon } from '../../assets/img/icons/visa.svg';
import { ReactComponent as CardIcon } from '../../assets/img/icons/card.svg';
import { ReactComponent as MastercardIcon } from '../../assets/img/icons/mastercard.svg';
import { ReactComponent as AmexIcon } from '../../assets/img/icons/amex.svg';
import { ReactComponent as DiscoverIcon } from '../../assets/img/icons/discover.svg';
import { ReactComponent as Loader } from '../../assets/img/icons/loader.svg';
import { ReactComponent as DinersIcon } from '../../assets/img/icons/diners.svg';
import { ReactComponent as JcbIcon } from '../../assets/img/icons/jcb.svg';
import { ReactComponent as UnionpayIcon } from '../../assets/img/icons/unionpay.svg';
import { ReactComponent as PlusIcon } from '../../assets/img/icons/plus.svg';
import Input from '../../components/base/Input';
import classNames from 'classnames';
import PrimaryButton from '../../components/base/PrimaryButton';
import { toast } from 'react-toastify';

export const brandIcons = {
    unknown: CardIcon,
    visa: VisaIcon,
    mastercard: MastercardIcon,
    amex: AmexIcon,
    discover: DiscoverIcon,
    diners: DinersIcon,
    jcb: JcbIcon,
    unionpay: UnionpayIcon
}

const isValidZipCode = (zipCode) => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode);

const AddCardModal = ({ close, cards,setCards }) => {
    const token = useSelector(getToken);
    const user = useSelector(getUser);

    const elements = useElements();
    const stripe = useStripe();

    const [brand, setBrand] = useState('unknown');
    const CurrentIcon = brandIcons[brand];
    const [cardData,setCardData] = useState({
        name: '',
        zipCode: '',
        setDefault: true
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleAddCard = async () => {
        if(!isValidZipCode(cardData.zipCode)) {
            toast.error('Zip Code must be a 5 digit number.');
            return;
        }
        try {
            const toastId = toast.loading("Adding card...");
            const intent = await api.payments.createIntent({token});
            const stripeData = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement('cardNumber'),
                billing_details: {
                    name: cardData.name,
                    email: user.email,
                    address: {
                        postal_code: cardData.zipCode
                    }
                },
            });
            if(stripeData.error) {
                toast.update(toastId, {
                    render: stripeData.error.message, 
                    type: 'error', 
                    isLoading: false,
                    autoClose: 5000
                });
                return;
            };
            const dataConfirm = await stripe.confirmCardSetup(intent.client_secret, {
                payment_method: stripeData.paymentMethod.id
            });
            if (dataConfirm.error) {
                toast.update(toastId, {
                    render: dataConfirm.error.message, 
                    type: 'error', 
                    isLoading: false,
                    autoClose: 5000
                });
                return
            };
            const response = await api.paymentCards.addCard({
                data: {
                    payment_method: dataConfirm.setupIntent.payment_method,
                    intentId: intent,
                    isDefault: cardData.setDefault
                },
                token
            });
            console.log(response);
            toast.update(toastId, {
                render: response.success ? 'Card added successfully!' : response.message, 
                type: response.success ? 'success' : 'error', 
                isLoading: false,
                autoClose: 5000
            });
            if(!response.success) return;
            close();
            api.paymentCards.getCards({token}).then(response => {
                if(!response.success) return;
                setCards(response.data);
            })
        } catch (e) {
            console.log(e);
        }
    }
    return <div className='modal-content relative p-[36px]'>
        <button 
            className="z-10 absolute top-[16px] right-[16px] w-[34px] h-[34px] flex items-center justify-center
            bg-[#1B1B1B] rounded-[8px] border border-solid border-[#333]"
            onClick={close}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g opacity="0.5">
                    <path d="M15 5L5 15" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M5 5L15 15" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
            </svg>
        </button>
        <div className='flex flex-col gap-[36px]'>
            <div>
                <h2 className='modal-body-title' >Add Card</h2>
                <span className='modal-body-subtitle' >Please fill in form below to add new card</span>
            </div>
            <div className='flex flex-col gap-[16px]'>
                <div className='flex-1 flex flex-col gap-[8px]'>
                    <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>Name on Card</p>
                    <Input
                        placeholder='John Smith'
                        value={cardData.name}
                        onChange={(e) => setCardData({...cardData, name: e.target.value})}
                    />
                </div>
                <div className='flex-1 flex flex-col gap-[8px]'>
                    <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>Card Number</p>
                    <div className='py-[8px] pl-[12px] pr-[8px] bg-[#1B1B1B] rounded-[8px] h-[40px]
                    flex items-center gap-[12px] border border-solid border-[#333]'>
                        <CurrentIcon className='w-[28px] h-[20px]'/>
                        <CardNumberElement
                            className='block flex-1' 
                            options={{
                                style: {
                                    base: {
                                        color: '#ffffffe6',
                                        fontSize: '14px',
                                        backgroundColor: '#1B1B1B',
                                    },
                                },
                            }}               
                            onChange={(e) => {
                                if(!e.brand) return;
                                console.log(e.brand);
                                if(!brandIcons[e.brand]) {
                                    setBrand('unknown')
                                } else {
                                    setBrand(e.brand);
                                }
                            }}
                        />
                    </div>
                </div>
                <div className='flex gap-[16px]'>
                    <div className='flex-1 flex flex-col gap-[8px]'>
                        <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>Expiry Date</p>
                        <div className='py-[8px] pl-[14px] pr-[8px] bg-[#1B1B1B] rounded-[8px] h-[40px]
                        flex items-center border border-solid border-[#333]'>
                            <CardExpiryElement
                                className='block flex-1'
                                options={{
                                    style: {
                                        base: {
                                            color: '#ffffffe6',
                                            fontSize: '14px',
                                            backgroundColor: '#1B1B1B',
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                    <div className='flex-1 flex flex-col gap-[8px]'>
                        <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>CVV</p>
                        <div className='py-[8px] pl-[14px] pr-[8px] bg-[#1B1B1B] rounded-[8px] h-[40px]
                        flex items-center border border-solid border-[#333]'>
                            <CardCvcElement
                                className='block flex-1'
                                options={{
                                    style: {
                                        base: {
                                            color: '#ffffffe6',
                                            fontSize: '14px',
                                            backgroundColor: '#1B1B1B',
                                        },
                                    },
                                    placeholder: '123'
                                }}

                            />
                        </div>
                    </div>
                    <div className='flex-1 flex flex-col gap-[8px]'>
                        <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>Zip Code</p>
                        <Input
                            placeholder='12345'
                            className='w-full'
                            inputMode="numeric"
                            type='text'
                            maxLength="10"
                            value={cardData.zipCode}
                            onChange={(e) => setCardData({...cardData, zipCode: e.target.value})}
                        />
                    </div>
                </div>
                <label className="custom-checkbox">
                    <input 
                        type="checkbox"
                        className="hidden"
                        checked={cardData.setDefault}
                        onChange={(e) => setCardData({...cardData, setDefault: e.target.checked})}
                        disabled={cards.length === 0}
                    />
                    <span className="custom-checkbox-fake"></span>
                    <span className="custom-checkbox-text text-[#FFFFFF]">Set as Default</span>
                </label>
            </div>
             
            <PrimaryButton
                onClick={handleAddCard}
                disabled={!cardData.name || !cardData.zipCode}
                className='w-fit ml-auto stroke-plus'
            >
                <PlusIcon className='icon-plus-card'/>
                <span>Add Card</span>
            </PrimaryButton>
        </div>
    </div>
}

export default AddCardModal;