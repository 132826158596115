import React, { Fragment, useContext, useEffect, useState } from 'react';
import { format } from 'date-fns';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import { ReactComponent as DragIcon } from './icons/drag.svg';
import { ReactComponent as AwesomeIcon } from './icons/awesome.svg';
import { ReactComponent as CrossIcon } from './icons/cross.svg';
import { ReactComponent as RefreshIcon } from './icons/refresh.svg';
import { ReactComponent as ArrowIcon } from './icons/arrow.svg';
import { defaultButtons, editButtons, labelSuggestions } from './consts';
import InputSuggestion from '../../GeneratorV4/InputSuggestion';
import TextareaGenerator from '../../GeneratorV4/TextareaGenerator';
import { GenerateFormContext } from '../../GeneratorV4/GeneratorContextComponent';
import CardLoader from './CardLoader';
import DatePicker from '../../../components/base/DatePicker';
import Tippy from '@tippyjs/react';
import GoalInputSuggestion from '../../GeneratorV4/GoalInputSuggestion';

import { useDispatch, useSelector } from 'react-redux';
import { getSocket, getToken } from '../../../redux/slices/applicationSlice';
import PrimaryButton from '../../../components/base/PrimaryButton';

import GeneratedContentModal from '../GeneratedContentModal';
import api from '../../../api';
import { useQuill } from 'react-quilljs';
import EditorToolbar from '../Blog/EditorToolbar';
import { setCourseLessonAddon, setCourseLessonContent, setCourseLessonMaterial, setCourseMaterial } from '../../../redux/slices/courseGeneratorSlice';
import DefaultToolbar from './DefaultToolbar';
import EditorControl from './EditorControl';
import { db } from '../../../db';
import { useLiveQuery } from 'dexie-react-hooks';
import SelectBox from '../../../components/base/SelectBox';

import { toast } from 'react-toastify';

const LessonCard = ({ lesson, index, courseIndex, provided, cardFullScreen, setCardFullScreen }) => {
    console.log('LessonCard lesson:', lesson);
    const { id } = useParams();
    const token = useSelector(getToken);
    const socket = useSelector(getSocket);

    const [isOpen, setIsOpen] = useState(false);
    const [activeBtn, setActiveBtn] = useState(null);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        deadline: '',
    });

    const [customPrompt, setCustomPrompt] = useState('');

    const [labelInput, setLabelInput] = useState('');
    const [subtaskInput, setSubtaskInput] = useState('');
    const [labelInputState, setLabelInputState] = useState('input');
    const [draftLabels, setDraftLabels] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [suggestedSubtaskTools, setSuggestedSubtaskTools] = useState([]);
    const [selectedSuggestedSubtaskTools, setSelectedSuggestedSubtaskTools] = useState(null);

    const [openedModal, setOpenedModal] = useState(null);

    const courseFromIndexedDb = useLiveQuery(
        () =>  db?.courses?.get(Number(id)), [id]
    );
    console.log("courseFromIndexedDb:", courseFromIndexedDb)

    const editLesson = ({operation, customPayload}) => {
        socket.send({
            event: 'lesson-kit-edit',
            payload: {
                operation,
                content: lesson.content,
                ...customPayload
            },
            handler: (response) => {
                if(response?.isError){
                    toast.error("AI bot is not available at the moment");
                    return;
                }

                console.log(response);
                dispatch(setCourseLessonContent({
                    courseIndex,
                    lessonIndex: index,
                    data: response.data
                }))
                if(response.done) {
                    db.courses.update(Number(id), {
                        [`lessons.${index}.content`]: response.data
                    })
                }
            }
        });
    }

    const updateIndexedDB = async () => {
        await db.courses.put({
            id: 4,
            test: 'test'
        })
    }
    const dispatch = useDispatch();


    const customPromptSubmit = () => {
        // setIsLoading(true);

        editLesson({
            operation: 'custom',
            customPayload: {
                data: customPrompt
            }
        })
        
        setActiveBtn(null);
        setCustomPrompt('');
    }

    const saveChanges = () => {
        // dispatch(updateGoalTask({
        //     index,
        //     data: {
        //         task_title: formData.title,
        //         task_description: formData.description,
        //         deadline: String(formData.deadline)
        //     }
        // }))
    }
    const { quill, quillRef } = useQuill({
        theme: 'snow',
        modules: {
        toolbar: '#toolbar' + index,
          history: {
            delay: 2000,
            userOnly: true
          },
          imageUploader: {
            upload: (file) => {
                return new Promise(async (resolve, reject) => {
                    try {
                        const { data: { attachments }, success } = await api.assets.changeAsset({
                            id,
                            data: {
                                attachments: file
                            },
                            token
                        })
                        if(success) {
                            resolve(process.env.REACT_APP_API_URL + attachments[attachments.length - 1].path);
                        }
                        reject("Upload failed");
                    } catch (e) {
                        reject("Upload failed");
                    }
                });
              },
          }
        },
        formats: [
            'bold', 'italic', 'underline', 'align', 'link', 'image', 'video',
            'color', 'background', 'header', 'font', 'size',
            'clean', 'imageBlot', 'list', 
        ],
    });

    const addMaterial = () => {
        // console.log('Length', lesson.materials.length)

        socket.send({
            event: 'lesson-kit-addons',
            payload: {
                title: lesson.title,
                content: lesson.content,
                addons: [{ title: addonType, key: addonType.replace(/ /g, '_').toLowerCase() }],
            },
            handler: (response) => {
                if(response?.isError){
                    toast.error("AI bot is not available at the moment");
                    return;
                }

                console.log('lesson-kit-addons response:', response);
                if(courseFromIndexedDb){
                    dispatch(setCourseLessonMaterial({
                        courseIndex,
                        lessonIndex: index,
                        materialIndex: lesson.materials.length,
                        data: {
                            type: 'addon',
                            title: addonType,
                            content: response.data[addonType.replace(/ /g, '_').toLowerCase()]
                        }
                    }))
                }

                // dispatch(setCourseLessonAddon({
                //     courseIndex,
                //     lessonIndex: index,
                //     data: response.data
                // }))


                
                if(response.done) {
                    api.courses.uploadAddon({
                        data: {
                            type: 'addon',
                            title: addonType,
                            content: response.data[addonType.replace(/ /g, '_').toLowerCase()]
                        },
                        token
                    }).then(resp => {
                        console.log('ADDON RESPONSE', resp)
                        if(courseFromIndexedDb){
                            db.courses.update(Number(id), {
                                [`lessons.${index}.materials.${lesson.materials.length}`]: {
                                    id: resp.data.id,
                                    content: resp.data.content,
                                    type: resp.data.type,
                                    title: resp.data.title,
                                }
                            })
                        } else {
                            dispatch(setCourseLessonMaterial({
                                courseIndex,
                                lessonIndex: index,
                                materialIndex: lesson.materials.length,
                                data: {
                                    ...resp.data,
                                    type: 'addon',
                                    title: addonType,
                                    content: response.data[addonType.replace(/ /g, '_').toLowerCase()]
                                }
                            }))
                            dispatch(setCourseMaterial({
                                courseIndex,
                                lessonIndex: index,
                                materialIndex: lesson.materials.length,
                                data: {
                                    ...resp.data,
                                    type: 'addon',
                                    content: response.data[addonType.replace(/ /g, '_').toLowerCase()]
                                }
                            }))
                        }
                    });
                }
            }
        });
    }

    useEffect(() => {
        if (!quill || !lesson.content) return;
        quill.disable();
        quill.clipboard.dangerouslyPasteHTML(lesson.content, 'api');
    }, [quill, lesson.content]);
    const [editorActiveButton, setEditorActiveButton] = useState(null);
    const [imagePopupOpened, setImagePopupOpened] = useState(false);
    const [imageLinkOpened, setImageLinkOpened] = useState(false);
    useEffect(() => {
        if (!quill) return;
        if(activeBtn === 'edit') {
            quill.enable();
        } else {
            quill.disable();
        }
    }, [quill, activeBtn]);
    const [footerActions, setFooterActions] = useState({
        keyword: false,
        customPrompt: false
    });
    const [addonType, setAddonType] = useState('')
    
    return <li className={`goal-generator-card relative ${cardFullScreen === index ? 'h-full' : ''}`} ref={provided.innerRef} {...provided.draggableProps}>
        <div className={`goal-generator-card-header ${isOpen ? 'cursor-default' : 'cursor-pointer'}`} onClick={() => setIsOpen(true)}>
            <div className="gen-con-header-title gap-[8px]">
                <div {...provided.dragHandleProps} className={`p-[2px] card-drag-btn`}>
                    <DragIcon />
                </div>
                <div className='w-[16px] text-center text-[14px] text-[#A7A7A7]'>{index + 1}</div>
                <div className={`gen-header-arrow ${isOpen && 'rotate-180'}`} onClick={(e) => {e.stopPropagation(); setIsOpen(!isOpen)}}>
                    <ArrowIcon />
                </div>

                <span className="line-clamp-1" >{lesson.title}</span>
            </div>
            <div className="gen-con-header-date line-clamp-1">
                Generated {format(new Date(lesson.generated), 'dd.MM.yyyy, H:mm')}
            </div>
        </div>
        <div className='accordion-content' aria-hidden={!isOpen}>
        <div className='overflow-hidden'>
            <div 
                id={'toolbar'+ index}
                className={classNames("flex justify-between py-[6px] px-[8px] rounded-full bg-[#1B1B1B]")}
                style={{
                    display: activeBtn === 'edit' ? 'flex' : 'none'
                }}
            >
                <EditorToolbar
                    isEdit={activeBtn === 'edit'}
                    quill={quill}
                    activeButton={editorActiveButton}
                    setActiveButton={setEditorActiveButton}
                    setImagePopupOpened={setImagePopupOpened}
                    setImageLinkOpened={setImageLinkOpened}
                    isFullScreen={cardFullScreen}
                    setCardFullScreen={setCardFullScreen}
                    editBlog={() => {}}
                    imageButtonRef={null}
                />
            </div>
            <div 
                className="flex justify-between py-[6px] px-[8px] rounded-full bg-[#1B1B1B]"
                style={{
                    display: activeBtn === 'edit' ? 'none' : 'flex'
                }}
            >
                <DefaultToolbar
                    disabled={false}
                    footerActions={footerActions}
                    setFooterActions={setFooterActions}
                    isFullScreen={cardFullScreen}
                    setCardFullScreen={setCardFullScreen}
                    editBlog={editLesson}
                    activeButton={activeBtn}
                    setActiveButton={setActiveBtn}
                    courseIndex={courseIndex}
                    index={index}
                />
            </div>
            {isLoading ? <CardLoader /> : <>
                <div className='max-w-[850px] px-[16px] mx-auto my-[24px] flex flex-col gap-[18px]
                '>
                    <pre 
                        ref={quillRef}
                        className='max-h-[360px] no-scroll'
                        // style={{display: isEdit ? 'block': 'none'}}
                    />
                </div>
                {activeBtn === 'edit' ? <EditorControl
                    activeButton={activeBtn}
                    setActiveButton={setActiveBtn}
                    quill={quill}
                    imageLinkOpened={imageLinkOpened}
                    setImageLinkOpened={setImageLinkOpened}
                    closeEditor={() => setActiveBtn(null)}
                    content={lesson.content}
                    courseIndex={courseIndex}
                    index={index}
                /> : activeBtn === 'customPrompt' ? <div className='flex mb-[16px] gap-[10px]'>
                    <input
                        className='goal-generator-input'
                        value={customPrompt}
                        onChange={(e) => setCustomPrompt(e.target.value)}
                        placeholder="Ex. Rewrite the introductory paragraph to create a stronger hook and engage the reader's interest from the start..."
                        style={{ height: "32px", padding: "8px 8px 8px 14px", fontSize: "13px" }}
                    />
                    <button 
                        className='goal-generator-action btn--primary-gradient btn min-w-[65px]'
                        style={{ height: "32px", padding: "6px", fontSize: "13px" }}
                        onClick={() => {
                            customPromptSubmit();
                        }}
                    >
                        Apply
                    </button>
                </div> : <div className='mb-[20px]'>
                    <div className='flex items-center gap-[12px]'>
                        <span>Materials</span>
                        <button 
                            className='w-[26px] h-[26px] flex items-center justify-center rounded-full 
                            border border-solid border-[#383838] bg-[#1B1B1B]'
                            onClick={() => setActiveBtn('addLabel')}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M7.99578 12.9163C7.79078 12.9163 7.61502 12.8462 7.4685 12.7059C7.32196 12.5656 7.2487 12.3802 7.2487 12.1497V8.76634H3.83203C3.62786 8.76634 3.45182 8.69101 3.3039 8.54034C3.15599 8.38969 3.08203 8.21024 3.08203 8.00201C3.08203 7.77823 3.15599 7.5969 3.3039 7.45801C3.45182 7.31912 3.62786 7.24967 3.83203 7.24967H7.2487V3.83301C7.2487 3.61329 7.32291 3.43335 7.47133 3.29321C7.61975 3.15307 7.79642 3.08301 8.00133 3.08301C8.20624 3.08301 8.38481 3.15307 8.53703 3.29321C8.68925 3.43335 8.76536 3.61329 8.76536 3.83301V7.24967H12.1654C12.3743 7.24967 12.5543 7.32009 12.7054 7.46092C12.8565 7.60177 12.932 7.7813 12.932 7.99952C12.932 8.21776 12.8565 8.40012 12.7054 8.54661C12.5543 8.6931 12.3743 8.76634 12.1654 8.76634H8.76536V12.1497C8.76536 12.3802 8.68953 12.5656 8.53786 12.7059C8.38619 12.8462 8.20549 12.9163 7.99578 12.9163Z" fill="#A7A7A7"/>
                            </svg>
                        </button>
                    </div>
                    <ul className='flex flex-col gap-[10px]'>
                        {lesson?.materials?.map((item, matherialIndex) => 
                            <li key={matherialIndex} className=''>
                                <div className='flex items-center gap-[6px]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <rect x="4" y="6" width="10.6667" height="8" rx="2" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M12.2414 6.00001L11.7304 3.10201C11.6026 2.37683 10.911 1.89261 10.1858 2.02048L2.43404 3.38734C1.70886 3.51522 1.22465 4.20675 1.35251 4.93194L2.2619 10.0892C2.32329 10.4375 2.52052 10.7471 2.81019 10.9499C3.09986 11.1527 3.45824 11.2322 3.80649 11.1707L3.99871 11.1369" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    {
                                        item?.content || item?.title ? <span className='text-[12px] text-[#A7A7A7]'>
                                            {item?.type === "file" ? "File" : item.title}
                                        </span> : <span className='text-[12px] text-[#A7A7A7]'>
                                            { courseFromIndexedDb ? courseFromIndexedDb.materials_files.find(itemMaterial => +itemMaterial.data.id === +item)?.data?.title : '-'}
                                        </span>
                                    }
                                    
                                </div>
                                <div className="flex">
                                    {
                                        item?.type === "file" ? <a href={process.env.REACT_APP_API_URL+item.content} download={item.content} target='_blank' className='text-[14px] text-orange-primary underline line-clamp-1'>{item.title}</a> :
                                        <span className='text-[14px] text-orange-primary underline line-clamp-1'>
                                            {item.content}
                                        </span>
                                    }
                                </div>
                            </li>
                        )}
                    </ul>
                </div>}
                {activeBtn === 'addLabel' && <div className='flex items-center mb-[16px] gap-[10px]'>
                    {labelInputState === 'input' && <>
                        <SelectBox
                            className='input-custom__wrap-input gen4-settings-select w-[200px]'
                            propsOptions={[
                                {value: '', label: 'Select add-on type', placeholder: true},
                                {value: 'Lesson Plan', label: 'Lesson Plan'},
                                {value: 'Warm up', label: 'Warm up'},
                                {value: 'Guided Questions', label: 'Guided Questions'},
                                {value: 'Home Work', label: 'Home Work'},
                                {value: 'Learning Materials', label: 'Learning Materials'},
                            ]}
                            value={addonType}
                            change={(topic) => setAddonType(topic)}  
                        />
                        <input
                            className='goal-generator-input'
                            value={labelInput}
                            onChange={(e) => setLabelInput(e.target.value)}
                            placeholder="Write name for add-on that you will add..."
                        />
                        <button 
                            className='goal-generator-action btn--primary-gradient btn'
                            onClick={() => {
                                addMaterial();
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M10 4.16699V15.8337" stroke="#331A04" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M4.16797 10H15.8346" stroke="#331A04" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            Add Material
                        </button>
                    </>}
                    {labelInputState === 'sugg' && <>
                        <ul className='flex flex-wrap gap-[8px] flex-1 items-center'>
                            <AwesomeIcon className='awesome-sugg'/>
                            {labelSuggestions.map((item, index) => <li key={index}>
                                <button 
                                    className={`flex items-center gap-[6px] py-[5px] pl-[8px] pr-[5px] bg-[#202020] rounded-[24px] border border-solid box-border
                                    `}
                                    onClick={() => {
                                        setDraftLabels(labels => {
                                            if(labels.includes(item)) {
                                                return labels.filter(label => label !== item);
                                            } else {
                                                return [...labels, item];
                                            }
                                        })
                                    }}
                                >
                                    <span className={`text-[12px] ${draftLabels.includes(item) ? 'text-orange-primary': 'text-[#ffffff80]'}`}>
                                        {item}
                                    </span>
                                    {draftLabels.includes(item) ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <g clip-path="url(#clip0_1618_15778)">
                                            <path d="M6.98242 9.11668L5.66576 7.78334C5.52353 7.65001 5.34847 7.58334 5.14057 7.58334C4.93267 7.58334 4.75218 7.65279 4.59909 7.79168C4.45464 7.94168 4.38242 8.12223 4.38242 8.33334C4.38242 8.54445 4.4602 8.71112 4.61576 8.83334L6.44909 10.6833C6.58546 10.8389 6.76121 10.9167 6.97636 10.9167C7.19151 10.9167 7.37687 10.8389 7.53242 10.6833L11.3158 6.90001C11.4713 6.75779 11.5491 6.5855 11.5491 6.38316C11.5491 6.18081 11.4722 5.99754 11.3184 5.83334C11.1647 5.70001 10.9807 5.63612 10.7666 5.64167C10.5525 5.64723 10.3744 5.71668 10.2324 5.85001L6.98242 9.11668ZM8.00139 15.0167C7.03719 15.0167 6.12549 14.8355 5.26631 14.4733C4.40711 14.111 3.65946 13.6118 3.02336 12.9757C2.38726 12.3396 1.88808 11.5928 1.52582 10.7351C1.16356 9.87744 0.982422 8.96651 0.982422 8.00231C0.982422 7.03526 1.16388 6.12367 1.52679 5.26753C1.88971 4.41138 2.38981 3.66419 3.02707 3.02596C3.66435 2.38771 4.41076 1.88494 5.26631 1.51762C6.12186 1.15032 7.03156 0.966675 7.99541 0.966675C8.96326 0.966675 9.87613 1.1498 10.734 1.51606C11.5919 1.88231 12.3394 2.38374 12.9767 3.02034C13.6139 3.65694 14.1158 4.40389 14.4825 5.26117C14.8491 6.11847 15.0324 7.03188 15.0324 8.00139C15.0324 8.9702 14.8488 9.88133 14.4815 10.7348C14.1142 11.5882 13.6114 12.3338 12.9731 12.9714C12.3349 13.6091 11.5885 14.1094 10.7339 14.4723C9.87926 14.8352 8.96843 15.0167 8.00139 15.0167Z" fill="#FF8114"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1618_15778">
                                                <rect width="16" height="16" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <g opacity="0.5">
                                            <path d="M8 3.33325V12.6666" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M3.33203 8H12.6654" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                                        </g>
                                    </svg>}
                                </button>
                            </li>)}
                        </ul>
                        <button 
                            className='goal-generator-action btn--primary-gradient btn'
                            onClick={() => {
                                // setGenerateForm(prev => {
                                //     const result = [...prev.goalMilestones];
                                //     result[index] = {
                                //         ...result[index],
                                //         labels: [...result[index].labels, ...draftLabels]
                                //     }
                                //     return {
                                //         ...prev,
                                //         goalMilestones: result
                                //     };
                                // });
                                setLabelInputState('input');
                                setDraftLabels([]);
                                setLabelInput('');
                            }}
                        >
                            Submit
                        </button>
                        <button
                            className='goal-generator-button-cross'
                            onClick={() => {
                                setLabelInputState('input');
                                setDraftLabels([]);
                                setLabelInput('');
                            }}
                        >
                            <RefreshIcon />
                        </button>
                    </>}
                    <button
                        className='goal-generator-button-cross'
                        onClick={() => {
                            setActiveBtn(null);
                            setLabelInputState('input');
                            setDraftLabels([]);
                            setLabelInput('');
                        }}
                    >
                        <CrossIcon />
                    </button>
                </div>}

            </>}
        </div>
        </div>
        {isOpen && activeBtn === 'deadline' && <div className='goal-generator-popup'>
            <p className='text-[18px] font-medium opacity-90 leading-[24px]'>Change Deadline</p>
            <p className='text-[14px] text-[#A7A7A7] opacity-60 mb-[24px] leading-[22px]'>Set date to change deadline</p>
            <DatePicker
                selected={formData.deadline}
                onChange={(deadline) => setFormData(prev => ({...prev, deadline}))}
                dateFormat="dd.MM.yyy"
                className='w-full'
                iconType='calendar'
            />
            <div className='flex gap-[12px] mt-[24px]'>
                <PrimaryButton
                    className='flex-1 goal-deadline-cancel'
                    onClick={() => setActiveBtn(null)}
                >
                    Cancel
                </PrimaryButton>
                <PrimaryButton
                    className='flex-1'
                    onClick={() => {
                        saveChanges();
                        setActiveBtn(null);
                    }}
                >
                    Submit
                </PrimaryButton>
            </div>
        </div>}

        { openedModal && <GeneratedContentModal isOpened close={() => setOpenedModal(null)} >
            { openedModal?.name === 'confirm-delete' && <div className="modal__body w-[450px] modal-body-flex">
                <div className="text-center">
                    <h3 className="text-[24px] font-[500]" style={{ lineHeight: '1.5' }}>
                        { openedModal?.title ? openedModal.title : 'Do you really want to delete?' }
                    </h3>
                    {/* <p className="mt-[20px] text-[14px] text-[#A7A7A7] leading-[22px]">
                        Your plan “Publish a Novel” has been successfully saved
                    </p> */}
                </div>
                <div className="d-flex items-center gap-[10px]">
                    <button
                        className="btn bg-[#202020] border border-solid box-border rounded-full py-[10px] px-[36px]"
                        onClick={() => { setOpenedModal(null); }}
                    >
                        <span className="text-[14px] font-medium">Close</span>
                    </button>
                    <button
                        className="btn btn--primary-gradient rounded-full py-[10px] px-[36px]"
                        onClick={() => {
                            openedModal?.action && openedModal?.action();
                        }}
                    >
                        <span className="text-[14px] font-medium">Confirm</span>
                    </button>
                </div>
            </div> }
        </GeneratedContentModal> }
    </li>;
};

export default LessonCard;