import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '../../api';

export const getUserThunk = createAsyncThunk(
    '/get-user/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.users.getUser({token: payload.token});
            if(res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchKeepPositiveThunk = createAsyncThunk(
    '/api/keep-positive',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.settings.getKeepPositive({ token: payload.token });
            if(res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);