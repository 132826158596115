import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getBlogActiveVersion, setBlogActiveContent, setBlogContent } from '../../../redux/slices/blogGeneratorSlice';
import { useSelector } from 'react-redux';
import { setEssayVersion } from '../../../redux/slices/essayGeneratorSlice';

const EditorControl = ({ 
    activeButton, setActiveButton, quill, imageLinkOpened, setImageLinkOpened, closeEditor, setUpdateBlogs,
    markup, activeVersionIndex
}) => {
    const dispatch = useDispatch();
    const [linkInput, setLinkInput] = useState('');
    const [imageLinkInput, setImageLinkInput] = useState('');
    const [wordsCount, setWordsCount] = useState();
    useEffect(() => {
        if (!quill) return;
        setWordsCount(quill.getText().trim().split(/\s+/).filter(word => word.length > 0).length);
        quill.on('text-change', (delta, oldDelta, source) => {
            setWordsCount(quill.getText().trim().split(/\s+/).filter(word => word.length > 0).length);
        });
    }, [quill]);
    return activeButton === 'link' ? <div className="flex items-center w-full gap-[10px]">
        <input
            className="bg-[#1B1B1B] border border-solid border-[#333] h-[32px] pl-[12px] pr-[8px]
            text-[13px] rounded-[8px] flex-1"
            placeholder="Paste your URL here..."
            value={linkInput}
            onChange={(e) => setLinkInput(e.target.value)}
        />
        <button 
            className='goal-generator-action btn--primary-gradient btn'
            style={{
                height: 32,
                padding: '0 12px',
                fontSize: 13
            }}
            onClick={() => {
                // const range = quill.getSelection();
                // console.log(range);
                // const format = quill.formatText(range.index, range.length, 'link', 'https://google.com');
                quill.format('link', linkInput);
                setActiveButton(null);
            }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M8.00033 5.33301V10.6663" stroke="#331A04" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M10.6663 8.00033H5.33301" stroke="#331A04" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M8 14V14C4.686 14 2 11.314 2 8V8C2 4.686 4.686 2 8 2V2C11.314 2 14 4.686 14 8V8C14 11.314 11.314 14 8 14Z" stroke="#331A04" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            Add Link
        </button>
    </div> : imageLinkOpened ? <div className="flex items-center w-full gap-[10px]">
        <input
            className="bg-[#1B1B1B] border border-solid border-[#333] h-[32px] pl-[12px] pr-[8px]
            text-[13px] rounded-[8px] flex-1"
            placeholder="Paste your image URL here..."
            value={imageLinkInput}
            onChange={(e) => setImageLinkInput(e.target.value)}
        />
        <button 
            className='goal-generator-action btn--primary-gradient btn'
            style={{
                height: 32,
                padding: '0 12px',
                fontSize: 13
            }}
            onClick={() => {
                setImageLinkOpened(false);
                setActiveButton(null);
                quill.insertEmbed(quill.getSelection(true).index, 'image', imageLinkInput);
            }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M8.00033 5.33301V10.6663" stroke="#331A04" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M10.6663 8.00033H5.33301" stroke="#331A04" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M8 14V14C4.686 14 2 11.314 2 8V8C2 4.686 4.686 2 8 2V2C11.314 2 14 4.686 14 8V8C14 11.314 11.314 14 8 14Z" stroke="#331A04" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            Add Image
        </button>
    </div> : <div className="flex items-center justify-between w-full">
        <span className="text-[13px] opacity-30">{wordsCount} words</span>
        <div className="flex gap-[8px]">
            <button 
                className='h-[32px] px-[12px] flex items-center bg-[#1B1B1B] border border-solid border-[#333]
                rounded-full text-[13px] text-[#A7A7A7] gap-[8px] opacity-90'
                onClick={() => { 
                    quill.clipboard.dangerouslyPasteHTML(markup, 'api');
                    closeEditor();
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M4.44434 4.44434L11.5554 11.5554" stroke="#A7A7A7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M11.5554 4.44434L4.44434 11.5554" stroke="#A7A7A7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                Discard
            </button>
            <button 
                className='goal-generator-action btn--primary-gradient btn'
                style={{
                    height: 32,
                    padding: '0 12px',
                    fontSize: 13
                }}
                onClick={() => {
                    dispatch(setEssayVersion({
                        index: activeVersionIndex,
                        data: {
                            content: quill.root.innerHTML
                        }
                    }));
                    closeEditor();
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M13.3337 4.33301L6.00033 11.6663L2.66699 8.33301" stroke="#331A04" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                Apply
            </button>
        </div>
    </div>};

export default EditorControl;