import React, { useEffect, useState } from 'react';
import IconCheckCircle from "../../assets/img/icons/icon-check-circle.svg";
import Header from '../../components/parts/Header';
import api from '../../api';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getToken } from '../../redux/slices/applicationSlice';
import { useStripe } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { getUserThunk } from '../../redux/thunks/applicationThunks';

const cards = [
    {
        popular: false,
        name: "Starter",
        subtitle: "Perfect for an individual or a small team starting to get bigger",
        price: "49.99",
        list: [
            `<span class="font-[700]">30,000</span> credits per month`,
            "Access to all AI models (Copy, Art, Video, Music, Marketing)",
            "Ideal for startups and small businesses",
            "One Page/One Stop Entire Website Content Creation Tool",
            "Share to popular platforms (e.g., Etsy, Amazon, Walmart, WordPress, etc.)",
            "One Page Content Creation with Metatags",
            "Unlimited access to tutorials and resources",
            "1 User",
        ]
    },
    {
        popular: true,
        name: "Pro",
        subtitle: "Perfect for growing teams wanting to be in more control",
        price: "99.95",
        list: [
            `<span class="font-[700]">100,000</span> credits per month`,
            "Access to all AI models (Copy, Art, Video, Music, Marketing)",
            "Ideal for startups and small businesses",
            "One Page/One Stop Entire Website Content Creation Tool",
            "Share to popular platforms (e.g., Etsy, Amazon, Walmart, WordPress, etc.)",
            "One Page Content Creation with Metatags",
            "Unlimited access to tutorials and resources",
            "1 User",
        ]
    },
    {
        popular: false,
        name: "Premium",
        subtitle: "Perfect for an individual or a small team starting to get bigger",
        price: "199.99",
        list: [
            `<span class="font-[700]">300,000</span> credits per month`,
            "Access to all AI models (Copy, Art, Video, Music, Marketing)",
            "Ideal for startups and small businesses",
            "One Page/One Stop Entire Website Content Creation Tool",
            "Share to popular platforms (e.g., Etsy, Amazon, Walmart, WordPress, etc.)",
            "One Page Content Creation with Metatags",
            "Unlimited access to tutorials and resources",
            "3 User",
        ]
    },
    {
        popular: false,
        name: "Power",
        subtitle: "Perfect for companies wanting advanced tools and support",
        price: "299.99",
        list: [
            `<span class="font-[700]">500,000</span> credits per month`,
            "Access to all AI models (Copy, Art, Video, Music, Marketing)",
            "Ideal for startups and small businesses",
            "One Page/One Stop Entire Website Content Creation Tool",
            "Share to popular platforms (e.g., Etsy, Amazon, Walmart, WordPress, etc.)",
            "One Page Content Creation with Metatags",
            "Unlimited access to tutorials and resources",
            "5 User",
        ]
    },
]

const Pricing = () => {
    const token = useSelector(getToken);
    const stripe = useStripe();
    console.log(stripe);
    const navigate = useNavigate();
    const [pricing, setPricing] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        api.pricingPlans.getPricing()
            .then(response => setPricing(response.data));
    }, [])
    const handleSubscribePlan = async (planId) => {
        const toastId = toast.loading("Subscribing...");
        try {
            if(!token) {
                navigate('/sign-in', {
                    state: {
                        sourse: '/pricing'
                    }
                });
                return;
            }
            const data = await api.payments.subscribePlan({
                planId,
                token
            });
            console.log(data);
            if(!data.success) {
                toast.update(toastId, {
                    render: data.message,
                    type: 'error', 
                    isLoading: false,
                    autoClose: 5000
                });
                return;
            }
            if(!data.client_secret) {
                toast.update(toastId, {
                    render: data.message,
                    type: 'success', 
                    isLoading: false,
                    autoClose: 5000
                });
                dispatch(getUserThunk({token}));
                return;
            }
            const dataConfirm = await stripe.confirmCardPayment(data.client_secret);
            console.log('dataConfirm', dataConfirm);
            const resolve = await api.payments.resolveSubscribe({token});
            toast.update(toastId, {
                render: resolve.message,
                type: 'success', 
                isLoading: false,
                autoClose: 5000
            });
            dispatch(getUserThunk({token}));
        } catch(e) {
            toast.update(toastId, {
                render: e.response.data.message || 'Error',
                type: 'error', 
                isLoading: false,
                autoClose: 5000
            });
        }
    }
    return <div>
        <Header />
        <section className="mt-[62px]">
            <div className="container">
                <div className="pricing__header-group mb-[60px]">
                    <span className="block text-[18px] font-[600] leading-[54px] mb-[8px]">PRICING</span>
                    <h2 className="text-[48px] font-[800] mb-[8px]">Take control of your productivity</h2>
                    <p className="text-[20px] leading-[32px]">Start small and free, upgrade as you go.<br />Take control of everything.</p>
                </div>
                <div className="row gx-30">
                    {pricing.map(item => 
                        <div 
                            className="col-lg-6 col-xxl-3 mb-[30px]" 
                            key={item.id}
                        >
                            <div className={classNames('pricing__card', {
                                'popular': item.is_popular
                            })}>
                                {item.is_popular && <span className="popular-text">POPULAR</span>}
                                <h3 className="pricing__title">{ item.name }</h3>
                                <p className="pricing__subtitle">{ item.description }</p>
                                <span className="pricing__price-group">
                                    <span className="pricing__currency">USD</span>
                                    <span className="pricing__price">${ item.price }</span>
                                </span>
                                <span className="block text-[14px] leading-[30px] color-secondary-lighten-60 mb-[40px]">billed monthly</span>
                                <div className="flex justify-center">
                                    <button 
                                        className="btn btn--primary-outline btn-lg-4 rounded-[8px] text-[16px] w-full max-w-[220px] mb-[40px]"
                                        onClick={() => handleSubscribePlan(item.id)}
                                    >
                                        Get Started
                                    </button>
                                </div>
                                <ul className="pricing__list">
                                    {item.options.map((option, index) => 
                                        <li className="pricing__item" key={index}>
                                            <div className="min-w-[20px] mr-[8px]">
                                                <img src={IconCheckCircle} />
                                            </div>
                                            <span>{option}</span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    </div>
}

export default Pricing;