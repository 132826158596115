import { configureStore } from '@reduxjs/toolkit';
import TextGeneratorSlice from './slices/TextGeneratorSlice';
import applicationSlice from './slices/applicationSlice';
import blogGeneratorSlice from './slices/blogGeneratorSlice';
import goalGeneratorSlice from './slices/goalGeneratorSlice';
import newWordGeneratorSlice from './slices/newWordGeneratorSlice';
import essayGeneratorSlice from './slices/essayGeneratorSlice';
import etsyGeneratorSlice from './slices/etsyGeneratorSlice';
import courseGeneratorSlice from './slices/courseGeneratorSlice';
import metaTagsGeneratorSlice from './slices/metaTagsGeneratorSlice';

export const store = configureStore({
    reducer: {
        textGenerator: TextGeneratorSlice,
        application: applicationSlice,
        blogGenerator: blogGeneratorSlice,
        goalGenerator: goalGeneratorSlice,
        newWordGenerator: newWordGeneratorSlice,
        essayGenerator: essayGeneratorSlice,
        etsyGenerator: etsyGeneratorSlice,
        courseGenerator: courseGeneratorSlice,
        metaTagsGenerator: metaTagsGeneratorSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                ignoredActions: ['application/setSocket'],
                // Ignore these field paths in all actions
                ignoredActionPaths: ['application.socket'],
                // Ignore these paths in the state
                ignoredPaths: ['application.socket'],
            },
        }),
});