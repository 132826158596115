import React, { useState } from 'react';
import { brandIcons } from './AddCardModal';
import classNames from 'classnames';
import api from '../../api';
import { useSelector } from 'react-redux';
import { getToken } from '../../redux/slices/applicationSlice';
import Popup from 'reactjs-popup';
import EditCardModal from './EditCardModal';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';
import { toast } from 'react-toastify';

const CardItem = ( {card, setCards }) => {
    const token = useSelector(getToken);
    const Icon = brandIcons[card.brand];
    const handleDeleteCard = async (e) => {
        e.stopPropagation();
        const toastId = toast.loading("Deleting card...");
        const response = await api.paymentCards.deleteCard({
            id: card.id,
            token
        });
        toast.update(toastId, {
            render: response.message, 
            type: response.success ? 'success' : 'error', 
            isLoading: false,
            autoClose: 5000
        });
        if(!response.success) return;
        setCards(cards => cards.filter(({id}) => id !== card.id));
    }
    const handleSetDefault = async (e) => {
        if(card.is_default) return;
        try {
            const response = await api.paymentCards.setCardDefault({
                id: card.id,
                token
            });
            if(!response.success) return;
            api.paymentCards.getCards({token}).then(response => {
                if(!response.success) return;
                setCards(response.data);
            })
        } catch(e) {
            console.log(e);
        }
    }
    const [isOpenedEdit, setIsOpenedEdit] = useState(false);
    const closePopup = () => setIsOpenedEdit(false);
    return <div 
        className={classNames("payment-card-container cursor-pointer", {
        'active': card.is_default
        })}
        onClick={handleSetDefault}
    >
        <div className="payment-card-container-img mr-[16px]">
            {<Icon />}
        </div>
        <div className="payment-card-container-desc">
            <p className='sett-title' >•••• •••• •••• {card.last4}</p>
            <p className='settings-component-subtitle'>Exp. date {card.exp_month}/{String(card.exp_year).slice(2)}</p>
        </div>
        <div className="payment-card-container-actions">
            <button 
                className='btn-outline-primary'
                onClick={(e) => {
                    e.stopPropagation();
                    setIsOpenedEdit(true);
                }}
            >
                Edit
            </button>
            <button 
                className='ml-[16px]'
                onClick={handleDeleteCard}
            >
                <DeleteIcon />
            </button>
        </div>
        <Popup
                open={isOpenedEdit}
                onClose={closePopup}
                overlayStyle={{
                    background: 'rgba(0,0,0,0.5)'
                }}
            >
                <EditCardModal close={closePopup} setCards={setCards} card={card}/>
            </Popup>
    </div>
};

export default CardItem;