import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import GeneratedContent from './GeneratedContent';

import logo from '../../../assets/img/gen4logo.svg';

import { navLinks } from './consts';

import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../db";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getGeneratedCourses, setCourses } from '../../../redux/slices/courseGeneratorSlice';
import api from '../../../api';
import { getToken } from '../../../redux/slices/applicationSlice';

const EmailEducatorGeneratedContent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const courses = useSelector(getGeneratedCourses);
    // console.log(courses);

    const { id } = useParams();
    const token = useSelector(getToken);
    
    const [activeTab, setActiveTab] = useState(navLinks[0].title);
    const activeTabObjectIndex = navLinks.findIndex(({title}) => title === activeTab);
    const ActiveComponent = navLinks[activeTabObjectIndex]?.Component;
    const [sideBarOpened, setSideBarOpened] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [cardFullScreen, setCardFullScreen] = useState(null);
    useEffect(() => {
        setSideBarOpened(cardFullScreen === null ? true : false);
    }, [cardFullScreen])

    const [formatedForStoreData, setFormatedForStoreData] = useState(null);

    const coursesFromIndexedDb = useLiveQuery(
        () => {
            return db?.courses?.toArray()
        }
    );

    // console.log('coursesFromIndexedDb:', coursesFromIndexedDb);
    
    useEffect(()=>{
        console.log('effect', coursesFromIndexedDb);
        //     if(courses?.length && !courses.pop()?.lessons?.length){
        //         setIsLoading(true);
        //     } else {
        //         setIsLoading(false);
        //     }

        let formatedForStore = coursesFromIndexedDb?.map(item => ({
            id: item.id,
            cover: item.settings.cover || null,
            title: item.title,
            description: item.description,
            language: item.settings.language,
            country: item.settings.language,
            numberOfLessons: item.settings.numberOfLessons,
            lessonType: item.settings.lessonType,
            subjectLevel: item.settings.subjectLevel,
            readingLevel: item.settings.readingLevel,
            specialEducation: item.settings.specialEducation,
            competencyMapping: item.settings.competencyMapping,
            scheduleLessonFrequency: item.settings?.scheduleLessonFrequency || '',
            scheduleRepeatOn: item.settings?.scheduleRepeatOn || '',
            scheduleBegins: item.settings?.scheduleBegins || '',
            scheduleTime: item.settings?.scheduleTime || '',
            scheduleIsSendOneMessage: item.settings?.scheduleIsSendOneMessage || false,
            materials: item.materials,
            lessons: item?.lessons?.map(itemM => ({
                title: itemM.title,
                content: itemM.content,
                generated: itemM.generated,
                timeToComplete: itemM.timeToComplete,
                materials: itemM.materials,
            })),
            text: item.settings.text,
            generated: item?.generated || new Date().toISOString(),
            audiences: item.settings?.audiences || []
        }))

        setFormatedForStoreData(formatedForStore);

        // console.log('formatedForStore:', formatedForStore);

        dispatch(setCourses(formatedForStore));
        console.log(formatedForStore);
        if(!id && formatedForStore?.length === 0){
            navigate("/generated-content?type=lesson-kit", {
                replace: true
            });
        }
        if(id && formatedForStore?.length === 0){
            api.courses.getCourse({
                id,
                token
            }).then(resp => {
                if(!resp.success) return;
                dispatch(setCourses([resp.data]))
            })
        }
    },[coursesFromIndexedDb, id, dispatch, navigate]);

    return <main className='w-screen h-screen overflow-hidden flex'>
        <nav className='flex flex-col w-[72px] bg-[#151515]'>
            <div className={classNames('generated-tab w-[72px] h-[60px]', {
                'generated-tab--active-top': activeTabObjectIndex === 0,
                'generated-tab--closed': !sideBarOpened,
            })}>
                <Link to='/'><img src={logo} alt='Logo'/></Link>
            </div>
            {navLinks.map(({ icon, title }, index) =>
                <button
                    key={index} 
                    className={classNames('generated-tab w-[72px] h-[72px]', {
                        'generated-tab--active': title === activeTab,
                        'generated-tab--active-top': index === activeTabObjectIndex - 1,
                        'generated-tab--active-bottom': index === activeTabObjectIndex + 1,
                        'generated-tab--closed': !sideBarOpened,
                    })}
                    onClick={() => {
                        setActiveTab(title);
                        setSideBarOpened(true);
                    }}
                >
                    {icon}
                    <span className='text-[10px] font-medium'>{title}</span>
                </button>
            )}
            <div className={classNames('generated-exit-wrapper', {
                'generated-tab--active-bottom': activeTabObjectIndex === navLinks.length - 1,
                'generated-tab--closed': !sideBarOpened,
            })}>
                <Link to="/my-lessons" className='w-[72px] h-[72px] flex flex-col gap-[8px] justify-center items-center opacity-40 bg-[#111111]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path d="M5 12.5H19" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10 7.5L5 12.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10 17.5L5 12.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <span className='text-[10px] font-medium'>Exit</span>
                </Link>
            </div>
        </nav>
        <div className={classNames('generated-sidebar', {
            'generated-sidebar--closed': !sideBarOpened
        })}>
            <button 
                className='absolute top-[18px] right-[16px]' 
                onClick={() => {
                    setSideBarOpened(false);
                    setActiveTab(null);
                }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g opacity="0.5">
                        <path d="M18 6L6 18" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6 6L18 18" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                </svg>
            </button>
            {ActiveComponent && <ActiveComponent setIsLoading={setIsLoading} />}
        </div>
        { isLoading ? <div className="flex flex-col justify-center items-center h-full flex-1">
            <div className="lds-ring"><div /><div /><div /><div /></div>
            <div className="mt-[36px] text-[18px] font-medium leading-[13px] opacity-90">Loading...</div>
            <div className="mt-[24px] text-[14px] leading-[10px] opacity-50 text-[#A7A7A7]">Please wait for your results...</div>
        </div> : <GeneratedContent cardFullScreen={cardFullScreen} setCardFullScreen={setCardFullScreen} coursesFromIndexedDb={coursesFromIndexedDb} /> }
    </main>
};

export default EmailEducatorGeneratedContent;