import { useSelector } from "react-redux";
import { getToken } from "../../../redux/slices/applicationSlice";
import { getActiveBlogIndex, getGeneratedBlogs, setActiveBlogIndex } from "../../../redux/slices/blogGeneratorSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ReactComponent as ArrowIcon } from '../Blog/icons/arrow.svg';
import api from "../../../api";
import classNames from "classnames";
import { format } from "date-fns";
import ReleaseCardContent from "./ReleaseCardContent";

const ReleasePageCard = ({data, index, setCardFullScreen, isFullScreen, isNotDone = false}) => {
    const token = useSelector(getToken);
    const activeBlogIndex = useSelector(getActiveBlogIndex);
    const blogs = useSelector(getGeneratedBlogs);
    const {activeVersionIndex } = blogs[index] || {};
    const { generatedDate, selectedVersionId } = blogs[index];
    const dispatch = useDispatch();
    const isOpen = index === activeBlogIndex;

    const isSelected = selectedVersionId === data[activeVersionIndex]?.id;

    const { id } = useParams();
    const [updateBlogs, setUpdateBlogs] = useState(false);
    useEffect(() => {
        if(updateBlogs) {
            api.assets.changeAsset({
                id,
                data: {
                    content: JSON.stringify(blogs)
                },
                token
            }).catch(e => console.log(e))
            setUpdateBlogs(false);
        }
    }, [updateBlogs])

    const markup = data[activeVersionIndex]?.activeContent || data[activeVersionIndex]?.content;

    return <><li 
        className={classNames(
            'blog-post-card', 
            isFullScreen ? 'h-full' : isOpen ? 'h-[442px]' : 'h-[42px]',
            (isFullScreen && !isOpen) ? 'hidden': 'flex'
        )}
        style={{
            borderColor: isSelected ? '#FF8114': '#1F1F1F'
        }}
    >
        <div 
            className='flex items-center justify-between py-[11px] px-[16px]'
        >
            <div className={`gen-con-header-title gap-[8px] cursor-pointer ${isNotDone ? 'disabled' : ''}`} onClick={() => dispatch(setActiveBlogIndex(index))}>
                <div
                    className={`gen-header-arrow pointer-events-none ${isOpen && 'rotate-180'}`}
                >
                    <ArrowIcon />
                </div>
                <span className="leading-[18px]">Content {index + 1}</span>
            </div>
            <div className="gen-con-header-date leading-[18px]">
                Generated  {format(generatedDate ? new Date(generatedDate) : new Date(), 'dd.MM.yyyy, H:mm')}
            </div>
        </div>
        
        {isOpen && <ReleaseCardContent
            index={index}
            isFullScreen={isFullScreen}
            setCardFullScreen={setCardFullScreen}
            setUpdateBlogs={setUpdateBlogs}
            markup={markup}
            isNotDone={isNotDone}
        /> }
    </li>
    {/* <div dangerouslySetInnerHTML={{ __html: markup }}></div> */}
    </>;
};

export default ReleasePageCard;