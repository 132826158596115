import { add } from "date-fns"

export const step3Data = {
    "Positive": [
        "Reframing negative situations in a positive light ",
        "Focus on solutions & positive outcomes",
        "Affirmations",
        "Positive self talk",
        "Keep sentences positives",
        "Replace negative words with positive",
    ],
    "Category": [
        "Focus on solutions & positive outcomes category",
        "Reframing negative situations in a positive light category",
        "Affirmations category",
        "Positive self talk category",
        "Keep sentences positives category",
        "Replace negative words with positive category",
    ],
    "Templates": [
        "Affirmations templates",
        "Reframing negative situations in a positive light templates",
        "Focus on solutions & positive outcomes templates",
        "Positive self talk templates",
        "Keep sentences positives templates",
        "Replace negative words with positive templates",
    ],
    "Structure": [
        "Positive self talk structure",
        "Reframing negative situations in a positive light structure",
        "Focus on solutions & positive outcomes structure",
        "Affirmations structure",
        "Keep sentences positives structure",
        "Replace negative words with positive structure",
    ],
    "SEO": [
        "Keep sentences positives seo",
        "Reframing negative situations in a positive light seo",
        "Focus on solutions & positive outcomes seo",
        "Affirmations seo",
        "Positive self talk seo",
        "Replace negative words with positive seo",
    ]
}

export const step4Data = {
    "SEO": [
        "Reframing negative situations in a positive light ",
        "Focus on solutions & positive outcomes",
        "Affirmations",
        "Positive self talk",
        "Keep sentences positives",
        "Replace negative words with positive",
    ],
    "Meta Tags": [
        "Focus on solutions & positive outcomes category",
        "Reframing negative situations in a positive light category",
        "Affirmations category",
        "Positive self talk category",
        "Keep sentences positives category",
        "Replace negative words with positive category",
    ],
    "Social Media": [
        "Affirmations templates",
        "Reframing negative situations in a positive light templates",
        "Focus on solutions & positive outcomes templates",
        "Positive self talk templates",
        "Keep sentences positives templates",
        "Replace negative words with positive templates",
    ],
    "Ads": [
        "Positive self talk structure",
        "Reframing negative situations in a positive light structure",
        "Focus on solutions & positive outcomes structure",
        "Affirmations structure",
        "Keep sentences positives structure",
        "Replace negative words with positive structure",
    ],
    "Misc": [
        "Keep sentences positives seo",
        "Reframing negative situations in a positive light seo",
        "Focus on solutions & positive outcomes seo",
        "Affirmations seo",
        "Positive self talk seo",
        "Replace negative words with positive seo",
    ]
}

export const goalMilestones = [
    {
        id: 1,
        title: 'Complete the Manuscript',
        deadline: add(new Date(), {days: 1}),
        description: 'Start by writing your novel, aiming to complete the entire manuscript. This timing can vary widely based on the length and complexity of your novel.',
        labels: ['Text Generator'],
        generatedDate: new Date(),
        done: true,
    }, {
        id: 2,
        title: 'First Draft Review and Revision',
        deadline: add(new Date(), {days: 3}),
        description: 'After finishing the first draft, take a break before revising. Review your manuscript for plot holes, character development, and overall flow. Make necessary revisions.',
        labels: ['Text Generator'],
        generatedDate: new Date(),
        done: true,
    }, {
        id: 3,
        title: 'Beta Reader Feedback',
        deadline: add(new Date(), {days: 5}),
        description: 'Share your manuscript with beta readers (trusted individuals who provide feedback). Give them time to read and provide comments.',
        labels: [],
        generatedDate: new Date(),
        done: true
    }, {
        id: 4,
        title: 'Second Draft and Editing',
        deadline: add(new Date(), {days: 7}),
        description: 'Incorporate feedback from beta readers and conduct a thorough self-edit. Consider hiring a professional editor for structural and line editing.',
        labels: ['Text Generator'],
        generatedDate: new Date(),
        done: true
    }, {
        id: 5,
        title: 'Final Manuscript Review',
        deadline: add(new Date(), {days: 9}),
        description: "Review the manuscript one final time for any remaining issues. Ensure it's polished and ready for submission or publication.",
        labels: ['Text Generator'],
        generatedDate: new Date(),
        done: true
    }, {
        id: 6,
        title: 'Publishing Decisions',
        deadline: add(new Date(), {days: 11}),
        description: 'Decide whether you want to pursue traditional publishing or self-publishing. Research and choose your publishing path. If going traditional, query agents and publishers.',
        labels: [],
        generatedDate: new Date(),
        done: true
    }, {
        id: 7,
        title: 'Formatting and Cover Design',
        deadline: add(new Date(), {days: 13}),
        description: 'Prepare your manuscript for publication, which includes formatting it for print and e-book versions. Work on cover design or hire a professional designer.',
        labels: ['Cover Generator', 'Product Description'],
        generatedDate: new Date(),
        done: true
    }, 
    {
        id: 8,
        title: 'Publishing and Marketing',
        deadline: add(new Date(), {days: 15}),
        description: `Release your book according to your chosen publishing route. Timing can vary widely here. For traditional publishing, it depends on acceptance and the publisher's schedule. For self-publishing, it's your decision.`,
        labels: ['Landing Page Content', 'Tweet Campaign', 'SEO', 'Social Media Campaign'],
        generatedDate: new Date(),
        done: false
    }, 
]

export const partOfSpeechDefinitions = {
    '': 'Please select part of speech to see definition',
    Noun: 'Noun - A word that represents a person, place, thing, or idea',
    Pronoun: 'Pronoun - A word that takes the place of a noun, often used to avoid repetition.',
    Verb: 'Verb - A word that expresses an action, occurrence, or state of being.',
    Adjective: 'Adjective - A word that describes or modifies a noun or pronoun by providing more information about its qualities.',
    Adverb: 'Adverb - A word that modifies a verb, adjective, or other adverb, typically providing information about how, when, where, or to what degree.',
    Preposition: 'Preposition - A word that shows the relationship between a noun (or pronoun) and other words in a sentence, indicating location, direction, time, or manner.',
    Conjunction: 'Conjunction - A word that connects words, phrases, or clauses, indicating the relationship between them.',
    Interjection: 'Interjection - A word or phrase used to express strong emotion or surprise, often as a standalone expression.',
}