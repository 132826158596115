import React, { useContext, useEffect, useState, } from 'react';
import { GenerateFormContext, initialState } from '../GeneratorContextComponent';
import Tippy from '@tippyjs/react';
import SettingsTab from "./Settings/SettingsTab";
import AddOnsTab from "./Settings/AddOnsTab";
import FilesTab from "./Settings/FilesTab";

const Settings = ({ back, selectedForSetting, setSelectedForSetting, setupAll }) => {
    const [generateForm, setGenerateForm] = useContext(GenerateFormContext);
    const { selectedMethod, courses, } = generateForm;

    const mainTabs = [
        {
            title: 'Settings',
            subtitle: 'Setup newsletter',
        },
        {
            title: 'Add-ons',
            subtitle: 'Addition functionality',
        },
        {
            title: 'Files',
            subtitle: 'Upload materials',
        },
    ];

    const [activeTab, setActiveTab] = useState(mainTabs[0].title);

    const ActiveTabComponent = activeTab === 'Add-ons' ? AddOnsTab : activeTab === 'Files' ? FilesTab : SettingsTab;

    return <>
        <div className='w-full max-w-[500px] m-auto mt-0'>
            <div className="d-flex items-center mb-[24px]">
                <a
                    href="#" className="btn btn-outline-black btn-lg-2 rounded-full h-[32px] px-[11px] mr-[16px] text-[13px]"
                    onClick={(e)=>{
                        e.preventDefault();
                        back();
                    }}
                >
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-[6px]">
                        <path d="M3.75 9H14.25" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M7.5 5.25L3.75 9" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M7.5 12.75L3.75 9" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    Back
                </a>
                <div>
                    <h3 className="text-[14px] text-[#E8E8E8]">{!setupAll ? selectedForSetting.text : 'Setup All' }</h3>
                    <div className="d-flex items-center text-[#A7A7A7] text-[12px] opacity-40 mt-[3px]">
                        <span>No Settings</span>
                        <svg width="3" height="4" viewBox="0 0 3 4" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-[12px]">
                            <circle cx="1.5" cy="2" r="1.5" fill="#4F4F4F"/>
                        </svg>
                        <span>{selectedForSetting?.files?.length || 0} files</span>
                    </div>
                </div>
            </div>
            <div className="mb-[36px]">
                <label className='text-[#A7A7A7] text-[12px] leading-[14px] block mb-[10px] min-h-[16px]'>
                    Additional Background Information
                </label>
                <div className='flex gap-[10px] mb-[10px]'>
                    <textarea
                        className="textarea min-h-[120px]"
                        placeholder='Add some info to cater the lesson plan with specific elements... '
                        value={selectedForSetting.backgroundInformation}
                        onChange={(e) => {
                            setSelectedForSetting((prevState)=>({
                                ...prevState,
                                backgroundInformation: e.target.value
                            }));
                        }}
                    />
                </div>
            </div>
            <div className="methods-items-wrpr pb-[24px]">
                {
                    mainTabs.map((tab)=>{
                        return <a
                            href="#" className={`method-box-item hover:opacity-70 ${activeTab === tab.title ? 'active' : ''}`}
                            style={{ padding: "14px 10px" }}
                            key={tab.title} onClick={(e)=>{
                                e.preventDefault();
                                setActiveTab(tab.title);
                            }}
                        >
                            <h3>{ tab.title }</h3>
                            <p>{ tab.subtitle }</p>
                        </a>
                    })
                }
            </div>

            <ActiveTabComponent 
                selectedForSetting={selectedForSetting}
                setSelectedForSetting={setSelectedForSetting}
            />
        </div>
    </>;
};

const ButtonTooltip = ({ tooltip }) => {
    return <Tippy
        offset={[0,4]}
        arrow={false}
        className='generator-button-tooltip'
        placement='bottom'
        content={tooltip}
    >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.9991 8.47351L8.88414 7.98181C9.32583 7.73646 9.59977 7.27093 9.59977 6.76567C9.54745 5.92546 8.82688 5.28495 7.98633 5.33149C7.23528 5.30031 6.56834 5.80801 6.39844 6.54026" stroke="#A7A7A7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            <circle cx="7.99859" cy="7.99957" r="6.0025" stroke="#A7A7A7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.06814 10.6011C8.06809 10.6379 8.03822 10.6678 8.00141 10.6677C7.9646 10.6677 7.93476 10.6379 7.93475 10.6011C7.93473 10.5643 7.96454 10.5344 8.00135 10.5344C8.01907 10.5343 8.03607 10.5414 8.0486 10.5539C8.06113 10.5664 8.06816 10.5834 8.06814 10.6011" stroke="#A7A7A7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </Tippy>
};

export default Settings;