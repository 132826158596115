export const axiosConfig = {
    baseURL: 'https://works.webstaginghub.com/openai-api',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
}

export const apiConfig = {
    baseURL: 'https://aikit.webstaginghub.com',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'X-Requested-With': 'XMLHttpRequest',
    },
}