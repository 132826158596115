import React, { useContext, useEffect, useState, } from 'react';
import { useSelector } from 'react-redux';

import { GenerateFormContext, initialState } from '../GeneratorContextComponent';
import { getSocket, getToken } from '../../../redux/slices/applicationSlice';
import Step1 from "./Step1";
import Step2 from "./Step2";

import { toast } from 'react-toastify';
 

const EmailEducatorConfigComponent = ({ setLoading }) => {
    const socket = useSelector(getSocket);

    const [step, setStep] = useState(1);
    
    const [randomGenerateLoading, setRandomGenerateLoading] = useState(false);

    const [generateForm, setGenerateForm] = useContext(GenerateFormContext);
    const { selectedMethod, courses, } = generateForm;

    const generateRandom = () => {
        setStep(2);
        if(selectedMethod === 'goal' || selectedMethod === 'topic'){
            setRandomGenerateLoading(true);

            socket.send({
                event: 'lesson-kit-tools',
                payload: {
                    operation: selectedMethod === 'goal' ? 'goal_suggestions' : selectedMethod === 'topic' ? 'topic_suggestions' : '',
                    amount: 2,
                },
                handler: (response) => {
                    if(response?.isError){
                        toast.error("AI bot is not available at the moment");
                        return;
                    }

                    if(response.isDone){
                        setRandomGenerateLoading(false);

                        setGenerateForm((prevState) => {
                            return {
                                ...prevState,
                                courses: [...prevState.courses, {
                                    method: selectedMethod, // goal | topic
                                    text: response.data[0],
                                    backgroundInformation: "",
                                    language: "English",
                                    country: "Global",
                                    numberOfLessons: 3,
                                    lessonType: 'Curation',
                                    subjectLevel: "Intermediate",
                                    readingLevel: "Intermediate",
                                    specialEducation: "None",
                                    competencyMapping: "",
                                    addons: [],
                                    files: [],
                                    cover: null,
                                    scheduleLessonFrequency: '',
                                    scheduleRepeatOn: '',
                                    scheduleBegins: '',
                                    scheduleTime: '',
                                    scheduleIsSendOneMessage: false,
                                }]
                            }
                        })
                    }
                }
            });
        }
    }

    return <>
        { step === 1 && <Step1 setStep={setStep} generateRandom={generateRandom} randomGenerateLoading={randomGenerateLoading} /> }
        { step === 2 && <Step2 generateRandom={generateRandom} randomGenerateLoading={randomGenerateLoading} /> }
    </>;
};

export default EmailEducatorConfigComponent;