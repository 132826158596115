import React, { useRef, useEffect, useState } from 'react'
import { useClickOutside } from '../../helpers/useClickOutside';

const MultiSelect = ({ placeholder, value = [], options, className = '', change }) => {
    const selectRef = useRef();
    const [open, setOpen] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
    const [search, setSearch] = useState('');
    const [localValue, setLocalValue] = useState([]);

    const toggleOpen = (e) => {
        let selectClientRect = e.target.getBoundingClientRect();
        setDropdownPosition({
            top: selectClientRect.y + selectClientRect.height + 8,
            left: selectClientRect.x,
        })
        
        setOpen(prev => !prev);
    }

    useClickOutside(selectRef, () => setOpen(false));

    const filteredOptions = options.filter(item => item.label.toLowerCase().includes(search.toLowerCase()));

    const clear = () => {
        setLocalValue([]);
        change && change([]);
        setOpen(false);
    }

    const apply = () => {
        change && change(localValue);
        setOpen(false);
    }

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    useEffect(() => {
        const closeMethod = () => {
            setOpen(false);
        }

        if(document.querySelector('.page-section')){
            document.querySelector('.page-section').addEventListener("scroll", closeMethod);
        }

        return () => {
            if(document.querySelector('.page-section')){
                document.querySelector('.page-section').removeEventListener("scroll", closeMethod);
            }
        }
    }, []);

    return (
        <div className={`custom-multiselect ${open ? 'active' : ''} ${className}`} ref={selectRef}>
            <div className="custom-multiselect-header" onClick={(e)=>{ toggleOpen(e); }}>
                { placeholder || 'Select' }
                <svg className="pointer-events-none" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.9">
                        <path d="M9.37513 12.292L6.70847 9.64616C6.40291 9.32671 6.3352 8.97949 6.50534 8.60449C6.67548 8.22949 6.97236 8.04199 7.39597 8.04199H12.6668C13.0765 8.04199 13.3665 8.22949 13.5366 8.60449C13.7067 8.97949 13.639 9.32671 13.3335 9.64616L10.6876 12.292C10.5974 12.3753 10.4949 12.4378 10.3803 12.4795C10.2658 12.5212 10.146 12.542 10.021 12.542C9.88208 12.542 9.75881 12.5212 9.65117 12.4795C9.54354 12.4378 9.45152 12.3753 9.37513 12.292Z" fill="white"/>
                    </g>
                </svg>
            </div>
            { open &&
                <div className="custom-multiselect-body" style={{
                    left: `${dropdownPosition.left}px`, top: `${dropdownPosition.top}px`
                }}>
                    <div className="relative w-100 p-[6px]">
                        <svg
                            width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                            style={{ position: 'absolute', left: '16px', top: '50%', transform: "translateY(-50%)", pointerEvents: 'none' }}
                        >
                            <g opacity="0.3">
                                <path d="M16.6667 16.6662L13.647 13.6465" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M3.33325 9.37467C3.33325 12.7114 6.0382 15.4163 9.37492 15.4163C12.7116 15.4163 15.4166 12.7114 15.4166 9.37467C15.4166 6.03795 12.7116 3.33301 9.37492 3.33301V3.33301C6.0383 3.33325 3.3335 6.03806 3.33325 9.37467" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                        </svg>
                        <input
                            type="search" className="form-search-radiused__input" placeholder="Search..."
                            style={{ borderRadius: '8px' }} value={search} onInput={(e) => { setSearch(e.target.value); }}
                        />
                    </div>
                    <ul>
                        {
                            filteredOptions.map(item => {
                                return <li
                                    key={item.value} className={localValue.includes(item.value) ? 'active' : ''}
                                    onClick={(e)=>{
                                        setLocalValue(prevState => {
                                            return prevState.includes(item.value) ? prevState.filter(itemValue => itemValue !== item.value) : [...prevState, item.value]
                                        })
                                    }}
                                >
                                    <span className={localValue.includes(item.value) ? 'option-checkbox active' : 'option-checkbox'}>
                                        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.6654 1.79175L4.2487 8.20841L1.33203 5.29175" stroke="#331A04" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </span>
                                    { item.label }
                                </li>
                            })
                        }
                    </ul>
                    <div className="custom-multiselect-body-footer">
                        <button
                            className="btn btn--lg px-[10px] py-[5px] h-[32px] mx-[5px]"
                            onClick={clear}
                        >
                            Clear
                        </button>
                        <button
                            className="btn btn--primary btn--lg px-[10px] py-[5px] orange-rounded-drop-btn h-[32px] font-[500]"
                            onClick={apply}
                        >
                            Apply
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}

export default MultiSelect;