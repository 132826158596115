import React, { useEffect, useState } from 'react';
import SelectBox from '../../components/base/SelectBox';
import { Tabs } from '../../components/base/Tabs';
import Label from '../../components/base/Label';
import Input from '../../components/base/Input';
import TextArea from '../../components/base/TextArea';
import Tippy from '@tippyjs/react';
import BotRoleSelect from '../GeneratorV4/BotRoleSelect';
import { botBrandToneOptions, botLengthOptions, botToneOptions, languageOptions } from '../../configs/selectOptions';
import classNames from 'classnames';
import api from '../../api';
import { useSelector } from 'react-redux';
import { getFormOptions, getToken } from '../../redux/slices/applicationSlice';
import { toast } from 'react-toastify';
import getBotImageSrc from '../../helpers/getBotImageSrc';

const Settings = ({botKit, setBotKit}) => {
    const token = useSelector(getToken);
    const [activeTab, setActiveTab] = useState('AI Creation');
    const [formValues, setFormValues] = useState(botKit);
    const [roleSelectOpened, setRoleSelectOpened] = useState(false);

    const formOptions = useSelector(getFormOptions)

    const [botBrandToneOptions, setBotBrandToneOptions] = useState([])
    const [botToneOptionsRes, setBotToneOptionsRes] = useState([])
    const [languageOptions, setLanguageOptions] = useState([])
    const [responseLengthOptions, setResponseLengthOptions] = useState([])

    useEffect(() => {
        const toneOptions = [];
        const brandToneOptions = [];
        const languageOptions = [];
        const responseLengthOptions = [];
    
        formOptions &&
            formOptions.forEach(el => {
                if (el.key === 'tone') {
                    toneOptions.push({ value: el.title, label: el.title });
                } else if (el.key === 'bot_brand_tone') {
                    brandToneOptions.push({ value: el.title, label: el.title });
                } else if (el.key === 'language') {
                    languageOptions.push({ value: el.title, label: el.title });
                } else if (el.key === 'bot_response_length') {
                    responseLengthOptions.push({ value: el.title, label: el.title });
                }
            });
    
        setBotToneOptionsRes(toneOptions);
        setBotBrandToneOptions(brandToneOptions);
        setLanguageOptions(languageOptions);
        setResponseLengthOptions(responseLengthOptions);
    }, [formOptions]);


    const handleUpdateBot = async () => {
        const toastId = toast.loading('Saving changes...');
        try {
            const response = await api.assets.changeAsset({
                id: botKit.assetId,
                data: {
                    name: formValues.name,
                    bot: formValues
                },
                token
            });
            toast.update(toastId, {
                render: response.success ? 'Changes saved successfully!' : 'Error. Please try again.',
                type: response.success ? 'success' : 'error', 
                isLoading: false,
                autoClose: 5000
            });
            if(!response.success) return;
            setBotKit(formValues)
        } catch (e) {
            console.log(e);
        }
    }
    return <div className='py-[13px] px-[16px] flex-1 flex flex-col overflow-auto'>
        <h2 className='text-[14px] font-medium leading-[14px]'>Settings</h2>
        <p className='text-[12px] leading-[14px] opacity-40 mt-[6px]'>Setup bot specialization or tone</p>
        <div className='flex flex-col gap-[16px] flex-1'>
            <Tabs
                labelsArray={['General', 'Bot Settings']}
                idxCallBack={(val) => setActiveTab(val)}
                classes='goal-tabs'
            />
            {activeTab === 'General' && <>
                <div className='flex flex-col gap-[10px]'>
                    <Label htmlFor='bot-name'>Bot Name</Label>
                    <Input
                        id='bot-name'
                        value={formValues.name}
                        onChange={(e) => setFormValues({
                            ...formValues,
                            name: e.target.value
                        })}
                        className={'h-[120px]'}
                        placeholder='Enter bot name...'
                    />
                </div>
                <div className='flex flex-col gap-[10px]'>
                    <Label htmlFor='description'>Bot Description</Label>
                    <TextArea
                        id='description'
                        value={formValues.description}
                        change={(description) => setFormValues({
                            ...formValues,
                            description
                        })}
                        className={'h-[120px]'}
                        placeholder='Enter bot description...'
                    />
                </div>
                <div className='flex flex-col gap-[10px]'>
                    <Label htmlFor='description'>Bot Avatar</Label>
                    <div className='flex gap-[16px] items-center'>
                        <img 
                            alt='bot'
                            className='w-[44px] h-[44px] rounded-[8px]'
                            src={getBotImageSrc(formValues.logo)} />
                        <div className='flex flex-col gap-[8px]'>
                            <label className='text-[13px] text-orange-primary font-medium underline leading-[14px] text-center cursor-pointer'>
                                Upload Logo
                                <input 
                                    type='file'
                                    hidden
                                    accept="image/png, image/jpeg"
                                    onChange={(e) => {
                                        const logo = e.target.files[0];
                                        logo && setFormValues({
                                            ...formValues,
                                            logo
                                        })
                                    }}
                                />
                            </label>
                            <span className='text-[12px] text-[#A7A7A7] opacity-50 leading-[14px]'>{'JPG/PNG <5 MB'}</span>
                        </div>
                    </div>
                </div>
            </>}
            {activeTab === 'Bot Settings' && <>
                <div className='flex gap-[16px] flex-col'>
                    <div className='flex flex-col gap-[10px] flex-1'>
                        <Label>Acting as / Role</Label>
                        <Tippy
                            // ref={roleSelectRef}
                            offset={[0,4]}
                            arrow={false}
                            className='generator-button-tooltip-modal w-[318px] max-h-[516px] overflow-auto no-scroll'
                            placement='bottom-start'
                            visible={roleSelectOpened}
                            content={<BotRoleSelect
                                value={formValues.roles}
                                change={(roles) => setFormValues({
                                    ...formValues,
                                    roles
                                })}
                            />}
                            // onClickOutside={() => setRoleSelectOpened(!roleSelectOpened)}
                        >
                            <button 
                                className='h-[40px] py-[8px] pl-[14px] pr-[8px] flex items-center justify-between
                                bg-[#1B1B1B] rounded-[8px] border border-solid border-[#333]'
                                onClick={() => setRoleSelectOpened(!roleSelectOpened)}
                            >
                                <span className='text-[14px] text-[#ffffffe6]'>Select bot role</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <g opacity="0.9">
                                        <path d="M9.37513 12.292L6.70847 9.64616C6.40291 9.32671 6.3352 8.97949 6.50534 8.60449C6.67548 8.22949 6.97236 8.04199 7.39597 8.04199H12.6668C13.0765 8.04199 13.3665 8.22949 13.5366 8.60449C13.7067 8.97949 13.639 9.32671 13.3335 9.64616L10.6876 12.292C10.5974 12.3753 10.4949 12.4378 10.3803 12.4795C10.2658 12.5212 10.146 12.542 10.021 12.542C9.88208 12.542 9.75881 12.5212 9.65117 12.4795C9.54354 12.4378 9.45152 12.3753 9.37513 12.292Z" fill="white"/>
                                    </g>
                                </svg>
                            </button>
                        </Tippy>
                    </div>
                    <div className='flex flex-col gap-[10px] flex-1'>
                        <Label>Bot Tone</Label>
                        <SelectBox key={botToneOptionsRes}
                            className='default-select flex-1'
                            propsOptions={botToneOptionsRes}
                            value={formValues.bot_tone}
                            change={(bot_tone) => setFormValues({
                                ...formValues,
                                bot_tone
                            })}
                        />
                    </div>
                </div>
                <div className='flex gap-[16px] flex-col'>
                    <div className='flex flex-col gap-[10px] flex-1'>
                        <Label>Brand Tone</Label>
                        <SelectBox key={botBrandToneOptions}
                            className='default-select flex-1'
                            propsOptions={botBrandToneOptions}
                            value={formValues.brand_tone}
                            change={(brand_tone) => setFormValues({
                                ...formValues,
                                brand_tone
                            })}
                        />
                    </div>
                    <div className='flex flex-col gap-[10px] flex-1'>
                        <Label>Response Length</Label>
                        <SelectBox key={responseLengthOptions}
                            className='default-select flex-1'
                            propsOptions={responseLengthOptions}
                            value={formValues.response_length}
                            change={(response_length) => setFormValues({
                                ...formValues,
                                response_length
                            })}
                        />
                    </div>
                </div>
                <div className='flex gap-[16px] flex-col'>
                    <div className='flex flex-col gap-[10px] flex-1'>
                        <Label>Multilingual Support</Label>
                        <div className='flex gap-[2px] p-[2px] rounded-[8px] border border-solid border-[#333]'>
                            <button 
                                className={classNames('flex items-center justify-center flex-1 rounded-[6px] h-[36px]', {
                                    'bg-orange-primary' : formValues.multilang_enabled
                                })}
                                onClick={() => setFormValues({
                                    ...formValues,
                                    multilang_enabled: true
                                })}
                            >
                                <span className={classNames('text-[13px] opacity-90', 
                                formValues.multilang_enabled ? 'text-[#151515] font-medium' : 'text-[#A7A7A7] font-normal')}>
                                    Enabled
                                </span>
                            </button>
                            <button 
                                className={classNames('flex items-center justify-center flex-1 rounded-[6px] h-[36px]', {
                                    'bg-orange-primary' : !formValues.multilang_enabled
                                })}
                                onClick={() => setFormValues({
                                    ...formValues,
                                    multilang_enabled: false
                                })}
                            >
                                <span className={classNames('text-[13px] opacity-90', 
                                !formValues.multilang_enabled ? 'text-[#151515] font-medium' : 'text-[#A7A7A7] font-normal')}>
                                    Disabled
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className='flex flex-col gap-[10px] flex-1'>
                        {!formValues.multilang_enabled && <>
                            <Label>Select Language</Label>
                            <SelectBox key={languageOptions}
                                className='default-select flex-1'
                                propsOptions={languageOptions}
                                value={formValues.language}
                                change={(language) => setFormValues({
                                    ...formValues,
                                    language
                                })}
                            />
                        </>}
                    </div>
                </div>    
                <div 
                    className='flex py-[5px] gap-[14px] cursor-pointer'
                    onClick={() => setFormValues({
                        ...formValues,
                        imported_data: !formValues.imported_data
                    })}
                >
                    <div 
                        className={`w-[20px] h-[20px] rounded-[4px] flex items-center justify-center
                        ${formValues.imported_data ? 'bg-orange-primary' : 'bg-[#232323]'}`}
                    >
                        {formValues.imported_data &&
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                <path d="M11.6654 3.79169L5.2487 10.2084L2.33203 7.29169" stroke="#331A04" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        }
                    </div>
                    <div className='text-[13px] leading-[20px] flex items-center gap-[6px]'>
                        Imported Data Only
                    </div>
                </div>
                <div 
                    className='flex py-[5px] gap-[14px] cursor-pointer'
                    onClick={() => setFormValues({
                        ...formValues,
                        include_citations: !formValues.include_citations
                    })}
                >
                    <div 
                        className={`w-[20px] h-[20px] rounded-[4px] flex items-center justify-center
                        ${formValues.include_citations ? 'bg-orange-primary' : 'bg-[#232323]'}`}
                    >
                        {formValues.include_citations &&
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                <path d="M11.6654 3.79169L5.2487 10.2084L2.33203 7.29169" stroke="#331A04" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        }
                    </div>
                    <div className='text-[13px] leading-[20px] flex items-center gap-[6px]'>
                        Include Citations
                    </div>
                </div>
            </>}
        </div>
        <button className='min-h-[40px] generated-button btn' onClick={handleUpdateBot}>
            Save Changes
        </button>
    </div>
};

export default Settings;