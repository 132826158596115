import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getSocket } from "../../../redux/slices/applicationSlice";

const DynamicTextArea = ({ initialText, withClues = false, callback }) => {
    const [text, setText] = useState(initialText || '')
    const [height, setHeight] = useState(0)
    const textAreaRef = useRef(null)
    const socket = useSelector(getSocket)

    const blockRef = useRef(null)
    const [open, setOpen] = useState(false)

    const [clueArr, setClueArr] = useState(null)
  
    useEffect(() => {
        if (!withClues) return
        socket.send({
            event: 'etsy-listing-tools',
            payload: {
                operation: 'title_examples',
                content: initialText
            },
            handler: (response) => {
                if(!response.payload) return;
                setClueArr(response.payload)
            }
        })
    }, [])

    const handleClick = () => {
        setOpen(true)
    }

    const handleClose = (e) => {
        if (blockRef.current && !blockRef.current.contains(e.target)) {
            setOpen(false)
        }
    }

    const optionClick = (val) => {
        setText(val)
        setOpen(false)
    }

    useEffect(() => {
        callback && callback(text)
    }, [text])

    useEffect(() => {
        if (open) {
            document.addEventListener('click', handleClose)
        } else {
            document.removeEventListener('click', handleClose)
        }
        

        return () => document.removeEventListener('click', handleClose)

    }, [open])

    useEffect(() => {
        const newText = initialText
        setText(newText)
    
        setHeight(textAreaRef.current.scrollHeight)
    }, [])
  
    return (
        <div className="relative" ref={blockRef}>
            <textarea
            className="text-[#A7A7A7] font-normal textarea"
            ref={textAreaRef}
            value={text}
            style={{ height: height + 'px' }}
            onClick={handleClick}
            onChange={(e) => setText(e.currentTarget.value)}
            />
            {withClues && open && <ul className="dynamic-textarea-clues-block">
                {clueArr && clueArr.map((el, idx) => <li key={idx} onClick={() => optionClick(el)} className="option">{el}</li>)}
            </ul>}
        </div>
        )
  }
  
export default DynamicTextArea;