import React, { useContext, useEffect, useState, } from 'react';

const Audience = ({ selectedForSetting, setSelectedForSetting }) => {
    const [newContact, setNewContact] = useState({ name: '', email: '' });
    const canAdd = newContact.name?.length && newContact.email?.length;
    const [openMenu, setOpenMenu] = useState(null);

    return <>
        <div>
            <div className='flex gap-[10px] mb-[16px]'>
                <input
                    className='flex-1 w-100 h-[40px] p-[8px] px-[14px] bg-[#1B1B1B] rounded-[8px] border border-solid border-[#333] text-[14px]'
                    placeholder='Contact name...'
                    value={newContact.name}
                    onChange={(e) => {
                        setNewContact((prevState) => ({ ...prevState, name: e.target.value }));
                    }}
                />
                <input
                    className='flex-1 w-100 h-[40px] p-[8px] px-[14px] bg-[#1B1B1B] rounded-[8px] border border-solid border-[#333] text-[14px]'
                    placeholder='Contact email...'
                    value={newContact.email}
                    onChange={(e) => {
                        setNewContact((prevState) => ({ ...prevState, email: e.target.value }));
                    }}
                />
                <button 
                    className={`h-[40px] flex items-center justify-center px-[10px] radial-bg rounded text-[#331A04] text-[14px] font-medium rounded-lg ${!canAdd ? 'block-disabled' : ''}`}
                    disabled={!canAdd}
                    onClick={() => {
                        setSelectedForSetting((prevState)=>({
                            ...prevState,
                            settings: {
                                ...prevState.settings,
                                audiences: [...(prevState.settings.audiences || []), newContact]
                            }
                        }));
                        setNewContact({ name: '', email: '' });
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10 4.16699V15.8337" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4.16602 10H15.8327" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
            <ul>
                {
                    selectedForSetting?.settings?.audiences?.map((item, itemIndex)=>{
                        return <li key={itemIndex} className="d-flex items-center justify-between border bg-[#1B1B1B] py-[12px] px-[14px] rounded-lg mb-[8px]">
                            <div>
                                <h3 className="text-[14px] text-[#E8E8E8] mb-[5px]">{ item.name || 'Contact name' }</h3>
                                <p className="text-[12px] text-[#A7A7A7]">{ item.email || 'Contact email' }</p>
                            </div>
                            <div className="relative">
                                <a 
                                    href="#" onClick={(e)=>{
                                        e.preventDefault();
                                        setOpenMenu(openMenu === itemIndex ? null : itemIndex);
                                    }} className={`d-flex items-center justify-between hover:opacity-70 ${openMenu === itemIndex ? 'border bg-[#151515] rounded-lg' : 'border-1-transparent'}`}
                                >
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.9972 18.002C11.7221 18.002 11.497 18.227 11.5 18.5022C11.5 18.7773 11.7251 19.0024 12.0002 19.0024C12.2754 19.0024 12.5004 18.7773 12.5004 18.5022C12.5004 18.227 12.2754 18.002 11.9972 18.002" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M11.9972 12C11.7221 12 11.497 12.2251 11.5 12.5002C11.5 12.7753 11.7251 13.0004 12.0002 13.0004C12.2754 13.0004 12.5004 12.7753 12.5004 12.5002C12.5004 12.2251 12.2754 12 11.9972 12" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M11.9972 5.99707C11.7221 5.99707 11.497 6.22216 11.5 6.49728C11.5 6.77239 11.7251 6.99749 12.0002 6.99749C12.2754 6.99749 12.5004 6.77239 12.5004 6.49728C12.5004 6.22216 12.2754 5.99707 11.9972 5.99707" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </a>
                                {
                                    openMenu === itemIndex ? 
                                    <ul className='action-item-dropdown'>
                                        <li
                                            className="action-item-dropdown-item cursor-pointer"
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setSelectedForSetting((prevState)=>({
                                                    ...prevState,
                                                    settings: {
                                                        ...prevState.settings,
                                                        audiences: prevState.settings.audiences.filter((item, index) => index !== itemIndex)
                                                    }
                                                }));
                                                setOpenMenu(null);
                                            }}
                                        >
                                            Delete
                                        </li>
                                    </ul> : null
                                }
                            </div>
                        </li>
                    })
                }
            </ul>
        </div>
    </>;
};

export default Audience;