import React, { useState, useEffect } from 'react';
import logo from '../../../assets/img/gen4logo.svg';
import classNames from 'classnames';
import { navLinks } from './consts';
import { Link } from 'react-router-dom';

const SidebarPanel = ({
    cardFullScreen,
    configForm,
    setConfigForm,
    setIsLoading,
    handleGenerate,
    isNotDone,
 }) => {
    const [sideBarOpened, setSideBarOpened] = useState(true);
    const [activeTab, setActiveTab] = useState(navLinks[0].title);
    const activeTabObjectIndex = navLinks.findIndex(({title}) => title === activeTab);
    const ActiveComponent = navLinks[activeTabObjectIndex]?.Component;

    useEffect(() => {
        setSideBarOpened(!cardFullScreen);
    }, [cardFullScreen])

    return <>
        <nav className='flex flex-col w-[72px] bg-[#151515]'>
            <div className={classNames('generated-tab w-[72px] h-[60px]', {
                'generated-tab--active-top': activeTabObjectIndex === 0,
                'generated-tab--closed': !sideBarOpened,
            })}>
                <img src={logo} alt='Logo'/>
            </div>
            {navLinks.map(({ icon, title }, index) =>
                <button
                    key={index} 
                    className={classNames('generated-tab w-[72px] h-[72px]', {
                        'generated-tab--active': title === activeTab,
                        'generated-tab--active-top': index === activeTabObjectIndex - 1,
                        'generated-tab--active-bottom': index === activeTabObjectIndex + 1,
                        'generated-tab--closed': !sideBarOpened,
                    })}
                    onClick={() => {
                        setActiveTab(title);
                        setSideBarOpened(true);
                    }}
                >
                    {icon}
                    <span className='text-[10px] font-medium'>{title}</span>
                </button>
            )}
            <div className={classNames('generated-exit-wrapper', {
                'generated-tab--active-bottom': activeTabObjectIndex === navLinks.length - 1,
                'generated-tab--closed': !sideBarOpened,
            })}>
                <Link
                    to='/my-assets?tab=My Meta Tags'
                    className='w-[72px] h-[72px] flex flex-col gap-[8px] justify-center items-center opacity-40 bg-[#111111]'
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path d="M5 12.5H19" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10 7.5L5 12.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10 17.5L5 12.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <span className='text-[10px] font-medium'>Exit</span>
                </Link>
            </div>
        </nav>
        <div className={classNames('generated-sidebar', {
            'generated-sidebar--closed': !sideBarOpened
        })}>
            <button 
                className='absolute top-[18px] right-[16px]' 
                onClick={() => {
                    setSideBarOpened(false);
                    setActiveTab(null);
                }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g opacity="0.5">
                        <path d="M18 6L6 18" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6 6L18 18" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                </svg>
            </button>
            {(configForm && ActiveComponent) && 
                <ActiveComponent 
                    setIsLoading={setIsLoading}
                    configForm={configForm}
                    setConfigForm={setConfigForm}
                    handleGenerate={handleGenerate}
                    isNotDone={ isNotDone }
                    contentProductName="Meta Tags"
                    contentTitle="Meta Tags Content"
                    contentSubTitle="Information about your Meta Tags content"
                />
            }
        </div>
    </>;
};

export default SidebarPanel;