import React from 'react';
import { Outlet } from "react-router-dom";
import InfinityLogo from "../assets/img/react/new-infinity-logo.png";

const AuthLayout = () => {
    return (
        <div className="auth-fullscreen-wrpr split-screen p-[20px]">
            <div className="auth-left-top-logo" style={{ zIndex: '2' }}>
                <img src={require("../assets/img/big-logo.png")} alt="" />
            </div>
            <div className="auth-form-box-fullscreen" style={{ zIndex: '2' }}>
                <Outlet />
            </div>
            <div className="auth-content" style={{ zIndex: '2' }}>
                <div className="auth-content-inner">
                    <div className="logo">
                        <img src={InfinityLogo} alt="" />
                    </div>
                    <h1>Unleash the AI Advantage: Effortless Content, Captivating Images, and SEO-Optimized Videos for Your Web App</h1>
                    <p className="mb-[24px]">The ultimate platform for effortlessly generating high-quality content, captivating images, and dynamic videos to enhance your web app's appeal and optimize search engine visibility.</p>
                    <div className="d-flex align-items-center">
                        <ul className="avatars-list">
                            <li>
                                <img src={require("../assets/img/avatar-img-1.jpg")} alt="" />
                            </li>
                            <li>
                                <img src={require("../assets/img/avatar-img-2.jpg")} alt="" />
                            </li>
                            <li>
                                <img src={require("../assets/img/avatar-img-3.jpg")} alt="" />
                            </li>
                            <li>
                                <img src={require("../assets/img/avatar-img-4.jpg")} alt="" />
                            </li>
                        </ul>
                        <p className="ml-[16px]">More than 17k people joined us, it's your turn!</p>
                    </div>
                </div>
            </div>
            <video autoPlay muted loop className="auth-layout-video-bg">
                <source src={require("../assets/videos/auth-layout-video-bg.webm")} type="video/webm" />
            </video>
        </div>
    )
}

export default AuthLayout;