import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { getToken } from '../../redux/slices/applicationSlice';
import api from '../../api';

export default function PrivateButton({item}) {
    const [localIsPrivate, setLocalIsPrivate] = useState(!!item.is_private);
    console.log(item.word, localIsPrivate)
    const token = useSelector(getToken);

    const handleClick = async () => {
        try {
            const response = await api.newWords.updateNewWord({
                id: item.id,
                body: {
                    is_private: !localIsPrivate
                },
                token
            });
            if(!response.success) return;
            setLocalIsPrivate(response.data.is_private);
        } catch (e) {
            console.log(e);
        }
    }
    return <button 
        className="d-block"
        onClick={handleClick}
    >
        {localIsPrivate ? 'Make Public' : 'Make Private'}
    </button>
}
