import React, { Fragment, useEffect, useState } from 'react';
import { format } from 'date-fns';
import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from './icons/arrow.svg';
import { defaultButtons, editButtons } from './consts';
import Tippy from '@tippyjs/react';
import api from '../../../api';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { copyActiveBlogVersion, deleteActiveBlogVersion, getActiveBlogIndex, getGeneratedBlogs, setActiveBlogIndex, setBlogActiveButton, setBlogActiveContent, setBlogActiveVersionIndex, setBlogContent, setBlogIsEdit } from '../../../redux/slices/blogGeneratorSlice';
import { useDispatch } from 'react-redux';
import ModalAction from './ModalAction';
import Editor from './Editor';
import ButtonTooltip from './ButtonTooltip';
import { getToken } from '../../../redux/slices/applicationSlice';
import DefaultToolbar from './DefaultToolbar';
import { useQuill } from 'react-quilljs';
import EditorToolbar from './EditorToolbar';
import EditorControl from './EditorControl';
import CardContent from './CardContent';

const EssayCard = ({essay, index, setCardFullScreen, isFullScreen, isNotDone = false}) => {
    console.log('ESSAY CARD', essay)
    const token = useSelector(getToken);
    const activeBlogIndex = useSelector(getActiveBlogIndex);
    const dispatch = useDispatch();
    const isOpen = index === activeBlogIndex;

    const [customPrompt, setCustomPrompt] = useState('');

    const { id } = useParams();
    const [updateBlogs, setUpdateBlogs] = useState(false);
    // useEffect(() => {
    //     if(updateBlogs) {
    //         api.assets.changeAsset({
    //             id,
    //             data: {
    //                 // content: JSON.stringify(blogs)
    //             },
    //             token
    //         }).catch(e => console.log(e))
    //         setUpdateBlogs(false);
    //     }
    // }, [updateBlogs])
    const generatedDate = new Date(essay.generatedDate);
    // const markup = data[activeVersionIndex]?.activeContent || data[activeVersionIndex]?.content;
    const markup = essay.versions
    return <li 
        className={classNames(
            'blog-post-card', 
            isFullScreen ? 'h-full' : isOpen ? 'h-[442px]' : 'h-[42px]',
            (isFullScreen && !isOpen) ? 'hidden': 'flex'
        )}
        style={{
            // borderColor: isSelected ? '#FF8114': '#1F1F1F'
        }}
    >
        <div 
            className='flex items-center justify-between py-[11px] px-[16px]'
        >
            <div className={`gen-con-header-title gap-[8px] cursor-pointer ${isNotDone ? 'disabled' : ''}`} onClick={() => dispatch(setActiveBlogIndex(index))}>
                <div
                    className={`gen-header-arrow pointer-events-none ${isOpen && 'rotate-180'}`}
                    //  onClick={(e) => {e.stopPropagation()}}
                >
                    <ArrowIcon />
                </div>
                <span className="leading-[18px]">Essay {index + 1}</span>
            </div>
            <div className="gen-con-header-date leading-[18px]">
                Generated  {format(generatedDate, 'dd.MM.yyyy, H:mm')}
            </div>
        </div>
        
        {isOpen && <CardContent
            index={index}
            isFullScreen={isFullScreen}
            setCardFullScreen={setCardFullScreen}
            setUpdateBlogs={setUpdateBlogs}
            markup={markup}
            isNotDone={isNotDone}
            versions={essay.versions}
            isOpen={isOpen}
        />}
    </li>;
};

export default EssayCard;