import React, { useEffect, useRef, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/routes.js";
import { GeneratorContextComponent } from "./routes/GeneratorV4/GeneratorContextComponent.jsx";
import { useDispatch } from "react-redux";
import { getUserThunk } from "./redux/thunks/applicationThunks.js";
import { setAuthorized, setFormOptions, setSettings, setSocket, setToken } from "./redux/slices/applicationSlice.js";
import api from "./api/index.js";
import { ToastContainer } from "react-toastify";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Socket from "./helpers/socketClient.js";
import 'react-toastify/dist/ReactToastify.css';

function App() {
	const dispatch = useDispatch();
	const [stripe, setStripe] = useState(null);
	useEffect(() => {
		api.settings.getSettings().then((response) => {
			if(!response.success) return;
			setStripe(loadStripe(response.data.stripe_pk));
			dispatch(setSettings(response.data));
		}).catch(e => console.log(e));
		const token = localStorage.getItem('authToken');
		if(!token) {
			dispatch(setAuthorized(false));
			return;
		}
		api.generationForms.getGenerationForms({token}).then((response) => {
			if(!response.success) return;
			dispatch(setFormOptions(response.data));	
		}).catch(e => console.log(e));
		dispatch(setToken(token));
		dispatch(getUserThunk({token}));
	}, [dispatch])
	return (
		<div className="App">
			<ToastContainer theme='dark'/>
			<GeneratorContextComponent>
				<Elements stripe={stripe}>
					<RouterProvider router={router} />
                </Elements>
			</GeneratorContextComponent>
		</div>
	);
}

export default App;
