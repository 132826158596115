import React, { useState } from 'react';
import api from '../../api';
import { useSelector } from 'react-redux';
import { getToken } from '../../redux/slices/applicationSlice';
import Input from '../../components/base/Input';
import { brandIcons } from './AddCardModal';
import PrimaryButton from '../../components/base/PrimaryButton';
import { toast } from 'react-toastify';

const EditCardModal = ({ close, card, setCards }) => {
    const token = useSelector(getToken);
    const [inputValues,setInputValues] = useState({
        exp_month: card.exp_month,
        exp_year: card.exp_year
    })
    const [isLoading, setIsLoading] = useState(false);
    const handleEditCard = async () => {
        setIsLoading(true);
        try {
            const toastId = toast.loading("Editing card...");
            const response = await api.paymentCards.editCard({
                id: card.id,
                data: inputValues,
                token
            });
            toast.update(toastId, {
                render: response.message, 
                type: response.success ? 'success' : 'error', 
                isLoading: false,
                autoClose: 5000
            });
            if(!response.success) return;
            close();
            api.paymentCards.getCards({token}).then(response => {
                if(!response.success) return;
                setCards(response.data);
            })
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }
    const CardIcon = brandIcons[card.brand];
    return <div className='modal-content relative p-[36px]'>
        <button 
            className="z-10 absolute top-[16px] right-[16px] w-[34px] h-[34px] flex items-center justify-center
            bg-[#1B1B1B] rounded-[8px] border border-solid border-[#333]"
            onClick={close}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g opacity="0.5">
                    <path d="M15 5L5 15" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M5 5L15 15" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
            </svg>
        </button>
        <div className='flex flex-col gap-[36px]'>
            <div>
                <h2 className='modal-body-title' >Edit Card</h2>
                <span className='modal-body-subtitle' >Please fill in form below to edit card</span>
            </div>
            <div className='flex flex-col gap-[16px]'>
                <div className='flex-1 flex flex-col gap-[8px]'>
                    <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>Card Number</p>
                    <div className='py-[8px] pl-[12px] pr-[8px] bg-[#1B1B1B] rounded-[8px] h-[40px]
                    flex items-center gap-[12px] border border-solid border-[#333]'>
                        <CardIcon className='w-[28px] h-[20px]'/>
                        <span className='text-[14px] opacity-90'>•••• •••• •••• {card.last4}</span>
                    </div>
                </div>
                <div className='flex-1 flex flex-col gap-[8px]'>
                    <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>Expiry Month</p>
                    <Input
                        type='number'
                        value={inputValues.exp_month}
                        onChange={({target: {value}}) => {
                            if(/^(|[1-9]|0[1-9]|1[0-2])$/.test(value)) setInputValues({...inputValues, exp_month: value});
                        }}
                        min={1}
                        max={12}
                    />
                </div>
                <div className='flex-1 flex flex-col gap-[8px]'>
                    <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>Expiry Year</p>
                    <Input
                        type='number'
                        value={inputValues.exp_year}
                        onChange={({target: {value}}) => {
                            if(/^(|[0-9]{1,4})$/.test(value)) setInputValues({...inputValues, exp_year: value});
                        }}
                        min={2023}
                        max={2043}
                    />
                </div>
            </div>
            <PrimaryButton
                onClick={handleEditCard}
                className="w-fit ml-auto"
                disabled={!inputValues.exp_month || !inputValues.exp_year || isLoading}
            >
                <span>Edit Card</span>
            </PrimaryButton>
        </div>
    </div>
}

export default EditCardModal;