import React, { useEffect, useRef, useState } from 'react';
import EditorToolbar from './EditorToolbar';
import { useQuill } from 'react-quilljs';
import classNames from 'classnames';
import DefaultToolbar from './DefaultToolbar';
import EditorControl from './EditorControl';
import { useDispatch } from 'react-redux';
import BlogVariantKeywords from './EssayVariantKeywords';
import { useSelector } from 'react-redux';
import { getActiveBlogIndex, getBlogGeneratingContent, getGeneratedBlogs, setBlogActiveButton, setBlogActiveContent, setBlogActiveVersionIndex, setBlogContent, setBlogGeneratingContent, setBlogSelectedVersionId, setGenerateLoading } from '../../../redux/slices/blogGeneratorSlice';
import api from '../../../api';
import { useParams } from 'react-router-dom';
import { getSocket, getToken } from '../../../redux/slices/applicationSlice';
import ImageUploader from "quill-image-uploader";
import Quill from "quill";
import 'quill-image-uploader/dist/quill.imageUploader.min.css';
import { setEssayVersion } from '../../../redux/slices/essayGeneratorSlice';
import { setEtsyItemData } from '../../../redux/slices/etsyGeneratorSlice';
import { toast } from 'react-toastify';

const CardContent = ({data, versions, index, isFullScreen, setCardFullScreen, setUpdateBlogs, isNotDone = false, isOpen}) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    // const blogs = useSelector(getGeneratedBlogs);
    const socket = useSelector(getSocket);
    // const activeBlogIndex = useSelector(getActiveBlogIndex);
    // const generatingContent = useSelector(getBlogGeneratingContent);
    // console.log(generatingContent);
    // const { activeVersionIndex, versions, selectedVersionId } = blogs[activeBlogIndex] || {};
    // const isSelected = selectedVersionId === versions[activeVersionIndex]?.id;
   
    const { id } = useParams();
    console.log('DATA', data)

    const [isEdit, setIsEdit] = useState(false);

    const [editorActiveButton, setEditorActiveButton] = useState(null);
    const [imagePopupOpened, setImagePopupOpened] = useState(false);
    const [imageLinkOpened, setImageLinkOpened] = useState(false);
    const [footerActions, setFooterActions] = useState({
        keyword: false,
        customPrompt: false
    });
    const [customPrompt, setCustomPrompt] = useState('');
    const [editBlogLoading, setEditBlogLoading] = useState(false);

    const editEssay = ({operation, customPayload = {}}) => {
        // setEditBlogLoading(true);
        // dispatch(setGenerateLoading(true));

        socket.send({
            event: 'etsy-listing-edit',
            payload: {
                jsonContent: data,
                operation,
                ...customPayload
            },
            handler: (response) => {
                if(response?.isError){
                    toast.error("AI bot is not available at the moment");
                    return;
                }

                console.log(response);
                dispatch(setEtsyItemData(response.data));
                // dispatch(setEssayVersion({
                //     index: activeVersionIndex,
                //     data: {
                //         content: response.payload,
                //         isDone: response.isDone,
                //     }
                // }))
                // const { firstChild } = quillRef.current;
                // firstChild.scroll({ top: firstChild.scrollHeight, behavior: 'smooth' });
                // if(response.isDone) {
                //     dispatch(setBlogGeneratingContent(null));
                //     isActive ? dispatch(setBlogActiveContent(response.payload)) : dispatch(setBlogContent(response.payload));
                //     setUpdateBlogs(true);
                //     setEditBlogLoading(false);
                //     // dispatch(setGenerateLoading(false));
                //     return;
                // }
            }
        })
    }
    const wordsCount = Object.values(data).filter(value => typeof value === 'string').reduce((accumulator, value) => accumulator + value.trim().split(/\s+/).length, 0);
    const imageButtonRef = useRef();
    return <>
    <div
        className={classNames("flex justify-between py-[6px] px-[8px] rounded-full bg-[#1B1B1B] mx-[16px]",
        isEdit ? 'hidden' : 'flex')}
    >
        <DefaultToolbar
            disabled={isNotDone || editBlogLoading}
            footerActions={footerActions}
            setBlogIsEdit={setIsEdit}
            setFooterActions={setFooterActions}
            isFullScreen={isFullScreen}
            setCardFullScreen={setCardFullScreen}
            editEssay={editEssay}
        />
    </div>
    <div className='w-full max-w-[882px] mx-auto py-[24px] px-[16px] pb-[50px] flex flex-col gap-[16px] h-[304px] overflow-y-scroll no-scroll flex-1'>
             <>
            <p className='text-[14px] font-semibold leading-[24px] opacity-90'>
                Title: <span className='text-[#A7A7A7] font-normal'>{data?.title}</span>
            </p>
            <p className='text-[14px] font-semibold leading-[24px] opacity-90'>
                Description: <span className='text-[#A7A7A7] font-normal'>{data?.description}</span>
            </p>
            <p className='text-[14px] font-semibold leading-[24px] opacity-90'>
                Research: <span className='text-[#A7A7A7] font-normal'>{data?.research}</span>
            </p>
            <p className='text-[14px] font-semibold leading-[24px] opacity-90'>
                <ul className='flex flex-wrap gap-[6px]'>
                Hashtags: 
                    {data?.hash_tags && data?.hash_tags?.map(tag => <div className='text-[#A7A7A7] font-normal'>{tag}</div>)}
                </ul>
            </p>
        </>
    </div>
    <div
        className={classNames('blog-generator-card-footer', isOpen || isFullScreen ? 'block' : 'hidden')}
    >
        {footerActions.keyword ? <BlogVariantKeywords
            data={data}
            setUpdateBlogs={setUpdateBlogs}
            editBlog={editEssay}
            setFooterActions={setFooterActions}
            // activeVersionIndex={activeVersionIndex}
        /> : footerActions.customPrompt ? <div>
            <div className='flex gap-[10px]'>
                <input
                    className='bg-inherit h-[32px] text-[13px] flex-1 pl-[12px] pr-[8px]
                    border border-solid border-[#333] rounded-[8px]'
                    value={customPrompt}
                    onChange={(e) => setCustomPrompt(e.target.value)}
                    placeholder="Ex. Rewrite the introductory paragraph to create a stronger hook and engage the reader's interest from the start..."
                />
                <button 
                    className='goal-generator-action btn--primary-gradient btn'
                    style={{
                        height: 32,
                        padding: '0 12px',
                        fontSize: 13
                    }}
                    onClick={() => {
                        editEssay({
                            operation: 'custom',
                            customPayload: {
                                data: customPrompt
                            }
                        });
                        setFooterActions({
                            keyword: false,
                            customPrompt: false
                        });
                    }}
                    disabled={!customPrompt}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M13.3337 4.33301L6.00033 11.6663L2.66699 8.33301" stroke="#331A04" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    Apply
                </button>
            </div>
        </div> : <div className='flex justify-between items-center'>
            
            <div className='flex gap-[16px] items-center'>
                <span className='text-[13px] opacity-30'>
                    {wordsCount} words
                </span>
                {/* {false ? <button 
                    className='word-selected-button'
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <g clipPath="url(#clip0_4010_73587)">
                            <path d="M6.9834 9.1168L5.66673 7.78346C5.52451 7.65013 5.34945 7.58346 5.14155 7.58346C4.93365 7.58346 4.75315 7.65291 4.60007 7.7918C4.45562 7.9418 4.3834 8.12235 4.3834 8.33346C4.3834 8.54458 4.46118 8.71124 4.61673 8.83346L6.45007 10.6835C6.58643 10.839 6.76219 10.9168 6.97733 10.9168C7.19249 10.9168 7.37784 10.839 7.5334 10.6835L11.3167 6.90013C11.4723 6.75791 11.5501 6.58563 11.5501 6.38328C11.5501 6.18094 11.4732 5.99766 11.3194 5.83346C11.1656 5.70013 10.9817 5.63624 10.7676 5.6418C10.5534 5.64735 10.3754 5.7168 10.2334 5.85013L6.9834 9.1168ZM8.00237 15.0168C7.03817 15.0168 6.12647 14.8357 5.26728 14.4734C4.40808 14.1111 3.66043 13.612 3.02433 12.9759C2.38823 12.3398 1.88905 11.5929 1.5268 10.7352C1.16453 9.87756 0.983398 8.96663 0.983398 8.00243C0.983398 7.03539 1.16485 6.12379 1.52777 5.26765C1.89069 4.4115 2.39078 3.66431 3.02805 3.02608C3.66533 2.38784 4.41174 1.88506 5.26728 1.51775C6.12284 1.15045 7.03254 0.966797 7.99638 0.966797C8.96424 0.966797 9.8771 1.14992 10.735 1.51618C11.5928 1.88244 12.3404 2.38386 12.9776 3.02046C13.6149 3.65706 14.1168 4.40401 14.4834 5.2613C14.8501 6.1186 15.0334 7.032 15.0334 8.00151C15.0334 8.97033 14.8497 9.88145 14.4824 10.7349C14.1151 11.5883 13.6124 12.3339 12.9741 12.9715C12.3359 13.6092 11.5895 14.1095 10.7348 14.4724C9.88024 14.8353 8.96941 15.0168 8.00237 15.0168Z" fill="#331A04"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_4010_73587">
                            <rect width="16" height="16" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    Selected
                </button> : <button 
                    className='rounded-full border border-solid border-[#333333] bg-[#1B1B1B]
                    px-[12px] h-[32px] flex items-center text-[13px] opacity-90 text-[#A7A7A7]'
                    onClick={() => {
                        dispatch(setBlogSelectedVersionId(versions[activeVersionIndex].id));
                        setUpdateBlogs(true);
                    }}
                >
                    Select Version
                </button>} */}
            </div>
        </div>}
    </div>
    {imagePopupOpened && <div className='generated-blog-popup'>
            <button 
                onClick={() => {
                    imageButtonRef.current.click();
                    setImagePopupOpened(false);
                    setEditorActiveButton(null);
                }}
                className='py-[24px] px-[10px] flex flex-col justify-center items-center
                flex-1 rounded-[8px] bg-[#202020] cursor-pointer'
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect x="1.5" y="1.5" width="21" height="21" rx="5" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M22.3928 16.6596L19.2412 13.508C18.7856 13.0524 18.0469 13.0524 17.5913 13.508L14.9163 16.1831L9.90791 11.1747C9.4523 10.7191 8.71361 10.7191 8.258 11.1747L1.86426 17.5684" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M14.7094 7.71081L14.7099 7.7103C14.8237 7.59419 15.01 7.59222 15.1262 7.70589C15.2423 7.81957 15.2444 8.00588 15.1308 8.12213C15.0172 8.23838 14.8309 8.24057 14.7146 8.12704C14.5983 8.01351 14.596 7.82719 14.7094 7.71081" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <p className='mt-[20px] text-[13px] font-medium opacity-90'>Upload Image</p>
                <p className='mt-[12px] text-[12px] font-light opacity-50'>Add image from your computer</p>
            </button>
            <button
                onClick={() => {
                    setImageLinkOpened(true);
                    setImagePopupOpened(false);
                }}
                className='py-[24px] px-[10px] flex flex-col justify-center items-center
                flex-1 rounded-[8px] bg-[#202020]'
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M19.2239 16.2887L21.7811 13.7315C23.8119 11.6495 23.7911 8.32164 21.7346 6.26509C19.6781 4.20855 16.3502 4.18781 14.2682 6.21856L11.7109 8.77579" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M17.1123 10.8867L10.8867 17.1123" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M8.77579 11.7109L6.21856 14.2682C4.18781 16.3502 4.20855 19.6781 6.26509 21.7346C8.32164 23.7911 11.6495 23.8119 13.7315 21.7811L16.2887 19.2239" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <p className='mt-[20px] text-[13px] font-medium opacity-90'>Add Image URL</p>
                <p className='mt-[12px] text-[12px] font-light opacity-50'>Paste image link to add picture</p>
            </button>
        </div>}
        {editorActiveButton === 'chart' && <div className='generated-blog-popup'>
            <button 
                onClick={() => {
                    setEditorActiveButton(null);
                }}
                className='flex flex-col justify-center items-center h-[122px]
                flex-1 rounded-[8px] bg-[#202020] cursor-pointer'
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
                    <path d="M8.50022 1.61035C4.81178 2.90818 2.151 6.14934 1.59651 10.0199C1.04202 13.8905 2.68581 17.7483 5.86154 20.0295C9.03726 22.3107 13.218 22.6367 16.709 20.8754" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1V11.4999L19.4247 18.9245C22.4276 15.9215 23.3259 11.4053 21.7007 7.48178C20.0755 3.55824 16.2468 1.00002 12 1L12 1Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p className='mt-[16px] text-[13px] font-medium opacity-90'>Pie Chart</p>
            </button>
            <button 
                onClick={() => {
                    setEditorActiveButton(null);
                }}
                className='flex flex-col justify-center items-center h-[122px]
                flex-1 rounded-[8px] bg-[#202020] cursor-pointer'
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
                    <path d="M24.5 25H5.83333C4.54417 25 3.5 23.9558 3.5 22.6667V4" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M24.5003 7.5L19.136 13.9377C18.849 14.2818 18.3917 14.4312 17.9565 14.3227L14.7108 13.5107C14.2768 13.4022 13.8183 13.5515 13.5313 13.8957L8.16699 20.3333" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p className='mt-[16px] text-[13px] font-medium opacity-90'>Line Chart</p>
            </button>
            <button 
                onClick={() => {
                    setEditorActiveButton(null);
                }}
                className='flex flex-col justify-center items-center h-[122px]
                flex-1 rounded-[8px] bg-[#202020] cursor-pointer'
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
                    <path d="M8.16634 20.3333V14.5" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.8333 20.3333V7.5" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M17.5003 20.3335V13.2168" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M22.1663 20.3337V8.66699" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3.5 4V22.6667C3.5 23.9558 4.54417 25 5.83333 25H24.5" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p className='mt-[16px] text-[13px] font-medium opacity-90'>Column Chart</p>
            </button>
            <button 
                onClick={() => {
                    setEditorActiveButton(null);
                }}
                className='flex flex-col justify-center items-center h-[122px]
                flex-1 rounded-[8px] bg-[#202020] cursor-pointer'
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
                    <path d="M3.50033 4V25" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 17.2217H17.5V21.4998C17.5 22.1438 16.9773 22.6665 16.3333 22.6665H3.5V17.2217Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 11.7783H23.3333C23.9773 11.7783 24.5 12.301 24.5 12.945V16.0565C24.5 16.7005 23.9773 17.2232 23.3333 17.2232H3.5V11.7783Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 6.33301H19.8333C20.4773 6.33301 21 6.85567 21 7.49967V11.7778H3.5V6.33301Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p className='mt-[16px] text-[13px] font-medium opacity-90'>Bar Chart</p>
            </button>
        </div>}
    </>
};

export default CardContent;